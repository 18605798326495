// 3rd party
import { Text, useDetectGPU } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import React, {
  createRef,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { isMobile, isTablet } from "react-device-detect";
import { AdditiveBlending, Color, TextureLoader, Vector3 } from "three";

// utility
import { lerp } from "./math";

// materials
import "./materials/LineMaterial";
import "./materials/MilestonePlaneMaterial";
import "./materials/ParticleSpiralMaterial";
import "./materials/PlaneMaterial";
import "./materials/TextMaterial";

// store
import { useFlowMapStore } from "./state/flowMap";
import { useMeasureStore } from "./state/measure";
import { useScrollStore } from "./state/scroll";
import { useYearStore } from "./state/year";

// components
import RoadMapItem from "./components/RoadMapItem";

// enable devMode if you want to prevent the spiral appearing, saving CPU resources
// while developing
const devMode = false;

const ROWS = 300;
const COLS = 15;
const POINT_SIZE = 15;
const X_SPACING = isMobile ? 15 : 8;
const Y_SPACING = isMobile ? 10 : 4;

const palette = [
  0x980000, 0x980000, 0xad002c, 0xad002c, 0xba0952, 0xba0952, 0xbe2b79,
  0xbe2b79, 0xb9489e, 0xb9489e, 0xac62bf, 0xac62bf, 0x967adb, 0x967adb,
  0x7a91f0, 0x7a91f0, 0x56a5fd, 0x56a5fd, 0x2ab8ff, 0x2ab8ff, 0x00c9ff,
  0x00c9ff, 0x00d8ff, 0x00d8ff
];

const yearOffsetMultiplier = 5;
const spiralOffset = Math.PI * 1.25;

export default function ParticleSpiral({
  roadMapItems,
  visibleItemIds,
  onClickItem,
  isCondensedModeEnabled,
  filterBy,
  disableInteraction
}) {
  // textures
  const iconSprite = useLoader(
    TextureLoader,
    "/roadmap/assets/textures/iconSprite.png"
  );

  const gpuTier = useDetectGPU();

  // store
  const setDisplayYear = useYearStore((state) => state.setDisplayYear);
  const year = useYearStore((state) => state.year);
  const setYear = useYearStore((state) => state.setYear);
  const flowMapRef = useRef(useFlowMapStore.getState().flowMap);
  const setYearsAvailable = useYearStore((state) => state.setYearsAvailable);
  const setContainerHeight = useMeasureStore(
    (state) => state.setContainerHeight
  );

  const scrollY = useRef(useScrollStore.getState().scrollY);
  const setScrollY = useScrollStore((state) => state.setScrollY);

  const targetScrollY = useRef(useScrollStore.getState().targetScrollY);
  const setTargetScrollY = useScrollStore((state) => state.setTargetScrollY);

  const scrollYDelta = useRef(useScrollStore.getState().scrollYDelta);
  const setScrollYDelta = useScrollStore((state) => state.setScrollYDelta);

  // state
  const [yearPositionsCalculated, setYearPositionsCalculated] = useState(false);
  const [userInteracting, setUserInteracting] = useState(false);

  // refs
  const yearTextPos = useRef([0, 0, 0]);
  const spiralGeo = useRef();
  // a map from year to y position of where the first milestones of that year is encountered
  const startPositionsOfYearMilestones = useRef({});
  const scrollLerpSpeed = useRef(1);
  const textMaterialRef = useRef();
  const planeMaterialRef = useRef();
  const milestonePlaneMaterialRef = useRef();
  const lineMaterialRef = useRef();
  const firstLoad = useRef(true);
  const scrollCount = useRef(0);
  const roadmapContainerRef = useRef();

  const yearContainersRef = useRef(roadMapItems.map(() => createRef()));
  // basically the floating grey 2020, 2021 background labels
  const [backgroundYearLabelPositions, setBackgroundYearLabelPositions] =
    useState([]);

  const itemsRef = useRef(
    roadMapItems.map((el) => {
      return el.items.map(() => createRef());
    })
  );

  // create spiral geometry
  const [coords, sizes, colors] = useMemo(() => {
    if (devMode) return [undefined, undefined, undefined];

    const initialCoords = [];
    const initialSizes = [];
    const initialColors = [];
    for (let y = 0; y < ROWS; y += 1) {
      for (let x = 0; x < COLS; x += 1) {
        initialCoords.push(x * X_SPACING + Math.random() * 0.5);
        initialCoords.push(y * Y_SPACING + Math.random() * 1.0);
        initialCoords.push(Math.random());

        initialSizes.push(POINT_SIZE + Math.random() * 10);

        if (typeof palette[x] !== "undefined") {
          const color = new Color(palette[x]);
          initialColors.push(color.r);
          initialColors.push(color.g);
          initialColors.push(color.b);
        }
      }
    }

    const coords = new Float32Array(initialCoords);
    const sizes = new Float32Array(initialSizes);
    const colors = new Float32Array(initialColors);

    return [coords, sizes, colors];
  }, []);

  // constructor
  useEffect(() => {
    // window.scrollTo(0, 0)
    setTargetScrollY(window.scrollY);
    window.addEventListener("scroll", () => {
      setTargetScrollY(window.scrollY);
    });

    window.addEventListener("scroll", () => {
      if (scrollCount.current < 2) {
        scrollCount.current++;
      }
      if (scrollCount.current > 1) {
        setUserInteracting(true);
      }
    });

    window.addEventListener("mousedown", () => {
      setUserInteracting(true);
    });

    window.addEventListener(
      "touchmove",
      () => {
        setUserInteracting(true);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (userInteracting === true) {
      switch (gpuTier.tier) {
        case 3:
          scrollLerpSpeed.current = 0.02;
          break;
        case 2:
          scrollLerpSpeed.current = 0.06;
          break;
        case 1:
          scrollLerpSpeed.current = 0.1;
          break;
      }
    }

    if (isMobile || isTablet) {
      scrollLerpSpeed.current = 1;
    }
  }, [gpuTier, userInteracting]);

  useEffect(
    () =>
      useFlowMapStore.subscribe(
        (state) => (flowMapRef.current = state.flowMap)
      ),
    []
  );

  useEffect(
    () =>
      useScrollStore.subscribe((state) => (scrollY.current = state.scrollY)),
    []
  );

  useEffect(
    () =>
      useScrollStore.subscribe(
        (state) => (targetScrollY.current = state.targetScrollY)
      ),
    []
  );

  useEffect(
    () =>
      useScrollStore.subscribe(
        (state) => (scrollYDelta.current = state.scrollYDelta)
      ),
    []
  );

  useFrame((state) => {
    if (devMode) {
      return;
    }

    spiralGeo.current.material.uniforms.uTime.value =
      state.clock.getElapsedTime() * 100;
    spiralGeo.current.material.uniforms.uFlowMap.value = flowMapRef.current;

    if (Math.round(scrollY.current) !== targetScrollY.current) {
      setScrollY(
        lerp(scrollY.current, targetScrollY.current, scrollLerpSpeed.current)
      );

      setScrollYDelta(targetScrollY.current - scrollY.current);

      yearTextPos.current = [0, scrollY.current * (isMobile ? 1 : 0.1), 0];
      spiralGeo.current.material.uniforms.uScrollY.value =
        1 - scrollY.current * (isMobile ? 5 : 1);

      // find closest year position
      let closestYear;
      let closest = Number.MAX_SAFE_INTEGER;
      for (const year in startPositionsOfYearMilestones.current) {
        const dist = Math.abs(
          Math.abs(scrollY.current) -
            Math.abs(startPositionsOfYearMilestones.current[year])
        );
        if (dist < closest) {
          closest = dist;
          closestYear = year;
        }
      }
      setDisplayYear(closestYear);
    }

    roadmapContainerRef.current.position.set(0, yearTextPos.current[1], 0);

    milestonePlaneMaterialRef.current.uniforms.uTime.value =
      state.clock.elapsedTime;
  });

  function scrollToYear(yearTarget = null) {
    if (!yearTarget) {
      return;
    }

    if (
      typeof startPositionsOfYearMilestones.current[yearTarget] === "undefined"
    ) {
      return;
    }

    if (firstLoad.current === true) {
      window.scrollTo(
        0,
        1 - (startPositionsOfYearMilestones.current[yearTarget] + 1250)
      );
      firstLoad.current = false;
    } else {
      window.scrollTo(
        0,
        1 - startPositionsOfYearMilestones.current[yearTarget]
      );
    }
  }

  // scroll to current year
  useEffect(() => {
    scrollToYear(year);
  }, [year]);

  // calculate roadmap item positions on spiral
  useEffect(() => {
    // cleanup in case we switch from condensed to non condensed mode or viceversa
    roadMapItems.forEach((year) => {
      for (let index = 0; index < year.items.length; index++) {
        delete year.items[index].orientation;
        year.items[index].pos = [];
      }
    });
    // end cleanup

    const sortArr = [];
    for (const key in roadMapItems) {
      sortArr[parseInt(roadMapItems[key].year)] = roadMapItems[key];
    }

    sortArr.reverse();

    const sortedObj = [];
    sortArr.forEach((el, i) => {
      sortedObj[i] = el;
    });
    let originalRoadMapItems = roadMapItems;
    roadMapItems = sortedObj;

    let i = 0;

    const spiralYStartPos = 7;
    roadMapItems.forEach((year, yearIndex) => {
      for (let index = 0; index < year.items.length; index++) {
        let yearOffset;

        if (isCondensedModeEnabled) {
          yearOffset = 0;
        } else {
          yearOffset = -(yearIndex * yearOffsetMultiplier);
        }

        const modulo = 2;

        year.items[index].orientation = i % modulo === 0 ? "left" : "right";

        year.items[index].pos = [];

        year.items[index].pos[0] =
          (i % modulo === 0 ? -35 : 41) +
          Math.sin(spiralOffset + (-i + (spiralYStartPos + yearOffset)) * 0.5) *
            100;

        year.items[index].pos[1] =
          (-i + (spiralYStartPos + yearOffset)) * (isMobile ? 100 : 50);

        year.items[index].pos[2] =
          Math.cos(spiralOffset + (-i + (spiralYStartPos + yearOffset)) * 0.5) *
          100;

        const isIdVisible =
          visibleItemIds.lastIndexOf(year.items[index].id) > -1;

        if (
          (isCondensedModeEnabled && isIdVisible) ||
          !isCondensedModeEnabled
        ) {
          i += isMobile ? 2 : 1;
        }
      }
    });

    startPositionsOfYearMilestones.current = {};

    if (roadMapItems) {
      // year number position computation
      roadMapItems.forEach((el, i) => {
        if (!startPositionsOfYearMilestones.current[el.year]) {
          startPositionsOfYearMilestones.current[el.year] = [];
        }

        if (isMobile) {
          startPositionsOfYearMilestones.current[el.year] =
            el.items[0].pos[1] * 1 - 750;
        } else {
          startPositionsOfYearMilestones.current[el.year] =
            el.items[0].pos[1] * 10 - 3600;
        }

        // set document height to year furthest in the past
        if (i === roadMapItems.length - 1) {
          setContainerHeight(
            Math.abs(startPositionsOfYearMilestones.current[el.year]) +
              (isMobile ? 6000 : 6000)
          );
        }
      });

      setBackgroundYearLabelPositions(
        originalRoadMapItems.map((el, i) => {
          const ypos =
            startPositionsOfYearMilestones.current[el.year] *
              (isMobile ? 1.0 : 0.1) +
            350;
          return new Vector3(0, ypos, -300);
        })
      );

      setYearPositionsCalculated(true);
    }

    // update available years
    setYearsAvailable(roadMapItems.map((roadmapItem) => roadmapItem.printYear));
    setYear(startPositionsOfYearMilestones[0]);
  }, [visibleItemIds, filterBy, setYear, setYearsAvailable]);

  // set year positions

  const isVisible = ({ id }) => visibleItemIds.includes(id);
  const hasVisibleItems = (el) => el.items.some(isVisible);

  return (
    <>
      {!devMode && (
        <points ref={spiralGeo} frustumCulled={true}>
          <bufferGeometry>
            <bufferAttribute
              attachObject={["attributes", "position"]}
              count={!devMode && coords.length / 3}
              array={!devMode && coords}
              itemSize={3}
            />
            <bufferAttribute
              attachObject={["attributes", "size"]}
              count={!devMode && sizes.length}
              array={!devMode && sizes}
              itemSize={1}
            />
            <bufferAttribute
              attachObject={["attributes", "color"]}
              count={!devMode && colors.length / 3}
              array={!devMode && colors}
              itemSize={3}
            />
          </bufferGeometry>
          <dotMaterial
            transparent
            depthWrite={false}
            blending={AdditiveBlending}
          />
        </points>
      )}
      <group>
        <textMaterial
          ref={textMaterialRef}
          blending={AdditiveBlending}
          depthTest={false}
          depthWrite={false}
        />
        <planeMaterial
          ref={planeMaterialRef}
          blending={AdditiveBlending}
          depthTest={false}
          depthWrite={false}
        />
        <milestonePlaneMaterial
          ref={milestonePlaneMaterialRef}
          blending={AdditiveBlending}
          depthTest={false}
          depthWrite={false}
        />
        <lineMaterial
          ref={lineMaterialRef}
          blending={AdditiveBlending}
          depthTest={false}
          depthWrite={false}
        />
        <group renderOrder={0} ref={roadmapContainerRef}>
          {yearPositionsCalculated &&
            roadMapItems &&
            roadMapItems.map((el, i) =>
              hasVisibleItems(el) ? (
                <group key={el.printYear}>
                  <group
                    ref={yearContainersRef.current[i]}
                    position={backgroundYearLabelPositions[i]}
                  >
                    <Suspense fallback={null}>
                      {!isCondensedModeEnabled && (
                        <Text
                          font="/chivo-v12-latin-700.woff"
                          fillOpacity={0.2}
                          color="white"
                          anchorX="center"
                          anchorY="middle"
                          fontSize={isMobile ? 170 : 100}
                        >
                          {el.printYear}
                        </Text>
                      )}
                    </Suspense>
                  </group>

                  {el.items.map((item, j) =>
                    isVisible(item) ? (
                      <RoadMapItem
                        ref={itemsRef.current[i][j]}
                        key={item.id}
                        item={item}
                        textMaterialRef={textMaterialRef.current}
                        planeMaterialRef={planeMaterialRef.current}
                        lineMaterialRef={lineMaterialRef.current}
                        milestonePlaneMaterialRef={
                          milestonePlaneMaterialRef.current
                        }
                        iconSprite={iconSprite}
                        onClickItem={onClickItem}
                        disabled={disableInteraction}
                      />
                    ) : null
                  )}
                </group>
              ) : null
            )}
        </group>
      </group>
    </>
  );
}
