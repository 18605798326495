import { Svg, SvgProps } from "./Svg";

export function FAQSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={8}
      height={15}
      viewBox="0 0 8.5 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.32132 11.123C4.28145 11.4552 4.01472 11.7274 3.68015 11.7274V11.7274C3.34279 11.7274 3.06671 11.4514 3.09639 11.1153C3.12444 10.7976 3.17226 10.5141 3.23984 10.2649C3.34818 9.86546 3.52422 9.49645 3.76797 9.15791C4.01172 8.81937 4.3401 8.44697 4.75313 8.04072C5.11198 7.68187 5.43021 7.3501 5.70781 7.04541C5.98542 6.73395 6.20208 6.39203 6.35781 6.01963C6.52031 5.64723 6.60156 5.1902 6.60156 4.64854C6.60156 4.11364 6.50677 3.64307 6.31719 3.23682C6.12761 2.83057 5.84323 2.51234 5.46406 2.28213C5.0849 2.05192 4.61094 1.93682 4.04219 1.93682C3.52083 1.93682 3.05365 2.03838 2.64063 2.2415C2.23438 2.44463 1.91276 2.73916 1.67578 3.1251C1.53386 3.35886 1.43311 3.6235 1.37353 3.91902C1.30852 4.24154 1.04772 4.50635 0.718711 4.50635V4.50635C0.379635 4.50635 0.100954 4.22691 0.151742 3.89166C0.229317 3.3796 0.395403 2.92772 0.65 2.53604C1.00885 1.9876 1.48281 1.57119 2.07188 1.28682C2.66771 0.995671 3.32448 0.850098 4.04219 0.850098C4.85469 0.850098 5.53854 1.00921 6.09375 1.32744C6.65573 1.64567 7.08229 2.08916 7.37344 2.65791C7.67136 3.22666 7.82031 3.88682 7.82031 4.63838C7.82031 5.24098 7.71875 5.78604 7.51563 6.27354C7.3125 6.75426 7.03151 7.20791 6.67266 7.63447C6.3138 8.06104 5.8974 8.49098 5.42344 8.92432C4.9901 9.29671 4.69219 9.72328 4.52969 10.204C4.4291 10.4974 4.35965 10.8037 4.32132 11.123ZM2.90469 13.8196C2.90469 13.5894 2.97917 13.3965 3.12813 13.2407C3.27708 13.0782 3.48021 12.997 3.7375 12.997C3.99479 12.997 4.19792 13.0782 4.34688 13.2407C4.5026 13.3965 4.58047 13.5894 4.58047 13.8196C4.58047 14.0431 4.5026 14.236 4.34688 14.3985C4.19792 14.5543 3.99479 14.6321 3.7375 14.6321C3.48021 14.6321 3.27708 14.5543 3.12813 14.3985C2.97917 14.236 2.90469 14.0431 2.90469 13.8196Z"
        stroke="currentColor"
        strokeWidth={1}
      />
    </Svg>
  );
}

const FAQ = (props: SvgProps) => (
  <Svg
    width={12}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon
    {...props}
  >
    <path
      d="M6.492 15.805c-.061.51-.472.93-.986.93-.52 0-.944-.425-.899-.942.044-.49.117-.925.221-1.309a5.24 5.24 0 01.813-1.703c.375-.52.88-1.094 1.515-1.719a46.037 46.037 0 001.469-1.53c.427-.48.76-1.006 1-1.579.25-.573.375-1.276.375-2.11 0-.822-.146-1.546-.438-2.171A3.325 3.325 0 008.25 2.203c-.583-.354-1.313-.531-2.188-.531-.802 0-1.52.156-2.156.469a3.595 3.595 0 00-1.95 2.58c-.1.497-.5.904-1.007.904-.521 0-.95-.43-.872-.946.12-.787.375-1.483.767-2.085A5.345 5.345 0 013.03.672C3.948.224 4.958 0 6.062 0c1.25 0 2.303.245 3.157.734a4.844 4.844 0 011.969 2.047c.458.875.687 1.89.687 3.047 0 .927-.156 1.766-.469 2.516a8.208 8.208 0 01-1.297 2.094 21.203 21.203 0 01-1.921 1.984c-.667.573-1.126 1.229-1.376 1.969a6.873 6.873 0 00-.32 1.414zm-2.18 4.148c0-.354.115-.65.344-.89.23-.25.542-.375.938-.375.396 0 .708.125.937.375.24.24.36.536.36.89 0 .344-.12.64-.36.89-.229.24-.541.36-.937.36s-.709-.12-.938-.36a1.274 1.274 0 01-.343-.89z"
      fill="currentColor"
    />
  </Svg>
);

export default FAQ;
