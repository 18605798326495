import { useRouter } from "next/router";
import Button from "../buttons/Button";

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const SignupButton: React.FC<Props> = ({
  children = "Sign up",
  ...buttonProps
}) => {
  const router = useRouter();
  //TODO redirect to signup link once the flow for login/signup is decided
  const href = `/api/auth/login?returnTo=${encodeURIComponent(
    router.asPath
  )}&screen_hint=signup`;
  return (
    <Button {...buttonProps} as="a" href={href} data-login-button>
      {children}
    </Button>
  );
};

export default SignupButton;
