import { css } from "styled-components";
import { alertBoxContentVariables } from "../components/alert-box-content/AlertBoxContentVariables";
import { alertDialogVariables } from "../components/alert-dialog/AlertDialog.variables";
import { buttonVariables } from "../components/buttons/Button.variables";
import { socialCardVariables } from "../components/card/SocialCard.variables";
import {
  checkboxDarkVariables,
  checkboxVariables
} from "../components/checkbox/Checkbox.variables";
import { CopyToClipBoardVariables } from "../components/copy-to-clip-board/CopyToClipBoard.variables";
import { headingVariables } from "../components/heading/Heading.variables";
import { inputVariables } from "../components/input/Input.variables";
import { likesAndCommentsVariables } from "../components/likes-and-comments/LikesAndComments.variables";
import { navigationVariables } from "../components/navigation/Navigation.variables";
import { paginationVariables } from "../components/pagination/Pagination.variables";
import { PaperCardVariables } from "../components/paper-card/PaperCard.variables";
import { popMenuButtonVariables } from "../components/pop-menu-button/PopMenuButton.variables";
import { searchIconVariables } from "../components/search/Search.variables";
import { selectVariables } from "../components/select/Select.variables";
import { socialIconVariable } from "../components/social-icons/SocialIcons.variables";
import { tagVariables } from "../components/tag/Tag.variables";
import { textVariables } from "../components/text/Text.variables";
import { tooltipVariables } from "../components/tooltip/Tooltip.variables";
import { verifiedContentRibbonVariables } from "../components/verified-content-ribbon/VerifiedContentRibbon.variables";
import { verifiedLabelVariables } from "../components/verified-label/VerifiedLabel.variables";
import { voteVariables } from "../components/vote/Vote.variables";
import colors from "../tokens/colors";
import { spacing } from "../tokens/spacing";
import typography from "../tokens/typography";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { osanoVariables } from "./osano-dialog";

const defaultVariables = css`
  --default-text-font-family: ${typography.font.default};
  --default-text-color: ${colors.primary.slateGrey};
  --default-text-color-invert: ${colors.primary.white};
  --default-text-size: ${typography.size.default};
  --default-text-line-height: ${typography.lineHeight.default};
  --medium-text-line-height: ${typography.lineHeight.medium};
  --default-text-weight: ${typography.weight.default};
  --default-text-weight-light: ${typography.weight.light};
  --default-text-weight-bold: ${typography.weight.bold};

  --default-bg-color: ${colors.primary.white};
  --default-content-bg-color: ${colors.secondary.mondayMist};
  --default-focus-color: ${colors.others.skyBlue};

  --default-content-max-width: ${pxToRem(680)};

  --theme-color-primary: ${colors.primary.electricBlue};
  --theme-color-secondary: ${colors.primary.cardanoBlue};

  --link-color: var(--theme-color-secondary);

  --banner-border-color: ${colors.others.bannerLearnMore};

  --default-stroke-color: #efefef;
  --card-stroke: ${pxToRem(1)} solid var(--default-stroke-color);
  --card-shadow: 0 ${pxToRem(39)} ${pxToRem(25)} ${pxToRem(-45)}
    rgba(0, 67, 101, 0.2);
  --card-border-radius: var(--spacing-large);

  --spacing-xsmall: ${spacing.xs};
  --spacing-small: ${spacing.s};
  --spacing-default: ${spacing.m};
  --spacing-large: ${spacing.l};
  --spacing-xlarge: ${spacing.xl};
  --spacing-xxlarge: ${spacing.xxl};
  --spacing-xxxlarge: ${spacing.xxxl};

  --spacing-level00: ${spacing.level00};
  --spacing-level0: ${spacing.level0};
  --spacing-level1: ${spacing.level1};
  --spacing-level2: ${spacing.level2};
  --spacing-level3: ${spacing.level3};

  --spacing-level00: ${spacing.level00};
  --spacing-level0: ${spacing.level0};
  --spacing-level1: ${spacing.level1};
  --spacing-level2: ${spacing.level2};
  --spacing-level3: ${spacing.level3};

  --page-max-width: ${pxToRem(1180)};
`;

export const CssVariables = css`
  ${defaultVariables}
  ${headingVariables}
  ${textVariables}
  ${buttonVariables}
  ${navigationVariables}
  ${tagVariables}
  ${socialIconVariable}
  ${voteVariables}
  ${inputVariables}
  ${checkboxVariables}
  ${checkboxDarkVariables}
  ${likesAndCommentsVariables}
  ${searchIconVariables}
  ${selectVariables}
  ${popMenuButtonVariables}
  ${alertDialogVariables}
  ${alertBoxContentVariables}
  ${verifiedLabelVariables}
  ${tooltipVariables}
  ${verifiedContentRibbonVariables}
  ${PaperCardVariables}
  ${CopyToClipBoardVariables}
  ${paginationVariables}
  ${socialCardVariables}
  ${osanoVariables}
`;

export default CssVariables;
