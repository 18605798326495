import create from "zustand";

const useScrollStore = create((set) => ({
  scrollY: null,
  setScrollY: (value) => set((state) => ({ scrollY: value })),

  targetScrollY: null,
  setTargetScrollY: (value) => set((state) => ({ targetScrollY: value })),

  scrollYDelta: null,
  setScrollYDelta: (value) => set((state) => ({ scrollYDelta: value }))
}));

export { useScrollStore };
