export const not = (query: string) => "not screen and " + query;
/**
 * Devices smaller than 400px wide (iPhones)
 */
export const forNarrowPhoneOnly = "(max-width: 399px)";
/**
 * Devices smaller than 600px wide;
 */
export const forPhoneOnly = "(max-width: 599px)";

export const forWidePhoneOnly = `(min-width: 460px) and ${forPhoneOnly}`;
/**
 * Devices width 600px and up;
 */
export const forTabletPortraitUp = "(min-width: 600px)";
/**
 * Devices width 899px and down;
 */
export const forTabletPortraitDown = "(max-width: 899px)";

export const forTabletPortraitOnly = `${forTabletPortraitUp} and ${forTabletPortraitDown}`;
/**
 * Devices width 900px and up;
 */
export const forTabletLandscapeUp = "(min-width: 900px)";

export const forTabletLandscapeWideOnly = `${forTabletLandscapeUp} and (max-width: 1000px)`;
/**
 * Devices width 1200px and up (Laptop MDPI);
 */
export const forDesktopUp = "(min-width: 1200px)";
/**
 * Devices width 1400px and up (Laptop HiDPI);
 */
export const forBigDesktopUp = "(min-width: 1800px)";
