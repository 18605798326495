import { createGlobalStyle } from "styled-components";
import { chivoFontFace, proximaNovaFontFace } from "./fonts";
import reset from "./reset";
import CssVariables from "./variables";
import { osanoStyling } from "./osano-dialog";

export const GlobalStyle = createGlobalStyle`
${chivoFontFace}
${proximaNovaFontFace}
${reset}
${osanoStyling}

:root {
    ${CssVariables}
}

html,
body {
    min-height: 100%;
    padding: 0;
    margin: 0;
    font-family: var(--default-text-font-family);
    line-height: var(--default-text-line-height);
    font-size: var(--default-text-size);
    font-weight: var(--default-text-weight);
    color: var(--default-text-color);
    background-color: var(--default-bg-color);
}

html {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
}

.hide-on-screen {
    visibility: hidden;
    position: absolute;
    left: 10000;
}
`;
