import { Svg, SvgProps } from "./Svg";

export function MicSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={15}
      height={15}
      viewBox="1 1 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={8.75}
        y={2.75}
        width={6.5}
        height={11.5}
        rx={3.25}
        stroke="currentColor"
        strokeWidth={1}
      />
      <path
        d="M18 11a6 6 0 01-12 0M11.75 17.75v3.5M8.75 21.25h6.5"
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="round"
      />
    </Svg>
  );
}

function Mic(props: SvgProps) {
  return (
    <Svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <rect
        x={8.75}
        y={2.75}
        width={6.5}
        height={11.5}
        rx={3.25}
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        d="M18 11a6 6 0 01-12 0M11.75 17.75v3.5M8.75 21.25h6.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </Svg>
  );
}

export default Mic;
