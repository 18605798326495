import { extend } from "@react-three/fiber";
import { AdditiveBlending, DoubleSide, ShaderMaterial } from "three";

export default class LineMaterial extends ShaderMaterial {
  constructor() {
    super({
      blending: AdditiveBlending,
      side: DoubleSide,
      transparent: true,
      vertexShader: `
      void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
      `,
      fragmentShader: `
      void main() {
        gl_FragColor = vec4( vec3(1.0), 1.0 );
      }`
    });
  }
}

extend({ LineMaterial });
