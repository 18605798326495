const screenFrag = `
varying vec2 vUv;

uniform sampler2D uTexture;

void main () {
  vec2 uv = vUv;
  vec4 col = texture2D(uTexture, uv) * 0.8;

  gl_FragColor = vec4(col.rgb, 1.0);
}
`;

export default screenFrag;
