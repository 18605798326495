const colors = {
  primary: {
    cardanoBlue: "#002891",
    white: "#FFFFFF",
    electricBlue: "#2353FF",
    slateGrey: "#40485E"
  },
  secondary: {
    mondayMist: "#FBFCFF",
    tyneFog: "#F3F6FF",
    londonFog: "#EEF2FE",
    lilacCloud: "#CED8F2",
    ashGrey: "#8A96B5",
    midnightBlue: "#022275"
  },
  feedback: {
    errorRed: "#FF493D",
    successGreen: "#3BDF5F",
    warningOrange: "#FFBA35",
    errorRedLight: "#FFEDED",
    successGreenLight: "#E9FFE9",
    warningOrangeLight: "#FFF6E9",
    pendingYellow: "#F7CC62",
    amendPurple: "#A777F4",
    approveGreen: "#75E3BB",
    rejectGrey: "#E1E6ED"
  },
  action: {
    pass: "#009168",
    warn: "#F7CC62",
    fail: "#FF5553"
  },
  shadow: {
    gray: "rgba(26, 52, 185, 0.15)"
  },
  gradient: {
    linear: {
      blue: "#03104a, #1748c9"
    }
  },
  others: {
    skyBlue: "#69B7FF",
    bannerLearnMore: "#F34054",
    bannerBackground: "#002677",
    catalystBlue: "#133FF0"
  }
} as const;
export default colors;
