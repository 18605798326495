import { deleteCookie, getCookie } from "./cookies";
import type { AppContext } from "next/app";

export default function getInitialPageProps({ ctx: { req, res } }: AppContext) {
  if (!req || !res) {
    return {
      loginErrorCode: undefined,
      auth0User: undefined
    };
  }
  const loginErrorCookie = getCookie<{ errorCode: string }>(req, "login_error");

  // only delete if there was something there
  if (loginErrorCookie) {
    deleteCookie(res, "login_error");
  }

  return {
    loginErrorCode: loginErrorCookie?.errorCode
  };
}
