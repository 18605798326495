import styled from "styled-components";

type iProps = {
  element?: "p" | "span" | "div";
  level?: "1" | "2" | "3";
  weight?: "default" | "bold";
  isMuted?: boolean;
  align?: "left" | "right" | "center";
};

const Text = styled.div.attrs<iProps>(({ element }) => ({
  as: element
}))<iProps>`
  color: ${(props) => (props.isMuted ? `var(--text-color-muted)` : "inherit")};
  text-align: ${(props) => props.align};
  font-size: var(${(props) => `--text-level${props.level}-size`});
  line-height: var(${(props) => `--text-level${props.level}-lineHeight`});
  font-weight: var(
    ${(props) =>
      props.weight === "bold"
        ? `--default-text-weight-bold`
        : `--default-text-weight`}
  );
`;

Text.defaultProps = {
  element: "span",
  level: "1",
  weight: "default"
};

export default Text;
