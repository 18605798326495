import { pxToRem } from "@input-output-hk/px-to-rem";
import styled from "styled-components";

export const BlessedIcon = styled.span`
  position: absolute;
  top: -${pxToRem(6)};
  right: -${pxToRem(6)};
  font-size: ${pxToRem(14)};
  text-shadow: 0 ${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.3);
`;
