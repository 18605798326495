import { Svg, SvgProps } from "./Svg";

export function GlossarySmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={15}
      height={15}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.237 12.462v-9.67M10.236 16c-.812-.804-2.577-1.367-4.618-1.367-1.552 0-2.928.323-3.87.822A.507.507 0 0 1 1 15.002V3.19c0-.277.11-.545.305-.739C2.21 1.582 3.799 1 5.618 1c.84 0 1.635.12 2.337.342M10.237 2.792v9.67M10.237 16c.812-.804 2.576-1.367 4.618-1.367 1.552 0 2.928.323 3.87.822a.507.507 0 0 0 .748-.453V3.19c0-.277-.11-.545-.305-.739C18.263 1.582 16.674 1 14.855 1c-2.042 0-3.806.73-4.618 1.792"
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

const Glossary = (props: SvgProps) => (
  <Svg
    width={21}
    height={17}
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon
    {...props}
  >
    <path
      d="M10.237 12.462v-9.67M10.236 16c-.812-.804-2.577-1.367-4.618-1.367-1.552 0-2.928.323-3.87.822A.507.507 0 0 1 1 15.002V3.19c0-.277.11-.545.305-.739C2.21 1.582 3.799 1 5.618 1c.84 0 1.635.12 2.337.342M10.237 2.792v9.67M10.237 16c.812-.804 2.576-1.367 4.618-1.367 1.552 0 2.928.323 3.87.822a.507.507 0 0 0 .748-.453V3.19c0-.277-.11-.545-.305-.739C18.263 1.582 16.674 1 14.855 1c-2.042 0-3.806.73-4.618 1.792"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Glossary;
