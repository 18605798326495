import { css } from "styled-components";

/**
 * Very simple CSS reset & normalisation
 * for ironing out some smaller quirks
 */
const reset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
  }

  /* Correct the cursor style of increment and decrement buttons in Chrome. */
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  /* Correct the odd appearance in Chrome and Safari. */
  [type="search"] {
    -webkit-appearance: textfield;
  }

  /* Remove the inner padding in Chrome and Safari on macOS. */
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /* Correct the inability to style clickable types in iOS and Safari, Change font properties to 'inherit' in Safari. */
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
`;

export default reset;
