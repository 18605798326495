import { useRef } from "react";
import { lockDocumentScrollPosition } from "../components/layouts/utils";
import { forTabletLandscapeUp } from "../tokens/media-queries";
import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";
import { useSharedState } from "./useSharedState";

export function useResponsiveMenuRemoteControl(stateKey: string) {
  return useSharedState(stateKey + ":showMenu", false);
}

export default function useResponsiveMenu(
  stateKey: string,
  desktopMediaQuery = forTabletLandscapeUp
) {
  const [showMenu, setShowMenu, getIsShowing] =
    useResponsiveMenuRemoteControl(stateKey);
  const [isDesktop, setIsDesktop] = useSharedState(
    stateKey + ":isDesktop",
    true
  );
  const toggleMenu = () => setShowMenu(!showMenu);
  const menuElementRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  /**
   * Singular "on-before-mount" effect to ensure the
   * navigation is hidden on first render
   */
  useIsomorphicLayoutEffect(() => {
    /**
     * 1. Handle screen width changes to enable/disable
     *    this mobile/desktop view
     */
    const desktopMatchMedia = window.matchMedia(desktopMediaQuery);
    const handleMediaChange = () => {
      if (desktopMatchMedia.matches) {
        setIsDesktop(true);
        setShowMenu(false);
      } else {
        setIsDesktop(false);
        setShowMenu(false);
      }
    };
    handleMediaChange();
    desktopMatchMedia.addEventListener("change", handleMediaChange);

    /**
     * 2. Handle clicks "outside" this menu to close
     *    it if open. (on mobile)
     */
    const handleClickOutside = (e: MouseEvent) => {
      if (desktopMatchMedia.matches || !getIsShowing()) {
        return;
      }
      const el = menuElementRef.current;
      const toggleButton = toggleButtonRef.current;
      const target = e.target as HTMLElement;
      if (el) {
        const isHidden = el.getAttribute("aria-hidden");
        const menuTarget = el.contains(target);
        const buttonTarget = toggleButton?.contains(target);
        if (!isHidden && !menuTarget && !buttonTarget) {
          e.preventDefault();
          setShowMenu(false);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    /**
     * Teardown:
     *  - Remove the event listeners
     */
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      desktopMatchMedia.removeEventListener("change", handleMediaChange);
    };
  }, []);

  /**
   * This will prevent the page from being scrolled
   * when the navigation is open on a mobile device
   *
   * Explanation:
   * If the menu itself is to tall for the viewport
   * and requires scroll, iOS touch devices will leak
   * the scroll through to the page, so if a user
   * scrolls the menu and hit the end, the momentum
   * will leak through and scroll the body. To prevent
   * this we'll lock the body scroll position
   */
  useIsomorphicLayoutEffect(() => {
    lockDocumentScrollPosition(!isDesktop && showMenu);
  }, [showMenu, isDesktop]);

  // For DOM attribute `aria-hidden` and not `aria-hidden="true|false"`
  const ariaHidden = showMenu ? undefined : true;

  return {
    showMenu,
    setShowMenu,
    ariaHidden,
    menuElementRef,
    toggleButtonRef,
    toggleMenu
  };
}
