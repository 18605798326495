import { Svg, SvgProps } from "./Svg";

function ProfileIcon(props: SvgProps) {
  return (
    <Svg
      width={18}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="M9.187 11.983A5.492 5.492 0 109.187 1a5.492 5.492 0 000 10.983zM1.746 19a7.54 7.54 0 0114.992 0"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default ProfileIcon;
