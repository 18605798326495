import { css } from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";

export const navigationVariables = css`
  --nav-color: var(--theme-color-primary);
  --nav-color-active: var(--theme-color-primary);
  --secondary-nav-color: var(--theme-color-secondary);

  --nav-underline-thickness: ${pxToRem(3)};
  --nav-underline-width: ${pxToRem(30)};
  --nav-underline-border-radius: ${pxToRem(10)};

  --secondary-nav-padding: ${pxToRem(8)};
`;
