import { extend } from "@react-three/fiber";
import { isMobile } from "react-device-detect";
import { ShaderMaterial } from "three";

export default class DotMaterial extends ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        uTime: { value: 1 },
        uScrollY: { value: 0 },
        xOffset: { value: isMobile ? 90 : 120 },
        opacity: { value: 1 },
        uFlowMap: { value: null },
        yRotationAmount: { value: isMobile ? 0.0025 : 0.005 },
        finalOpacityMultiplier: { value: isMobile ? 0.4 : 0.6 }
      },
      vertexShader: `

      const float PI  = 3.14159265358979323846264;

      mat4 rotationMatrix(vec3 axis, float angle)
      {
          axis = normalize(axis);
          float s = sin(angle);
          float c = cos(angle);
          float oc = 1.0 - c;
          
          return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                      oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                      oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                      0.0,                                0.0,                                0.0,                                1.0);
      }

      uniform float uTime;
      uniform float xOffset;
      uniform float uScrollY;
      uniform float yRotationAmount;
      uniform sampler2D uFlowMap;

      attribute float size;
      attribute vec3 color;

      varying vec4 transformed;
      varying vec3 vColor;
      varying float perspectiveSizeSq;
      varying float perspectiveSize;
      varying float random;
      
      void main() {
        
        vColor = color;
        
        float x = position.x;
        float y = position.y;
        float z = position.z;

        random = position.z;
        
        vec3 transformed = vec3(
          x + xOffset,
          y,
          0.0
        );
          
        vec4 rotatedPos = vec4(transformed, 1.0) * rotationMatrix( vec3(0.0, 1.0, 0.0), (y * yRotationAmount) );
        rotatedPos.xyz *= 0.5;
        
        // spin on scroll
        rotatedPos *= rotationMatrix( vec3(0.0, 1.0, 0.0), (PI * 0.75) + (uScrollY * 0.001) );

        transformed = rotatedPos.xyz;
        
        vec4 mvPosition2 = vec4( transformed, 1.0 );
        mvPosition2 = modelViewMatrix * mvPosition2;
        vec4 newPos = projectionMatrix * mvPosition2;
        newPos /= newPos.w;
        gl_Position = newPos;
        
        vec4 mousePos = texture2D(uFlowMap, ((newPos.xy + 1.0) / 2.0));
        transformed.xy += mousePos.xy * 13.0;

        vec4 mvPosition = vec4( transformed, 1.0 );
        mvPosition = modelViewMatrix * mvPosition;
        gl_Position = projectionMatrix * mvPosition;
        
        perspectiveSizeSq = dot(gl_Position.xyz, gl_Position.xyz) * 0.000005;
        perspectiveSize = length(gl_Position.xyz) * 0.009;
        
        gl_PointSize = max(10.0, size / perspectiveSizeSq);
      }`,

      fragmentShader: `
        uniform float uTime;
        uniform float opacity;
        uniform float finalOpacityMultiplier;

        varying float random;
        varying vec3 vColor;
        varying float perspectiveSizeSq;
        varying float perspectiveSize;

        float rand(float n){
          return fract(sin(n) * 43758.5453123);
        }

        void main() {
          float border = 0.2;
          float radius = 0.5;
          float dist = radius - distance(gl_PointCoord.xy, vec2(0.5));
          float t = smoothstep(0.0, border, dist);
          // gl_FragColor = vec4(vColor, t * (opacity * (max(perspectiveSizeSq*1.2, 0.05) )));
          gl_FragColor = vec4(vColor, t * 0.5);

          gl_FragColor.rgb *= 1.3;
          gl_FragColor.rgb += 0.1;

          // gl_FragColor.a *= 1.0-(perspectiveSize*0.3);

          float finalOpacity = clamp(sin((uTime * 0.02) + random * 10.0) + 1.05, 0.35, 1.0);

          gl_FragColor.a *= finalOpacity;
          gl_FragColor.a *= finalOpacityMultiplier;
        }`
    });
  }
}

extend({ DotMaterial });
