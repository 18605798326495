import React from "react";
import { Svg, SvgProps } from "./Svg";

function ChevronLeft({
  color = "currentColor",
  ...props
}: SvgProps & { color?: string }) {
  return (
    <Svg
      width={7}
      height={13}
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="m.571 5.946 4.75-4.75a.719.719 0 0 1 .554-.238c.238 0 .396.08.554.238a.765.765 0 0 1 0 1.108L2.234 6.5l4.195 4.196a.765.765 0 0 1 0 1.108.765.765 0 0 1-1.108 0l-4.75-4.75a.765.765 0 0 1 0-1.108Z"
        fill={color}
      />
    </Svg>
  );
}

export default ChevronLeft;
