import {
  OrthographicCamera,
  ScreenQuad,
  shaderMaterial,
  useFBO
} from "@react-three/drei";
import { createPortal, extend, useFrame, useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import { Leva, useControls } from "leva";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  BufferAttribute,
  Color,
  LinearFilter,
  RGBAFormat,
  Scene,
  Vector2,
  Vector3
} from "three";

// config
import config from "./config";

// shaders
import advectFrag from "./shaders/advectFrag";
import divergenceFrag from "./shaders/divergenceFrag";
import emptyVert from "./shaders/emptyVert";
import gradientSubtractFrag from "./shaders/gradientSubtractFrag";
import pointerFrag from "./shaders/pointerFrag";
import pointerVelocityFrag from "./shaders/pointerVelocityFrag";
import pressureFrag from "./shaders/pressureFrag";

// state
import { useFlowMapStore } from "./state/flowMap";
import { useScrollStore } from "./state/scroll";

const palette = [
  new Color(0x1a3dad).multiplyScalar(1.0),
  new Color(0x980000).multiplyScalar(1.0),
  new Color(0x009800).multiplyScalar(1.0)
];

const FlowMap = () => {
  const { size, gl } = useThree();

  const scrollYDelta = useRef(useScrollStore.getState().scrollYDelta);
  useEffect(
    () =>
      useScrollStore.subscribe(
        (state) => (scrollYDelta.current = state.scrollYDelta)
      ),
    []
  );

  // refs
  const quadCamera = useRef();
  const quadRef = useRef();
  const prevMouse = useRef(new Vector2());
  // const downScale = useRef(maxFPS > 60 ? 1.0 : 0.5)
  const downScale = useRef(0.5 * window.devicePixelRatio);
  const inkColor = useRef(palette[0]);
  const mouseDelta = useRef(new Vector3());
  const uRdx = useRef(new Vector2());
  const pressureIterations = useRef(5); // increase for higher quality fluid sim
  const mousePos = useRef(new Vector2());
  const NDCMousePos = useRef(new Vector2());
  const mouseDown = useRef(false);

  function setMousePos(e) {
    const x = e.clientX - gl.domElement.offsetLeft;
    const y = e.clientY - gl.domElement.offsetTop;

    NDCMousePos.current.x = (x / window.innerWidth) * 2 - 1;
    NDCMousePos.current.y = (1 - y / window.innerHeight) * 2 - 1;

    mousePos.current.x = x;
    mousePos.current.y = gl.domElement.height - y;
  }

  const scene = useMemo(() => new Scene(), []);

  // animation
  const tl = useRef(
    gsap.timeline({
      repeat: -1
    })
  );

  // context
  const setFlowMap = useFlowMapStore((state) => state.setFlowMap);
  const setFlowMapDensity = useFlowMapStore((state) => state.setFlowMapDensity);

  // constructor
  useEffect(() => {
    document.addEventListener(
      "mousemove",
      (e) => {
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchmove",
      (e) => {
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchstart",
      (e) => {
        mouseDown.current = true;
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchend",
      (e) => {
        mouseDown.current = false;
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchcancel",
      (e) => {
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    // add uvs to ScreenQuad
    const geometry = quadRef.current.geometry;
    const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);
    geometry.setAttribute("uv", new BufferAttribute(uvs, 2));

    // animate ink colour
    tl.current.to(inkColor.current, {
      r: palette[1].r,
      g: palette[1].g,
      b: palette[1].b,
      duration: 2
    });
    tl.current.to(inkColor.current, {
      r: palette[2].r,
      g: palette[2].g,
      b: palette[2].b,
      duration: 2
    });
    tl.current.to(inkColor.current, {
      r: palette[0].r,
      g: palette[0].g,
      b: palette[0].b,
      duration: 2
    });
  }, []);

  // state
  const [pixelSize, setPixelSize] = useState([
    Math.floor(size.width),
    Math.floor(size.height)
  ]);
  const [aspectRatio, setAspectRatio] = useState(0.0);

  // controls
  const {
    mouseRadius,
    velocityMultiplier,
    velocityDiffusion,
    densityDiffusion,
    gradientAmount,
    uFadeAmount
  } = useControls("Flowmap Params", {
    mouseRadius: {
      value: 0.0005,
      min: 0.0001,
      max: 0.003,
      step: 0.0001
    },
    velocityMultiplier: {
      value: 1,
      min: 5,
      max: 40,
      step: 1
    },
    velocityDiffusion: {
      value: 0.999,
      min: 0.9,
      max: 0.9999,
      step: 0.001
    },
    densityDiffusion: {
      value: 0.999,
      min: 0.99,
      max: 0.9999,
      step: 0.0001
    },
    gradientAmount: {
      value: 1.0,
      min: 0,
      max: 2.0,
      step: 0.05
    },
    uFadeAmount: {
      value: 0.995,
      min: 0.9,
      max: 1.0
    }
  });

  useEffect(() => {
    const width = Math.floor(size.width * downScale.current);
    const height = Math.floor(size.height * downScale.current);
    setPixelSize([width, height]);

    uRdx.current.set(1.0 / width, 1.0 / height);
  }, [size]);

  useEffect(() => {
    setAspectRatio(pixelSize[0] / pixelSize[1]);
  }, [pixelSize]);

  /*
    =========================================================
    Create Materials
    =========================================================
    */
  const PointerVelocityMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uTexture: null,
      uAspect: aspectRatio,
      uMousePos: new Vector2(),
      uMouseRadius: mouseRadius,
      uFadeAmount: uFadeAmount,
      uColor: new Vector3(),
      uScrollYDelta: 0.0,
      uScrollDeltaSinRandom: 0.0
    },
    emptyVert,
    pointerVelocityFrag
  );
  extend({ PointerVelocityMaterial });
  const pointerVelMatRef = useRef();

  const PointerMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uTexture: null,
      uAspect: aspectRatio,
      uMousePos: new Vector2(),
      uMouseRadius: mouseRadius,
      uFadeAmount: uFadeAmount,
      uColor: new Vector3(),
      uMouseDelta: new Vector3()
    },
    emptyVert,
    pointerFrag
  );
  extend({ PointerMaterial });
  const pointerMatRef = useRef();

  // Advection
  const AdvectMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uTexture: null,
      uVelocityTex: null,
      uRdx: new Vector2(),
      uTimestep: 0.0,
      uDiffusion: 0.0
    },
    emptyVert,
    advectFrag
  );
  extend({ AdvectMaterial });
  const advectMatRef = useRef();

  // Divergence
  const DivergenceMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uTexture: null,
      uRdx: new Vector2()
    },
    emptyVert,
    divergenceFrag
  );
  extend({ DivergenceMaterial });
  const divergenceMatRef = useRef();

  // Pressure
  const PressureMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uTexture: null, // pressure
      uDivergenceTex: null,
      uRdx: new Vector2()
    },
    emptyVert,
    pressureFrag
  );
  extend({ PressureMaterial });
  const pressureMatRef = useRef();

  // Gradient Subtract
  const GradientSubtractMaterial = shaderMaterial(
    {
      uResolution: new Vector2(pixelSize[0], pixelSize[1]),
      uPressureTex: null, // pressure
      uTexture: null,
      uRdx: new Vector2(),
      uGradientAmount: 1.0
    },
    emptyVert,
    gradientSubtractFrag
  );
  extend({ GradientSubtractMaterial });
  const gradientMatRef = useRef();

  /*
    =========================================================
    Create Render Targets
    =========================================================
    */
  const rtOptions = {
    multisample: false,
    // samples: 8,
    stencilBuffer: false,
    depthWrite: false,
    depthBuffer: false,
    minFilter: LinearFilter,
    magFilter: LinearFilter,
    format: RGBAFormat,
    type: config.floatType
  };

  // velocity
  let velocityRtA = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  let velocityRtB = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  const swapVelocity = () => {
    const temp = velocityRtA;
    velocityRtA = velocityRtB;
    velocityRtB = temp;
  };

  // Density
  let densityRtA = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  let densityRtB = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  const swapDensity = () => {
    const temp = densityRtA;
    densityRtA = densityRtB;
    densityRtB = temp;
  };

  // Divergence
  const divergenceRt = useFBO(pixelSize[0], pixelSize[1], rtOptions);

  // Pressure
  let pressureRtA = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  let pressureRtB = useFBO(pixelSize[0], pixelSize[1], rtOptions);
  const swapPressure = () => {
    const temp = pressureRtA;
    pressureRtA = pressureRtB;
    pressureRtB = temp;
  };

  useFrame((state, dt) => {
    quadRef.current.material = pointerVelMatRef.current;

    const deltaX =
      (NDCMousePos.current.x - prevMouse.current.x) * velocityMultiplier;
    const deltaY =
      (NDCMousePos.current.y - prevMouse.current.y) * velocityMultiplier;

    prevMouse.current.x = NDCMousePos.current.x;
    prevMouse.current.y = NDCMousePos.current.y;

    pointerVelMatRef.current.uniforms.uMousePos.value = NDCMousePos.current;
    mouseDelta.current.set(deltaX, deltaY, 0);
    pointerVelMatRef.current.uniforms.uColor.value = mouseDelta.current;
    pointerVelMatRef.current.uniforms.uTexture.value = velocityRtA.texture;
    pointerVelMatRef.current.uniforms.uScrollYDelta.value =
      scrollYDelta.current;
    pointerVelMatRef.current.uniforms.uScrollDeltaSinRandom.value =
      Math.sin(state.clock.elapsedTime * 0.5) * 500;

    // setFlowMap(velocityRtA.texture)

    state.gl.setRenderTarget(velocityRtB);
    state.gl.render(scene, quadCamera.current);
    swapVelocity();

    // pointer density
    quadRef.current.material = pointerMatRef.current;
    pointerMatRef.current.uniforms.uMousePos.value = NDCMousePos.current;
    pointerMatRef.current.uniforms.uTexture.value = densityRtA.texture;
    pointerMatRef.current.uniforms.uColor.value = inkColor.current;
    pointerMatRef.current.uniforms.uMouseDelta.value = mouseDelta.current;
    state.gl.setRenderTarget(densityRtB);
    state.gl.render(scene, quadCamera.current);
    swapDensity();

    // advection
    quadRef.current.material = advectMatRef.current;

    // velocity advection
    advectMatRef.current.uniforms.uRdx.value = uRdx.current;
    advectMatRef.current.uniforms.uTimestep.value = dt * 1000;
    advectMatRef.current.uniforms.uDiffusion.value = velocityDiffusion;
    advectMatRef.current.uniforms.uTexture.value = velocityRtA.texture;
    advectMatRef.current.uniforms.uVelocityTex.value = velocityRtA.texture;
    state.gl.setRenderTarget(velocityRtB);
    state.gl.render(scene, quadCamera.current);
    swapVelocity();

    // density advection
    advectMatRef.current.uniforms.uTexture.value = densityRtA.texture;
    advectMatRef.current.uniforms.uVelocityTex.value = velocityRtA.texture;
    advectMatRef.current.uniforms.uDiffusion.value = densityDiffusion;
    state.gl.setRenderTarget(densityRtB);
    state.gl.render(scene, quadCamera.current);
    swapDensity();

    // divergence
    quadRef.current.material = divergenceMatRef.current;

    divergenceMatRef.current.uniforms.uTexture.value = velocityRtA.texture;
    divergenceMatRef.current.uniforms.uRdx.value = uRdx.current;
    state.gl.setRenderTarget(divergenceRt);
    state.gl.render(scene, quadCamera.current);

    // pressure
    quadRef.current.material = pressureMatRef.current;

    pressureMatRef.current.uniforms.uRdx.value = uRdx.current;

    for (let i = 0; i < pressureIterations.current; i++) {
      pressureMatRef.current.uniforms.uTexture.value = pressureRtA.texture;
      pressureMatRef.current.uniforms.uDivergenceTex.value =
        divergenceRt.texture;
      state.gl.setRenderTarget(pressureRtB);
      state.gl.render(scene, quadCamera.current);
      swapPressure();
    }

    // subtract gradient
    quadRef.current.material = gradientMatRef.current;
    gradientMatRef.current.uniforms.uRdx.value = uRdx.current;
    gradientMatRef.current.uniforms.uTexture.value = velocityRtA.texture;
    gradientMatRef.current.uniforms.uPressureTex.value = pressureRtA.texture;
    gradientMatRef.current.uniforms.uGradientAmount.value = gradientAmount;
    state.gl.setRenderTarget(velocityRtB);
    state.gl.render(scene, quadCamera.current);
    swapVelocity();

    setFlowMap(velocityRtA.texture);
    setFlowMapDensity(densityRtA.texture);

    // render density
    // quadRef.current.material = pointerMatRef.current

    // render to screen
    state.gl.setRenderTarget(null);
  });

  return createPortal(
    <>
      <Leva hidden />
      <ScreenQuad ref={quadRef} />
      <OrthographicCamera
        makeDefault={false}
        ref={quadCamera}
        position={[0, 0, 1]}
      />
      {/* Materials */}
      <pointerVelocityMaterial
        ref={pointerVelMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
        uAspect={aspectRatio}
        uMouseRadius={mouseRadius}
        uFadeAmount={uFadeAmount}
      />
      <pointerMaterial
        ref={pointerMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
        uAspect={aspectRatio}
        uMouseRadius={mouseRadius}
        uFadeAmount={uFadeAmount}
      />
      <advectMaterial
        ref={advectMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
      />
      <divergenceMaterial
        ref={divergenceMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
      />
      <pressureMaterial
        ref={pressureMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
      />
      <gradientSubtractMaterial
        ref={gradientMatRef}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
      />
    </>,
    scene
  );
};

export default FlowMap;
