import { UserProfile, UserProvider } from "@auth0/nextjs-auth0";
import App, { AppInitialProps, AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useRef } from "react";
import { SkipNavContent, SkipNavLink } from "../components/a11y/SkipNav";
import ContributeButton from "../components/contribute/contribute-button/ContributeButton";
import {
  Responsive,
  ResponsiveContent,
  ResponsiveNav
} from "../components/layouts/Responsive";
import LoginErrorHandler from "../components/login-prompt/LoginErrorHandler";

import { RoadmapContextProvider } from "@input-output-hk/essential-cardano-roadmap";
import PageFooter from "../components/page-footer/PageFooter";
import { I18nContext } from "../context/I18nContext";
import LoginPromptProvider from "../context/LoginPromptContext";
import NotificationCountProvider from "../context/NotificationCountContext";
import ViewProfilePromptProvider from "../context/ViewProfilePromptContext";
import { GlobalStyle } from "../styles/global";
import getInitialPageProps from "../utils/initial-page-props";
import { getPathWithoutQueryString } from "../utils/path-utils";
import { isOnKeyMilestonesPage } from "../utils/url-utils";
import { ConfigProvider } from "../context/ConfigContext";
import AnalyticsProvider from "../context/AnalyticsContext";

const hasPageSearch = (path: string) => {
  return path === "/" || path === "/search";
};

const isOnContributePage = (path: string) => {
  return path.startsWith("/contribute");
};

type ECAppProps = {
  auth0User?: UserProfile;
  loginErrorCode?: string;
};

function EssentialCardanoApp({
  Component,
  router,
  auth0User,
  loginErrorCode,
  pageProps
}: AppProps & ECAppProps) {
  const { locale } = useRouter();
  const i18nContext = useRef({
    locale,
    cache: {}
  });
  const isRoadmap = isOnKeyMilestonesPage(router.asPath);
  const shouldShowSearch =
    !hasPageSearch(getPathWithoutQueryString(router.asPath)) && !isRoadmap;
  const shouldShowContributeButton =
    !isOnContributePage(router.asPath) && !isOnKeyMilestonesPage(router.asPath);

  return (
    <>
      <Head>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
      </Head>
      <ConfigProvider>
        <AnalyticsProvider>
          <I18nContext.Provider value={i18nContext.current}>
            {/* Provide user from session cookie
            See documentation here https://auth0.github.io/nextjs-auth0/modules/frontend_use_user.html#userproviderprops
          */}
            <UserProvider user={auth0User}>
              <LoginPromptProvider>
                <NotificationCountProvider>
                  <RoadmapContextProvider>
                    <ViewProfilePromptProvider>
                      <GlobalStyle />
                      <SkipNavLink />
                      <LoginErrorHandler errorCode={loginErrorCode} />
                      <Responsive>
                        <ResponsiveNav
                          shouldShowSearch={shouldShowSearch}
                          showRoadmapMenu={isRoadmap}
                        />
                        <ResponsiveContent>
                          <SkipNavContent />
                          <Component {...pageProps} />
                        </ResponsiveContent>
                        {shouldShowContributeButton && <ContributeButton />}
                      </Responsive>
                      {
                        <PageFooter
                          theme={isRoadmap ? "dark" : "default"}
                          showBorder={!isRoadmap}
                        />
                      }
                    </ViewProfilePromptProvider>
                  </RoadmapContextProvider>
                </NotificationCountProvider>
              </LoginPromptProvider>
            </UserProvider>
          </I18nContext.Provider>
        </AnalyticsProvider>
      </ConfigProvider>
    </>
  );
}

const getInitialProps: typeof App["getInitialProps"] = async (
  appContext
): Promise<AppInitialProps & ECAppProps> => {
  const ecProps = getInitialPageProps(appContext);
  const appInitialProps = await App.getInitialProps(appContext);

  return {
    ...ecProps,
    ...appInitialProps
  };
};

EssentialCardanoApp.getInitialProps = getInitialProps;

export default EssentialCardanoApp;
