import { pxToRem } from "@input-output-hk/px-to-rem";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import colors from "../../tokens/colors";
import { CloseSmall } from "../icons/Close";

export const TagWithOutClose = styled.a`
  align-items: center;
  border: 0;
  border-radius: 0.25rem;
  color: var(--link-color);
  display: inline-flex;
  font-size: var(--tag-font-size);
  justify-content: center;
  padding: 0.125rem 0.5rem;

  [data-icon] {
    stroke: var(--tag-close-color);
    cursor: pointer;
    margin-left: var(--spacing-xsmall);
    &:hover {
      stroke: var(--tag-close-color-hover);
    }
  }

  background: var(--tag-background-color);

  &[data-variant="outline"] {
    background: transparent;
    border: ${pxToRem(1)} solid ${colors.shadow.gray};
  }

  &[href] {
    text-decoration: none;
    cursor: pointer;
  }
  &:hover {
    background: var(--tag-background-color-hover);
  }
`;
interface iTag {
  close?: boolean | "close";
  onClickClose?: (e: React.MouseEvent<SVGSVGElement>) => void;
  variant?: "default" | "outline";
  style?: CSSProperties;
}
const Tag: React.FC<iTag> = ({
  children,
  close,
  onClickClose,
  variant = "default",
  ...rest
}) => (
  <TagWithOutClose data-variant={variant} data-tag {...rest}>
    <span>{children}</span>
    {close && <CloseSmall onClick={onClickClose} />}
  </TagWithOutClose>
);

Tag.defaultProps = {
  close: false
};

export default Tag;
