// 3rd party
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useMemo, useRef } from "react";
import { AdditiveBlending } from "three";

// utility
import { lerp } from "./math";

// materials
import "./materials/ParticleBGMaterial";

const POINT_COUNT = 5000;
const POINT_MAX = [1000, 1000, 300]; // particle max bounds [x,y,z]
const POINT_SIZE = 20;

export default function ParticleBG() {
  // refs
  const scrollLerpSpeed = useRef(0.05);
  const targetScrollY = useRef(0);
  const scrollY = useRef(0);
  const particleGeometry = useRef();

  useEffect(() => {
    targetScrollY.current = window.scrollY;
    window.addEventListener("scroll", () => {
      targetScrollY.current = window.scrollY;
    });
  }, []);

  const [coords, sizes, ids] = useMemo(() => {
    const initialCoords = [];
    const initialSizes = [];
    const initialIds = [];
    for (let i = 0; i < POINT_COUNT; i++) {
      initialCoords.push(Math.random() * POINT_MAX[0] - POINT_MAX[0] / 2);
      initialCoords.push(Math.random() * POINT_MAX[1]);
      initialCoords.push(Math.random() * POINT_MAX[2] - POINT_MAX[2] / 2 + 80);

      initialSizes.push(POINT_SIZE + Math.random() * 1);

      initialIds.push(Math.random());
    }

    const coords = new Float32Array(initialCoords);
    const sizes = new Float32Array(initialSizes);
    const ids = new Float32Array(initialIds);

    return [coords, sizes, ids];
  }, []);

  useFrame((state) => {
    scrollY.current = lerp(
      scrollY.current,
      targetScrollY.current,
      scrollLerpSpeed.current
    );
    particleGeometry.current.material.uniforms.uScrollY.value = scrollY.current;
    particleGeometry.current.material.uniforms.uTime.value =
      state.clock.getElapsedTime();
  });

  return (
    <>
      <points ref={particleGeometry} frustumCulled={false}>
        <bufferGeometry>
          <bufferAttribute
            attachObject={["attributes", "position"]}
            count={coords.length / 3}
            array={coords}
            itemSize={3}
          />
          <bufferAttribute
            attachObject={["attributes", "size"]}
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
          <bufferAttribute
            attachObject={["attributes", "id"]}
            count={ids.length}
            array={ids}
            itemSize={1}
          />
        </bufferGeometry>
        <particleBGMaterial
          transparent
          depthWrite={false}
          blending={AdditiveBlending}
        />
      </points>
    </>
  );
}
