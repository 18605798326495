import { css } from "styled-components";

export const socialCardVariables = css`
  --social-card-bg: #fff;
  --social-card-bg-facebook: #1877f2;
  --social-card-bg-instagram: linear-gradient(
    71.06deg,
    #ee0013 6.92%,
    #bb00ae 94.89%
  );
  --social-card-bg-twitter: #1d9bf0;
  --social-card-bg-linkedin: linear-gradient(
    247.69deg,
    #0073b1 6.71%,
    #0084bf 92.72%
  );
  --social-card-bg-youtube: #cc0000;
  --social-card-bg-reddit: #ff4500;
  --social-card-bg-github: #000;
  --social-card-bg-discord: #5865f2;
  --social-card-bg-telegram: linear-gradient(
    247.69deg,
    #1d93d2 6.71%,
    #38b0e3 92.72%
  );
  --social-card-bg-mailinglist: #2353ff;
  --social-card-bg-stackexchange: #242629;
`;
