import type { Auth0Claims } from "../api-utils/session";
import { Maybe } from "./types";
import { UserProfile } from "@auth0/nextjs-auth0";

/**
 * Roles added to access token and user response
 * via https://auth0.com/docs/authorization/authorization-policies/sample-use-cases-rules-with-authorization#add-user-roles-to-tokens
 */
const namespace = "https://essentialcardano.io";
const blessedRole = "blessed";
const testRole = "test";

export type PossibleUser = UserProfile | Auth0Claims;

function userHasRole(user: Maybe<PossibleUser>, role: string) {
  const key = `${namespace}/roles`;
  const roles =
    user && Array.isArray(user[key]) ? (user[key] as string[]) : undefined;
  return roles?.includes(role) || false;
}

export function isTestUser(user: Maybe<PossibleUser>) {
  return userHasRole(user, testRole);
}

export function isBlessedUser(user: Maybe<PossibleUser>) {
  return userHasRole(user, blessedRole);
}
