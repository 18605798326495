import { pxToRem } from "@input-output-hk/px-to-rem";
import styled from "styled-components";
import Facebook from "../icons/Facebook";
import LinkedIn from "../icons/LinkedIn";
import Twitter from "../icons/Twitter";
import Instagram from "../icons/Instagram";
import YouTube from "../icons/YouTube";
import { iogSocials as officialUrl } from "../../links";

const OfficialSocialWrapper = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  [data-icon] {
    width: ${pxToRem(22)};
    height: ${pxToRem(22)};
    margin: 0 ${pxToRem(22)} 0 0;
  }
`;

const SocialLink = styled.a`
  color: inherit;
`;
const OfficialSocialIcons: React.FC<
  React.ComponentPropsWithoutRef<typeof OfficialSocialWrapper>
> = (props) => (
  <OfficialSocialWrapper data-social-official-links {...props}>
    <SocialLink
      data-social-link
      href={officialUrl.twitter}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <Twitter />
    </SocialLink>
    <SocialLink
      data-social-link
      href={officialUrl.linkedin}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <LinkedIn />
    </SocialLink>
    <SocialLink
      data-social-link
      href={officialUrl.youtube}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <YouTube strokeWidth={3} />
    </SocialLink>
    <SocialLink
      data-social-link
      href={officialUrl.facebook}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <Facebook />
    </SocialLink>
    <SocialLink
      data-social-link
      href={officialUrl.instagram}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <Instagram />
    </SocialLink>
  </OfficialSocialWrapper>
);
export default OfficialSocialIcons;
