import React from "react";
import styled from "styled-components";
import { usePortal } from "../../hooks/usePortal";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletPortraitUp } from "../../tokens/media-queries";
import AlertDialogBox from "../alert-dialog/AlertDialog";
import { Column } from "../Stack";

import ProfileButton from "./ProfileButton";
import LogoutButton from "./LogoutButton";
import NotificationButton from "./NotificationButton";
import AccountButton from "./AccountButton";

type Props = {
  isOpen?: boolean;
  onRequestClose(): void;
};

const StyledAlertBox = styled(AlertDialogBox)`
  text-align: center;
  width: auto;
  padding: var(--spacing-xlarge);
  @media ${forTabletPortraitUp} {
    padding: var(--spacing-xlarge) var(--spacing-xxlarge);
  }
`;

const ButtonContainer = styled.div`
  margin-top var(--spacing-large);
  height: ${pxToRem(210)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ViewProfilePrompt: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  children = "Profile"
}) => {
  const portal = usePortal();

  return portal(
    <StyledAlertBox isOpen={isOpen} onRequestClose={onRequestClose}>
      <Column level="2">{children}</Column>
      <ButtonContainer>
        <ProfileButton />
        <AccountButton />
        <NotificationButton />
        <LogoutButton />
      </ButtonContainer>
    </StyledAlertBox>
  );
};

export default ViewProfilePrompt;
