import { pxToRem } from "@input-output-hk/px-to-rem";
import Link, { LinkProps } from "next/link";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { fauxUnderlineCss } from "./fauxUnderlineCss";

//Not using this until we have some more content in place
import colors from "../../tokens/colors";
import {
  forPhoneOnly,
  forTabletPortraitDown
} from "../../tokens/media-queries";
import ChevronLeft from "../icons/ChevronLeft";
import ChevronRight from "../icons/ChevronRight";

const SecondaryNavigationElement = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: var(--nav-underline-thickness);
  padding: 0 0 var(--nav-underline-thickness) 0;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  overflow-x: auto;
  position: relative;
  width: 100%;
  padding-right: ${pxToRem(50)};
`;

const LeftFade = styled.span<{
  shouldShow: boolean;
}>`
  position: absolute;
  height: ${pxToRem(47)};
  width: ${pxToRem(100)};
  top: 0;
  pointer-events: none;
  margin-left: ${pxToRem(-10)};

  @media ${forTabletPortraitDown} {
    margin-left: ${pxToRem(-35)};
  }

  ${(props) =>
    props.shouldShow === false
      ? "visibility: hidden;"
      : "visibility: visible;"};

  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
`;

const RightFade = styled.span<{
  shouldShow: boolean;
}>`
  position: absolute;
  height: ${pxToRem(47)};
  width: ${pxToRem(100)};
  top: 0;
  pointer-events: none;
  right: ${pxToRem(28)};

  ${(props) =>
    props.shouldShow === false
      ? "visibility: hidden;"
      : "visibility: visible;"};

  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
`;

const NavItemElement = styled.a`
  display: inline-block;
  padding: var(--secondary-nav-padding);
  color: var(--theme-color-secondary);
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  &[aria-current] {
    font-weight: 600;
    color: var(--nav-color-active);
    ${fauxUnderlineCss}
  }
`;

const ScrollButton = styled.button`
  border: 0;
  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
  border: ${pxToRem(1)} solid ${colors.secondary.lilacCloud};
  border-radius: 10%;
  color: var(--theme-color-primary);
  background-color: var(--default-bg-color);
  position: absolute;
  top: 20%;
  &[data-back] {
    left: 0;
  }
  &[data-forward] {
    right: 0;
  }
  [hidden] {
    display: none;
  }
  cursor: pointer;
  touch-action: manipulation;
  @media ${forPhoneOnly} {
    display: none;
  }
`;

export const NavItem: React.FC<{
  href: LinkProps["href"];
  active?: boolean;
  shallow?: LinkProps["shallow"];
}> = ({ href, active, shallow, ...props }) => {
  const ariaCurrent = active ? "page" : undefined;
  return (
    <Link href={href} passHref shallow={shallow}>
      <NavItemElement aria-current={ariaCurrent} {...props} />
    </Link>
  );
};

type Props = React.ComponentPropsWithoutRef<typeof SecondaryNavigationElement>;
const SecondaryNavigation: React.FC<Props> = (props) => {
  const secondaryNavigationRef = useRef<HTMLDivElement>(null);
  const [shouldShowLeftFade, setShouldShowLeftFade] = useState(false);
  const [shouldShowRightFade, setShouldShowRightFade] = useState(true);

  const [shouldShowLeftArrow, setShouldShowLeftArrow] = useState(false);
  const [shouldShowRightArrow, setShouldShowRightArrow] = useState(true);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const scrollValue = 200;

  useEffect(() => {
    checkForOverflow();
    function checkForOverflow() {
      if (secondaryNavigationRef.current) {
        if (
          secondaryNavigationRef.current.scrollWidth >
          secondaryNavigationRef.current.clientWidth
        ) {
          setIsOverflowing(true);
        } else {
          setIsOverflowing(false);
        }
      }
    }
    window.addEventListener("resize", checkForOverflow);

    function removeListener() {
      window.removeEventListener("resize", checkForOverflow);
    }
    return removeListener;
  }, []);

  return (
    <Container data-secondary-nav-container>
      <SecondaryNavigationElement
        role="navigation"
        onScroll={(e) => {
          if (e.currentTarget.scrollLeft > 0) {
            setShouldShowLeftFade(true);
            setShouldShowLeftArrow(true);
          } else {
            setShouldShowLeftFade(false);
            setShouldShowLeftArrow(false);
          }
          if (
            e.currentTarget.scrollLeft <
            e.currentTarget.scrollWidth - e.currentTarget.clientWidth
          ) {
            setShouldShowRightFade(true);
            setShouldShowRightArrow(true);
          } else {
            setShouldShowRightFade(false);
            setShouldShowRightArrow(false);
          }
        }}
        ref={secondaryNavigationRef}
        data-secondary-nav
        {...props}
      />
      <LeftFade shouldShow={shouldShowLeftFade && isOverflowing} />
      <RightFade shouldShow={shouldShowRightFade && isOverflowing} />
      <ScrollButton
        data-back
        hidden={!(shouldShowLeftArrow && isOverflowing)}
        onClick={() => {
          if (secondaryNavigationRef.current) {
            secondaryNavigationRef.current.scrollLeft =
              secondaryNavigationRef.current?.scrollLeft - scrollValue;
          }
        }}
      >
        <ChevronLeft
          height={15}
          width={10}
          color={colors.primary.cardanoBlue}
        />
      </ScrollButton>
      <ScrollButton
        data-forward
        hidden={!(shouldShowRightArrow && isOverflowing)}
        onClick={() => {
          if (secondaryNavigationRef.current) {
            secondaryNavigationRef.current.scrollLeft =
              secondaryNavigationRef.current?.scrollLeft + scrollValue;
          }
        }}
      >
        <ChevronRight
          height={15}
          width={10}
          color={colors.primary.cardanoBlue}
        />
      </ScrollButton>
    </Container>
  );
};

export default SecondaryNavigation;
