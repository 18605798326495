import { useEffect } from "react";

export default function useClickOutside<E extends HTMLElement>(
  ref: React.RefObject<E>,
  onClickOutside: () => void
) {
  useEffect(() => {
    const mountTime = Date.now();
    const handleClickOutside = (e: MouseEvent) => {
      const diff = Date.now() - mountTime;
      const target = e.target as HTMLElement;
      if (ref.current && !ref.current.contains(target)) {
        // Prevent immediate close (i.e. double click)
        if (diff < 300) {
          return;
        }
        onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [onClickOutside, ref]);
}
