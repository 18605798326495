import { css } from "styled-components";
import colors from "../../tokens/colors";

export const inputVariables = css`
  --input-text-color: var(--default-text-color, inherit);
  --input-border-color: ${colors.secondary.lilacCloud};
  --input-border-color-hover: ${colors.primary.cardanoBlue};
  --input-placeholder-color: ${colors.secondary.ashGrey};
  --input-placeholder-color-2: ${colors.secondary.lilacCloud};

  --input-error-color: ${colors.feedback.errorRed};
  --input-error-border-color: ${colors.feedback.errorRed};

  --input-focus-color: var(--default-focus-color);

  --input-background-color: ${colors.primary.white};
  --input-disabled-background-color: ${colors.secondary.mondayMist};
`;
