import { Svg, SvgProps } from "./Svg";

export function ArticlesSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={15}
      height={15}
      viewBox="1 1 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 3v4a1 1 0 001 1h4" />
        <path d="M18 17h-7a2 2 0 01-2-2V5a2 2 0 012-2h4l5 5v7a2 2 0 01-2 2z" />
        <path d="M16 17v2a2 2 0 01-2 2H7a2 2 0 01-2-2V9a2 2 0 012-2h2" />
      </g>
    </Svg>
  );
}

function Articles(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 3v4a1 1 0 001 1h4" />
        <path d="M18 17h-7a2 2 0 01-2-2V5a2 2 0 012-2h4l5 5v7a2 2 0 01-2 2z" />
        <path d="M16 17v2a2 2 0 01-2 2H7a2 2 0 01-2-2V9a2 2 0 012-2h2" />
      </g>
    </Svg>
  );
}

export default Articles;
