"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pxToRem = void 0;
const defaultPxToRemOptions = {
    rootFontSize: 16,
    separator: " "
};
function pxToRem(maybeOptions, ...px) {
    var _a, _b;
    const opts = typeof maybeOptions === "object" ? maybeOptions : defaultPxToRemOptions;
    const rootFontSize = (_a = opts.rootFontSize) !== null && _a !== void 0 ? _a : defaultPxToRemOptions.rootFontSize;
    const separator = (_b = opts.separator) !== null && _b !== void 0 ? _b : defaultPxToRemOptions.separator;
    if (typeof maybeOptions === "number") {
        px.unshift(maybeOptions);
    }
    return px.map((val) => `${val / rootFontSize}rem`).join(separator);
}
exports.pxToRem = pxToRem;
