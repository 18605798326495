import { FC } from "react";
import styled from "styled-components";
import { useRoadmapFilters } from "../../hooks/useRoadmapFilters";
import { useSharedState } from "../../hooks/useSharedState";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletLandscapeUp } from "../../tokens/media-queries";
import Button from "../buttons/Button";
import FiltersIcon from "../icons/Filters2";

const FiltersButton = styled(Button)`
  background: transparent;
  border-color: var(--theme-color-primary);
  color: var(--theme-color-primary);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;

  min-width: initial !important;
  min-height: ${pxToRem(50)};
  height: auto !important;

  &:hover {
    background: transparent;
    border-color: initial;
  }

  & > svg {
    margin-right: var(--spacing-default) !important;
    height: ${pxToRem(14)};
  }
`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;

  @media ${forTabletLandscapeUp} {
    justify-content: right;
    margin-right: var(--spacing-small);
  }
`;

const RoadmapFiltersButton: FC = () => {
  const [_, setOpen] = useSharedState("roadmapFiltersPanelOpen", false);
  const { themeFilters } = useRoadmapFilters();

  const numFilters = themeFilters.length;

  return (
    <Wrapper>
      <FiltersButton
        appearance="inverted"
        size="default"
        onClick={() => setOpen(true)}
      >
        <FiltersIcon height={12} /> Filters
        {numFilters > 0 ? ` (${numFilters})` : null}
      </FiltersButton>
    </Wrapper>
  );
};

export default RoadmapFiltersButton;
