import create from "zustand";

const useFlowMapStore = create((set) => ({
  flowMap: null,
  setFlowMap: (value) => set((state) => ({ flowMap: value })),
  flowMapDensity: null,
  setFlowMapDensity: (value) => set((state) => ({ flowMapDensity: value }))
}));

export { useFlowMapStore };
