import React, { useState } from "react";

export const RoadmapContext = React.createContext({
  roadmapPresentationMode: true,
  setRoadmapPresentationMode: () => {}
});

export const RoadmapContextProvider = (props) => {
  const [roadmapPresentationMode, setRoadmapPresentationMode] = useState(true);

  return (
    <RoadmapContext.Provider
      value={{ roadmapPresentationMode, setRoadmapPresentationMode }}
    >
      {props.children}
    </RoadmapContext.Provider>
  );
};
