import { Svg, SvgProps } from "./Svg";

function Instagram({
  strokeWidth = 2,
  ...props
}: SvgProps & { strokeWidth?: number }) {
  return (
    <Svg
      data-icon
      data-icon-instagram
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M16 4H8a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4V8a4 4 0 00-4-4z" />
        <path d="M12 15a3 3 0 100-6 3 3 0 000 6zM16.5 7.5v.001" />
      </g>
    </Svg>
  );
}

export default Instagram;
