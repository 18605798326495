import * as React from "react";
import { Svg, SvgProps } from "./Svg";

function LinkedIn({
  strokeWidth = 2,
  ...props
}: SvgProps & { strokeWidth?: number }) {
  return (
    <Svg
      data-icon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        strokeWidth={strokeWidth}
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M18 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2zM8 11v5M8 8v.01M12 16v-5" />
        <path d="M16 16v-3a2 2 0 00-4 0" />
      </g>
    </Svg>
  );
}

export default LinkedIn;
