import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

let cachedScrollPosition: [number, number];
let layers = 0;
const FIXED_BODY_STYLE = [
  // "position:fixed",
  "overflow:hidden"
  // "width:100%",
  // "min-height:100%"
];

type ScrollElement = "html" | "body";

export default function useLockDocumentScroll(
  shouldLock: boolean,
  scrollElement: ScrollElement = "body"
) {
  useIsomorphicLayoutEffect(() => {
    const element =
      scrollElement === "html" ? document.documentElement : document.body;

    if (shouldLock) {
      if (layers === 0) {
        let style = FIXED_BODY_STYLE.slice();
        const x = window.pageXOffset;
        const y = window.pageYOffset;
        cachedScrollPosition = [x, y];
        style.push(`top:${y * -1}px`);
        style.push(`left:${x * -1}px`);
        element.setAttribute("style", style.join(";"));
      }
      layers++;
    }
    return () => {
      if (shouldLock) {
        layers--;
        if (layers === 0) {
          element.setAttribute("style", "");
          if (Array.isArray(cachedScrollPosition)) {
            window.scrollTo(...cachedScrollPosition);
          }
        }
      }
    };
  }, [shouldLock]);
}
