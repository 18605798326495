import Link from "next/link";
import { useRouter } from "next/router";
import { default as React, useCallback, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import useClickOutside from "../../../hooks/useClickOutside";
import { useEscKey } from "../../../hooks/useEscKey";
import useLockDocumentScroll from "../../../hooks/useLockDocumentScroll";
import { usePortal } from "../../../hooks/usePortal";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../../tokens/colors";
import {
  forTabletLandscapeUp,
  forTabletPortraitDown
} from "../../../tokens/media-queries";
import Articles from "../../icons/Articles";
import IconClose from "../../icons/Close";
import Code from "../../icons/Code";
// import Mic from "../../icons/Mic";
import Video from "../../icons/Video";
import HowToContributeButton from "./HowToContributeButton";
import Text from "../../text/Text";
import CloseButton from "../../CloseButton";
import Overlay from "../../Overlay";
import Glossary from "../../icons/Glossary";
import FAQ from "../../icons/FAQ";
import ChevronRight from "../../icons/ChevronRight";
import Other from "../../icons/Other";
import { useUser } from "@auth0/nextjs-auth0";
import { isBlessedUser } from "../../../utils/user-role";
import zIndex from "../../../tokens/z-index";
import { Infographics } from "../../icons/Infographics";

const appear = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 0) translateY(100%);
  }
`;

const Vars = styled.div`
  --contribute-popup-columns: 2;
  --contribute-popup-width: ${pxToRem(264)};

  @media ${forTabletPortraitDown} {
    --contribute-popup-width: auto;
    --alert-overlay-background-color: ${colors.secondary.midnightBlue};
    --alert-overlay-opacity: 0.75;
  }
`;

const ContributeOverlay = styled(Overlay)`
  z-index: ${zIndex.contribute - 1};
`;

const Content = styled.div`
  --contribute-button-bottom-offset: ${pxToRem(50)};
  --contribute-button-height: var(--button-huge-responsive-height);
  --popup-padding: calc(var(--spacing-large) + var(--spacing-small));
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--popup-padding);
  width: var(---contribute-popup-width);

  background-color: var(--default-bg-color);
  position: fixed;
  border-radius: ${pxToRem(10)};
  animation: ${appear} 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: ${zIndex.contribute};
  @media ${forTabletLandscapeUp} {
    right: 3rem;
    bottom: calc(
      var(--contribute-button-bottom-offset) + var(--contribute-button-height) +
        var(--spacing-large)
    );
    margin-bottom: var(--spacing-default);

    :after {
      position: absolute;
      content: "";
      width: ${pxToRem(50)};
      height: ${pxToRem(50)};
      background-color: var(--default-bg-color);
      border-radius: 0 0 0 ${pxToRem(10)};
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg) skew(15deg, 15deg);
    }
  }

  @media ${forTabletPortraitDown} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  [data-heading] {
    align-self: flex-start;
  }
`;

const ContributionContainerSeparator = styled.hr`
  width: 100%;
  border: 0;
  margin: 0;
  border-bottom: var(--card-stroke);
  margin-bottom: var(--popup-padding);
`;

const ContributionTypesContainer = styled.div`
  justify-items: stretch;
  display: grid;
  grid-template-columns: repeat(var(--contribute-popup-columns), 1fr);
  padding: var(--popup-padding) 0;
  gap: var(--popup-padding);
`;

const LearnHowToContributeButton = styled(HowToContributeButton)`
  border: 0;
  font-size: 1rem !important;
  padding-top: 0;
  padding-bottom: 0;

  vertical-align: middle;

  & > [data-icon] {
    display: inline-block;
    vertical-align: middle;
    margin-left: var(--spacing-default);
  }

  &:hover {
    /* background-color: var(--theme-color-primary); */
    /* color: var(--default-text-color-invert); */
  }
`;

export const ContributionTypeIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--default-text-color-invert);
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  background-color: var(--theme-color-primary);
  margin-bottom: var(--spacing-small);
`;

const ContributionType = styled.a<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: ${pxToRem(12)};
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  &:hover ${ContributionTypeIcon} {
    background-color: ${colors.primary.cardanoBlue};
  }
`;

const ContributionTypeItem: React.FC<
  React.ComponentProps<typeof ContributionType> & {
    href: string;
    isIndex?: boolean;
  }
> = ({ href, ...props }) => {
  return (
    <Link href={href} passHref>
      <ContributionType {...props} />
    </Link>
  );
};

type Props = {
  isOpen: boolean;
  onRequestClose(): void;
  className?: string;
  closeButton?: boolean;
  sourceEl?: HTMLElement | null;
};

const ContributePopUp: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  className,
  closeButton = true,
  sourceEl
}) => {
  const { user } = useUser();
  const isBlessed = isBlessedUser(user);
  const ref = useRef<HTMLDivElement>(null);
  const closeIfOpen = useCallback(() => {
    if (isOpen) {
      onRequestClose();
    }
  }, [isOpen, onRequestClose]);
  useLockDocumentScroll(isOpen);
  useClickOutside(ref, closeIfOpen);
  useEscKey(closeIfOpen);
  const portal = usePortal();
  const { asPath } = useRouter();

  const rect = sourceEl?.getBoundingClientRect();
  const getBottomOffset = () => {
    if (typeof window !== "undefined" && rect) {
      return window.innerHeight - rect.bottom;
    }
    return 50;
  };
  const contributeBottomOffset = getBottomOffset();

  useEffect(() => {
    if (isOpen) {
      closeIfOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  return isOpen
    ? portal(
        <Vars>
          <ContributeOverlay />
          <Content
            style={
              {
                "--contribute-button-bottom-offset":
                  contributeBottomOffset + "px"
              } as any
            }
            className={className}
            ref={ref}
            tabIndex={0}
            role="dialog"
            aria-modal="true"
          >
            {closeButton && (
              <>
                <Text data-heading align="left" level="1">
                  Select content to upload...
                </Text>
                <CloseButton onClick={onRequestClose} aria-label="Close dialog">
                  <IconClose width="20" height="20" />
                </CloseButton>
                <ContributionTypesContainer>
                  <ContributionTypeItem href={"/contribute?category=article"}>
                    <ContributionTypeIcon>
                      <Articles />
                    </ContributionTypeIcon>
                    Article
                  </ContributionTypeItem>
                  <ContributionTypeItem href={"/contribute?category=video"}>
                    <ContributionTypeIcon>
                      <Video />
                    </ContributionTypeIcon>
                    Videos
                  </ContributionTypeItem>
                  <ContributionTypeItem href={"/contribute?category=faq"}>
                    <ContributionTypeIcon>
                      <FAQ />
                    </ContributionTypeIcon>
                    FAQs
                  </ContributionTypeItem>
                  <ContributionTypeItem
                    href={"/contribute?category=infographic"}
                  >
                    <ContributionTypeIcon>
                      <Infographics />
                    </ContributionTypeIcon>
                    Infographics
                  </ContributionTypeItem>
                  {/* <ContributionTypeItem href={"/contribute?category=podcast"}>
                    <ContributionTypeIcon>
                      <Mic />
                    </ContributionTypeIcon>
                    Podcasts
                  </ContributionTypeItem> */}
                  <ContributionTypeItem href={"/contribute?category=developer"}>
                    <ContributionTypeIcon>
                      <Code />
                    </ContributionTypeIcon>
                    Developer Resources
                  </ContributionTypeItem>
                  <ContributionTypeItem href={"/contribute?category=glossary"}>
                    <ContributionTypeIcon>
                      <Glossary />
                    </ContributionTypeIcon>
                    Glossary
                  </ContributionTypeItem>
                  <ContributionTypeItem href={"/contribute?category=other"}>
                    <ContributionTypeIcon>
                      <Other />
                    </ContributionTypeIcon>
                    Other
                  </ContributionTypeItem>
                  {isBlessed && (
                    <ContributionTypeItem
                      href={"/contribute?category=development-update"}
                    >
                      <ContributionTypeIcon>
                        <Articles />
                      </ContributionTypeIcon>
                      Dev Update
                    </ContributionTypeItem>
                  )}
                </ContributionTypesContainer>
                <ContributionContainerSeparator />
                <LearnHowToContributeButton
                  size="large"
                  appearance={"secondary"}
                >
                  Learn how to contribute <ChevronRight />
                </LearnHowToContributeButton>
              </>
            )}
          </Content>
        </Vars>
      )
    : null;
};

export default ContributePopUp;
