import { RefObject, useEffect } from "react";

export default function useAutofocus<
  E extends HTMLInputElement | HTMLTextAreaElement
>(ref: RefObject<E> | ((instance: E | null) => void), enable = true) {
  useEffect(() => {
    if (enable && "current" in ref && ref.current) {
      const value = ref.current.value || "";
      ref.current.focus();
      ref.current.setSelectionRange(value.length, value.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
