import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const tagVariables = css`
  --tag-background-color: ${colors.secondary.londonFog};
  --tag-background-color-hover: ${colors.secondary.lilacCloud};
  --tag-text-color: var(--default-text-color, inherit);
  --tag-font-size: ${typography.size.tiny};

  --tag-close-color: ${colors.secondary.ashGrey};
  --tag-close-color-hover: ${colors.primary.cardanoBlue};
`;
