import { pxToRem } from "@input-output-hk/px-to-rem";
import { gsap } from "gsap";
import React, { createRef, useEffect, useRef } from "react";
import styled from "styled-components";
import { useYearStore } from "../state/year";

export default function YearList({ hideText }) {
  // store
  const displayYear = useYearStore((state) => state.displayYear);
  const setYear = useYearStore((state) => state.setYear);
  const yearsAvailable = useYearStore((state) => state.yearsAvailable);

  // refs
  const yearPositions = useRef();
  if (yearsAvailable) {
    yearPositions.current = yearsAvailable.map(() => createRef());
  }
  const yearContainerRef = useRef();

  const animParams = useRef({
    right: "-200px",
    opacity: 0
  });

  const tl = useRef(
    gsap.timeline({
      onUpdate: () => {
        if (yearContainerRef.current) {
          yearContainerRef.current.style.right = animParams.current.right;
          yearContainerRef.current.style.opacity = animParams.current.opacity;
        }
      }
    })
  );

  useEffect(() => {
    if (hideText === true) {
      tl.current.to(
        animParams.current,
        {
          duration: 1,
          right: "0",
          opacity: 1
        },
        0
      );
    }
  }, [hideText]);

  function cleanYear(year) {
    return year.replace("+", "");
  }

  function YearItems() {
    if (yearsAvailable === null) {
      return <></>;
    }

    return yearsAvailable.map((year, i) => {
      const cleanedYear = cleanYear(year);

      return (
        <YearItem
          onClick={() => {
            setYear(cleanedYear);
          }}
          style={{
            fontWeight: cleanedYear === displayYear ? "bold" : "100",
            fontSize: cleanedYear === displayYear ? pxToRem(18) : pxToRem(16)
          }}
          key={year.toString()}
          ref={yearPositions.current[i]}
        >
          {year}
        </YearItem>
      );
    });
  }

  return (
    <YearContainer ref={yearContainerRef}>
      <YearInner>
        <YearItems />
      </YearInner>
    </YearContainer>
  );
}

const YearContainer = styled.div`
  list-style: none;
  position: fixed;
  top: 0;
  right: ${pxToRem(-200)};
  padding: 0 3vw 0 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 2;
  text-align: right;
`;

const YearInner = styled.div`
  position: relative;
`;

const YearItem = styled.div`
  color: #ffffff;
  line-height: ${pxToRem(45)};
  font-weight: 100;

  &:hover {
    cursor: pointer;
    font-weight: 400 !important;
  }
`;
