import { createContext, useContext } from "react";

export type I18nContextType = {
  locale: string | undefined;
  cache: {
    [key: string]: any;
  };
};

export const I18nContext = createContext<I18nContextType>({
  locale: undefined,
  cache: {}
});

export const useI18nContext = () => useContext(I18nContext);
