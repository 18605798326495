// 3rd party
import React, {
  forwardRef,
  useEffect,
  useState,
  Suspense,
  useRef
} from "react";
import { Circle, Line, Plane, Text } from "@react-three/drei";
import { gsap } from "gsap";
import { isMobile } from "react-device-detect";
import { useFrame } from "@react-three/fiber";
import { A11y } from "@react-three/a11y";

const milestoneSpriteIndices = {
  Byron: 3,
  Shelley: 4,
  Goguen: 2,
  Voltaire: 0,
  Basho: 1
};

const RoadMapItem = forwardRef((props, itemContainerRef) => {
  const [isHovered, setIsHovered] = useState(false);
  const [descriptionFontSize, setDescriptionFontSize] = useState(0);
  const [categoryFontSize, setCategoryFontSize] = useState(0);

  const isMilestone = useRef(false);
  const circleXOffsetR = useRef(-40);
  const circleXOffsetL = useRef(29);
  const milestoneMaterial = useRef();

  // constructor
  useEffect(() => {
    if (props.item.type === "phase") {
      isMilestone.current = true;

      milestoneMaterial.current = props.milestonePlaneMaterialRef.clone();

      milestoneMaterial.current.uniforms.uTexture.value = props.iconSprite;

      const spriteIndex = milestoneSpriteIndices[props.item.label];
      if (typeof spriteIndex !== "undefined") {
        milestoneMaterial.current.uniforms.uSpriteIndex.value = spriteIndex;
      }

      circleXOffsetL.current = 32;
      circleXOffsetR.current = -43;
    }

    if (isMobile) {
      setCategoryFontSize(Math.max(15, 15 - props.item.pos[2] * 0.01));
      setDescriptionFontSize(Math.max(18, 18 - props.item.pos[2] * 0.01));
    } else {
      setCategoryFontSize(Math.max(4, 4 - props.item.pos[2] * 0.01));
      setDescriptionFontSize(Math.max(5, 5 - props.item.pos[2] * 0.01));
    }
  }, []);

  const hoverParams = useRef({
    dotRotationX: 0,
    groupXOffset: props.orientation === "right" ? 1.5 : 12
  });
  const dotRef = useRef();
  const containerGroupRef = useRef();

  const tl = useRef(
    gsap.timeline({
      onUpdate: () => {
        if (dotRef.current) {
          dotRef.current.rotation.x = hoverParams.current.dotRotationX;
        }
        if (containerGroupRef.current) {
          containerGroupRef.current.position.x =
            hoverParams.current.groupXOffset;
        }
      }
    })
  );

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  useEffect(() => {
    document.body.style.cursor = isHovered ? "pointer" : "default";

    if (dotRef.current) {
      if (isHovered) {
        tl.current.clear();
        tl.current.to(hoverParams.current, {
          dotRotationX: Math.PI,
          groupXOffset: props.item.orientation === "right" ? -3 : 15
        });
      } else {
        tl.current.clear();
        tl.current.to(hoverParams.current, {
          dotRotationX: 0,
          groupXOffset: props.item.orientation === "right" ? -1.5 : 12
        });
      }
    }
  }, [isHovered]);

  useFrame((state) => {
    if (milestoneMaterial.current) {
      milestoneMaterial.current.uniforms.uTime.value = state.clock.elapsedTime;
    }
  });

  return (
    <>
      {props.item.pos && (
        <A11y
          role={props?.disabled ? "none" : "button"}
          description={props.item.label}
          activationMsg={props.item.label}
          actionCall={() => props.onClickItem?.(props.item)}
          tabIndex={0}
          showAltText
          disabled={props?.disabled}

        >
          <>
            <group
              ref={itemContainerRef}
              onPointerOver={handleMouseEnter}
              onPointerLeave={handleMouseLeave}
            >
              {props.item.orientation === "right" && (
                <group
                  ref={containerGroupRef}
                  position={[
                    hoverParams.current.groupXOffset,
                    isMobile ? -10 : -3.5,
                    0
                  ]}
                >
                  <Plane
                    scale={isMobile ? [4, 4, 4] : [1, 1, 1]}
                    ref={dotRef}
                    position={[
                      props.item.pos[0] + circleXOffsetR.current,
                      props.item.pos[1],
                      props.item.pos[2]
                    ]}
                    args={isMilestone.current ? [24, 24] : [14, 14]}
                    material={
                      isMilestone.current
                        ? milestoneMaterial.current
                        : props.planeMaterialRef
                    }
                  />
                  <Line
                    material={props.lineMaterialRef}
                    position={[
                      props.item.pos[0] - 5.5,
                      props.item.pos[1],
                      props.item.pos[2]
                    ]}
                    points={
                      isMobile
                        ? [
                            [-18, 0, 0],
                            [0, 0, 0]
                          ]
                        : [
                            [-30, 0, 0],
                            [0, 0, 0],
                            [3, 3, 0]
                          ]
                    }
                    color={0xffffff}
                    lineWidth={0.6}
                  />
                  <Circle
                    material={props.lineMaterialRef}
                    position={[
                      props.item.pos[0] - (isMobile ? 4 : 2),
                      props.item.pos[1] + (isMobile ? 0 : 3.5),
                      props.item.pos[2]
                    ]}
                    args={isMobile ? [2.5, 12] : [0.8, 12]}
                  />
                </group>
              )}

              {props.item.orientation === "left" && (
                <group
                  ref={containerGroupRef}
                  position={[
                    hoverParams.current.groupXOffset,
                    isMobile ? -10 : -3.5,
                    0
                  ]}
                >
                  <Plane
                    ref={dotRef}
                    scale={isMobile ? [4, 4, 4] : [1, 1, 1]}
                    position={[
                      props.item.pos[0] + circleXOffsetL.current,
                      props.item.pos[1],
                      props.item.pos[2]
                    ]}
                    args={isMilestone.current ? [24, 24] : [14, 14]}
                    material={
                      isMilestone.current
                        ? milestoneMaterial.current
                        : props.planeMaterialRef
                    }
                  />
                  <Line
                    material={props.lineMaterialRef}
                    position={[
                      props.item.pos[0] - 5.5,
                      props.item.pos[1],
                      props.item.pos[2]
                    ]}
                    points={
                      isMobile
                        ? [
                            [18, 0, 0],
                            [0, 0, 0]
                          ]
                        : [
                            [30, 0, 0],
                            [0, 0, 0],
                            [-3, 3, 0]
                          ]
                    }
                    color={0xffffff}
                    lineWidth={0.6}
                  />
                  <Circle
                    material={props.lineMaterialRef}
                    position={[
                      props.item.pos[0] - (isMobile ? 4 : 8.8),
                      props.item.pos[1] + (isMobile ? 0 : 3.5),
                      props.item.pos[2]
                    ]}
                    args={isMobile ? [2.5, 12] : [0.8, 12]}
                  />
                </group>
              )}
              <Suspense fallback={null}>
                <Text
                  material={props.textMaterialRef}
                  position={[
                    props.item.pos[0],
                    props.item.pos[1] + (isMobile ? 25 : 5),
                    props.item.pos[2]
                  ]}
                  font="/chivo-v12-latin-700.woff"
                  textAlign={
                    props.item.orientation === "right" ? "left" : "right"
                  }
                  anchorX={
                    props.item.orientation === "right" ? "left" : "right"
                  }
                  anchorY="top"
                  fontSize={categoryFontSize}
                  color={0xc655fb}
                  maxWidth={isMobile ? 130 : 55}
                >
                  {props.item.theme}
                </Text>
                <Text
                  maxWidth={isMobile ? 130 : 55}
                  material={props.textMaterialRef}
                  position={props.item.pos}
                  font="/chivo-v12-latin-700.woff"
                  textAlign={
                    props.item.orientation === "right" ? "left" : "right"
                  }
                  anchorX={
                    props.item.orientation === "right" ? "left" : "right"
                  }
                  anchorY="top"
                  fontSize={descriptionFontSize}
                  color={0xffffff}
                >
                  {isMobile && props.item.label.length > 30
                    ? props.item.label.substring(0, 30) + "..."
                    : props.item.label}
                </Text>
              </Suspense>
            </group>
          </>
        </A11y>
      )}
    </>
  );
});

export default RoadMapItem;
