import { extend, useFrame, useThree } from "@react-three/fiber";
import React, { useEffect, useRef, useState } from "react";
import { AdditiveBlending, BufferAttribute } from "three";
import { ScreenQuad, shaderMaterial } from "@react-three/drei";
import { isMobile } from "react-device-detect";

// shaders
import emptyVert from "./shaders/emptyVert";
import screenFrag from "./shaders/screenFrag";

// state
import { useFlowMapStore } from "./state/flowMap";

const FlowMapVizualiser = () => {
  const { size } = useThree();

  // refs
  const quadRef = useRef();
  const downScale = useRef(0.5);

  // context
  const flowMapDensity = useFlowMapStore((state) => state.flowMapDensity);

  /*
    =========================================================
    Create Materials
    =========================================================
    */
  const ScreenMaterial = shaderMaterial(
    {
      uTexture: null
    },
    emptyVert,
    screenFrag
  );
  extend({ ScreenMaterial });
  const screenMatRef = useRef();

  // constructor
  useEffect(() => {
    // add uvs to ScreenQuad
    const geometry = quadRef.current.geometry;
    const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);
    geometry.setAttribute("uv", new BufferAttribute(uvs, 2));
  }, []);

  // state
  const [pixelSize, setPixelSize] = useState([
    Math.floor(size.width),
    Math.floor(size.height)
  ]);
  const [aspectRatio, setAspectRatio] = useState(0.0);

  useEffect(() => {
    // if (!isMobile) {
    setPixelSize([
      Math.floor(size.width * downScale.current),
      Math.floor(size.height * downScale.current)
    ]);
    // }
  }, [size]);

  useEffect(() => {
    setAspectRatio(pixelSize[0] / pixelSize[1]);
  }, [pixelSize]);

  useFrame((state, dt) => {
    screenMatRef.current.uniforms.uTexture.value = flowMapDensity;
  });

  return (
    <>
      <ScreenQuad ref={quadRef} material={screenMatRef.current} />
      {/* Materials */}
      <screenMaterial
        ref={screenMatRef}
        depthWrite={false}
        depthTest={false}
        blending={AdditiveBlending}
        attach="material"
        uResolution={[pixelSize[0], pixelSize[1]]}
        uAspect={aspectRatio}
      />
    </>
  );
};

export default FlowMapVizualiser;
