import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";

type tProps = {
  /**
   * Allows disabled to work with any
   * "as" (not just button)
   */
  disabled?: boolean;
  appearance?: "primary" | "secondary" | "link" | "inverted" | "banner";
  size?: "huge" | "huge-responsive" | "large" | "default" | "medium" | "small";
};

const Button = styled.button.attrs<tProps>(
  ({ size = "default", appearance = "primary" }) => ({
    "data-button": true,
    "data-size": size,
    "data-appearance": appearance
  })
)<tProps>`
  border-radius: 15rem;
  cursor: pointer;
  touch-action: manipulation;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.6875rem 1.5rem;
  white-space: nowrap;

  text-align: center;
  text-decoration: none;
  font-family: var(--default-text-font-family);
  font-weight: 600;
  border: ${pxToRem(2)} solid currentColor;

  &[data-appearance="primary"] {
    background: var(--button-primary-bg-color);
    color: var(--button-primary-text);
    border-color: var(--button-primary-border-color);
    &:hover {
      color: var(--button-primary-text-hover);
      background: var(--button-primary-bg-color-hover);
      border-color: var(--button-primary-border-color-hover);
    }
  }

  &[data-appearance="banner"] {
    background: var(--button-banner-bg-color);
    color: var(--button-primary-text);
    border-color: var(--button-banner-border-color);
    &:hover {
      color: var(--button-primary-text-hover);
      background: var(--button-banner-bg-color-hover);
      border-color: var(--button-banner-border-color);
    }
  }

  &[data-size="default"] {
    height: ${pxToRem(46)};
    min-width: var(--button-default-width);
    font-size: var(--button-default-font-size);
  }
  &[data-size="huge"] {
    min-width: var(--button-huge-width);
    height: var(--button-huge-height);
    font-size: var(--button-huge-font-size);
  }
  &[data-size="large"] {
    min-width: var(--button-large-width);
    font-size: var(--button-large-font-size);
  }
  &[data-size="small"] {
    height: ${pxToRem(35)};
    min-width: var(--button-small-width);
    font-size: var(--button-small-font-size);
    border-width: ${pxToRem(1)};
  }

  &[data-size="medium"] {
    height: ${pxToRem(50)};
    min-width: var(--button-medium-width);
    font-size: var(--button-default-font-size);
  }

  &[data-size="huge-responsive"] {
    /* Large on mobile  */
    min-width: var(--button-huge-responsive-width);
    height: var(--button-huge-responsive-height);
    font-size: var(--button-huge-responsive-font-size);
  }

  & > [data-icon]:first-child {
    margin-right: var(--spacing-default);
  }
  & > [data-icon]:last-child {
    margin-left: var(--spacing-small);
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  &[data-appearance="secondary"] {
    background: var(--button-secondary-bg-color);
    color: var(--button-secondary-text);
    border-color: var(--button-secondary-border-color);
    &:hover {
      color: var(--button-secondary-text-hover);
      background: var(--button-secondary-bg-color-hover);
      border-color: var(--button-secondary-border-color-hover);
    }
  }

  &[data-appearance="link"] {
    background: var(--button-link-bg-color);
    color: var(--button-link-text);
    border: none;
    &:hover {
      color: var(--button-link-text-hover);
      background: var(--button-link-bg-color-hover);
    }
  }

  &[data-appearance="inverted"] {
    background: transparent;
    color: #fff;
    border-color: currentColor;
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;

Button.defaultProps = {
  appearance: "primary"
};

export default Button;
