import { Svg, SvgProps } from "./Svg";

export function OtherSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={15}
      height={15}
      viewBox="0 0 19.5 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="prefix__a" fill="currentColor">
        <rect width={8.696} height={8.696} rx={1} />
      </mask>
      <rect
        width={8.696}
        height={8.696}
        rx={1}
        stroke="currentColor"
        strokeWidth={3}
        mask="url(#prefix__a)"
      />
      <mask id="prefix__b" fill="currentColor">
        <rect x={11.305} width={8.696} height={8.696} rx={1} />
      </mask>
      <rect
        x={11.305}
        width={8.696}
        height={8.696}
        rx={1}
        stroke="currentColor"
        strokeWidth={3}
        mask="url(#prefix__b)"
      />
      <mask id="prefix__c" fill="currentColor">
        <rect x={11.305} y={11.304} width={8.696} height={8.696} rx={1} />
      </mask>
      <rect
        x={11.305}
        y={11.304}
        width={8.696}
        height={8.696}
        rx={1}
        stroke="currentColor"
        strokeWidth={3}
        mask="url(#prefix__c)"
      />
      <mask id="prefix__d" fill="currentColor">
        <rect y={11.304} width={8.696} height={8.696} rx={1} />
      </mask>
      <rect
        y={11.304}
        width={8.696}
        height={8.696}
        rx={1}
        stroke="currentColor"
        strokeWidth={3}
        mask="url(#prefix__d)"
      />
    </Svg>
  );
}

const Other = (props: SvgProps) => (
  <Svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon
    {...props}
  >
    <mask id="prefix__a" fill="currentColor">
      <rect width={8.696} height={8.696} rx={1} />
    </mask>
    <rect
      width={8.696}
      height={8.696}
      rx={1}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#prefix__a)"
    />
    <mask id="prefix__b" fill="currentColor">
      <rect x={11.305} width={8.696} height={8.696} rx={1} />
    </mask>
    <rect
      x={11.305}
      width={8.696}
      height={8.696}
      rx={1}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#prefix__b)"
    />
    <mask id="prefix__c" fill="currentColor">
      <rect x={11.305} y={11.304} width={8.696} height={8.696} rx={1} />
    </mask>
    <rect
      x={11.305}
      y={11.304}
      width={8.696}
      height={8.696}
      rx={1}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#prefix__c)"
    />
    <mask id="prefix__d" fill="currentColor">
      <rect y={11.304} width={8.696} height={8.696} rx={1} />
    </mask>
    <rect
      y={11.304}
      width={8.696}
      height={8.696}
      rx={1}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#prefix__d)"
    />
  </Svg>
);

export default Other;
