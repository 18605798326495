import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const textVariables = css`
  --text-color-muted: ${colors.secondary.ashGrey};

  --text-level1-size: ${typography.size.default};
  --text-level2-size: ${typography.size.small};
  --text-level3-size: ${typography.size.tiny};

  --text-level1-lineHeight: ${typography.lineHeight.default};
  --text-level2-lineHeight: ${typography.lineHeight.small};
  --text-level3-lineHeight: ${typography.lineHeight.tiny};
`;
