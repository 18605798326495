import { Svg, SvgProps } from "./Svg";

function MenuIcon(props: SvgProps) {
  return (
    <Svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path fill="currentColor" d="M5 22h22v-2H5zM5 16h12v-2H5zM5 10h18V8H5z" />
    </Svg>
  );
}

export default MenuIcon;
