import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export function usePortal(debugName = "") {
  const elRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!elRef.current) {
      const el = document.createElement("div");
      el.setAttribute("data-ec-portal", debugName);
      document.body.appendChild(el);
      elRef.current = el;
    }
    return () => {
      elRef.current?.parentNode?.removeChild(elRef.current);
    };
  }, [debugName]);

  function portal(children: ReactNode) {
    return elRef.current ? createPortal(children, elRef.current) : null;
  }
  return portal;
}
