import Link from "next/link";
import React, { useState } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import ArrowUp from "../icons/ArrowUp";
import { TagWithOutClose } from "../tag/Tag";

const TagsElement = styled.div<{
  showingAll: boolean;
}>`
  ${(props) => props.showingAll === false && "white-space: nowrap;"}
  margin: ${pxToRem(-3)};
  & > * {
    margin: ${pxToRem(3)};
  }
`;

const CollapseTagsButton = styled.button`
  float: right;
  margin-top: ${pxToRem(5)};
  border: 0;
  background: transparent;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;

const AdditionalTags = styled(TagWithOutClose)`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  tags: string[];
  enableCollapsing?: boolean;
};

export function Tags({
  tags,
  enableCollapsing = false,
  ...props
}: Props & React.ComponentPropsWithoutRef<typeof TagsElement>) {
  const [_showingAll, setShowingAll] = useState(false);
  const showingAll = enableCollapsing ? _showingAll : true;

  const multiLineTagsBreakpoint = 2;
  const hasTagsOverBreakpoint = tags.length > multiLineTagsBreakpoint;

  return (
    <TagsElement data-tags {...props} showingAll={showingAll}>
      {hasTagsOverBreakpoint && !showingAll
        ? tags.slice(0, 2).map((t) => (
            <Link key={t} href={`/search?tags=${t}`} passHref>
              <TagWithOutClose>{t}</TagWithOutClose>
            </Link>
          ))
        : tags.map((t) => (
            <Link key={t} href={`/search?tags=${t}`} passHref>
              <TagWithOutClose>{t}</TagWithOutClose>
            </Link>
          ))}
      {hasTagsOverBreakpoint &&
        enableCollapsing &&
        (!showingAll ? (
          <AdditionalTags onClick={() => setShowingAll(true)}>
            + {tags.length - 2}
          </AdditionalTags>
        ) : (
          <CollapseTagsButton onClick={() => setShowingAll(false)}>
            <ArrowUp />
          </CollapseTagsButton>
        ))}
    </TagsElement>
  );
}
