import { createHmac, randomBytes } from "crypto";
import { parse, serialize, CookieSerializeOptions } from "cookie";
import { IncomingMessage, ServerResponse } from "http";

const COOKIE_SIGNING_KEY = process.env.COOKIE_SIGNING_KEY
  ? Buffer.from(process.env.COOKIE_SIGNING_KEY, "base64")
  : randomBytes(16);

// we'll truncate this so the facts 256bits won't be as painful
const HMAC_ALGORITHM = "sha256";

// HMAC can be truncated but no more than half its length
// for SHA-256 this'll mean 22 base64 characters which is tiny
// https://datatracker.ietf.org/doc/html/rfc2104#section-5
const HMAC_TRUNCATION_FACTOR = 0.5;

const COOKIE_ENCODING = "base64url";

export type SetCookieOptions = Omit<CookieSerializeOptions, "encode">;

export function addUnsignedCookie(
  res: ServerResponse,
  name: string,
  value: string,
  opts: SetCookieOptions
): void {
  const newCookie = serialize(name, value, opts);
  setCookieHeader(res, newCookie);
}

export function addCookie(
  res: ServerResponse,
  name: string,
  value: object,
  opts: SetCookieOptions
): void {
  const serializedValue = Buffer.from(JSON.stringify(value)).toString(
    COOKIE_ENCODING
  );
  const signature = generateSignature(serializedValue);

  const newCookie = serialize(name, `${serializedValue}.${signature}`, opts);
  setCookieHeader(res, newCookie);
}

export function getCookie<T = object>(
  req: IncomingMessage,
  cookieName: string
): T | null {
  try {
    const cookieHeader = req.headers.cookie;
    const cookies = cookieHeader ? parse(cookieHeader) : {};
    const cookieValue = cookies[cookieName];
    if (typeof cookieValue === "string") {
      const [value, signature] = cookieValue.split(".");
      const expectedSignature = generateSignature(value);

      if (expectedSignature !== signature) {
        return null;
      }

      return JSON.parse(Buffer.from(value, COOKIE_ENCODING).toString("utf-8"));
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}

export function deleteCookie(res: ServerResponse, cookieName: string) {
  const cookie = serialize(cookieName, "", {
    expires: new Date(0)
  });

  setCookieHeader(res, cookie);
}

function setCookieHeader(res: ServerResponse, cookie: string) {
  // for some reason this is string | number | string[]
  const existingCookies = res.getHeader("set-cookie");

  if (Array.isArray(existingCookies)) {
    res.setHeader("set-cookie", [...existingCookies, cookie]);
  } else if (typeof existingCookies === "string") {
    res.setHeader("set-cookie", [existingCookies, cookie]);
  } else {
    // if existingCookies is a `number`, then it's invalid and we overwrite it
    res.setHeader("set-cookie", cookie);
  }
}

function generateSignature(value: string): string {
  const fullSignature = createHmac(HMAC_ALGORITHM, COOKIE_SIGNING_KEY)
    .update(value)
    .digest(COOKIE_ENCODING);

  const truncatedHmac = fullSignature.slice(
    0,
    Math.ceil(fullSignature.length * HMAC_TRUNCATION_FACTOR)
  );

  return truncatedHmac;
}
