import { AriaRole } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { AvatarPlaceholder } from "./AvatarPlaceholder";
import { shadowFilter } from "../AvatarCustomiser/AvatarCustomiserCommonStyles";
import colors from "../../tokens/colors";

const levelSizes = {
  "000": 150,
  "00": 90,
  "0": 60,
  "1": 40,
  "2": 30,
  "3": 20
} as const;

export function getSize(level?: Level) {
  return level ? levelSizes[level] : levelSizes["3"];
}

const StyledAvatar = styled.div.attrs<Props>((props) => ({
  width: getSize(props.level),
  height: getSize(props.level)
}))<Props>`
  width: ${(props) => pxToRem(getSize(props.level))};
  height: ${(props) => pxToRem(getSize(props.level))};
  position: relative;
  overflow: hidden;
  border-radius: ${(props) => (props.rounded ? "50%" : 0)};

  ${shadowFilter}
`;

const AvatarImage = styled.img.attrs<Props>((props) => ({
  width: getSize(props.level),
  height: getSize(props.level)
}))<Props>`
  position: relative;
  width: ${(props) => pxToRem(getSize(props.level))};
  height: ${(props) => pxToRem(getSize(props.level))};
  background-color: ${colors.secondary.lilacCloud};
  background-image: ${(props) => `url("${props.src}")`};
  background-repeat: no-repeat;
`;

const Preloader = styled.div.attrs<Props>((props) => ({
  width: getSize(props.level),
  height: getSize(props.level)
}))<Props>`
  border-radius: ${(props) => (props.rounded ? "50%" : 0)};
  width: ${(props) => pxToRem(getSize(props.level))};
  height: ${(props) => pxToRem(getSize(props.level))};
  display: flex;
  justify-content: center;
  align-items: center;
`;

Preloader.defaultProps = {
  theme: {
    rounded: true
  }
};

export type Props = {
  level?: Level;
  rounded?: boolean;
  cover?: boolean;
  showPreloader?: boolean;
  alt?: string;
  src?: string | null;
  role?: AriaRole;
};
type Level = "000" | "00" | "0" | "1" | "2" | "3";

export const Avatar = ({
  level,
  rounded = true,
  cover = false,
  alt,
  src,
  role,
  ...props
}: Props) => {
  return (
    <StyledAvatar level={level} rounded={rounded}>
      {!src && <AvatarPlaceholder level={level} />}
      {src && (
        <AvatarImage
          {...props}
          src={src}
          level={level}
          rounded={rounded}
          cover={cover}
          alt={alt}
          role={role}
        />
      )}
    </StyledAvatar>
  );
};
