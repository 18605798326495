import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const popMenuButtonVariables = css`
  --menu-button-text-color: ${colors.primary.cardanoBlue};
  --menu-button-font-family: ${typography.font.default};
  --menu-button-font-size: ${typography.size.small};
  --menu-button-text-color-hover: var(--theme-color-primary);
  --menu-button-text-active-color: var(--theme-color-primary);

  --menu-list-background-color: ${colors.primary.white};
  --menu-list-border-color: ${colors.primary.white};

  --menu-link-text-color: ${colors.primary.cardanoBlue};
  --menu-link-text-color-hover: var(--theme-color-primary);
  --menu-link-background-color-hover: ${colors.secondary.londonFog};
`;
