import { pxToRem } from "@input-output-hk/px-to-rem";
import styled, { css } from "styled-components";

export const visuallyHiddenCss = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: ${pxToRem(1)};
  width: ${pxToRem(1)};
  margin: ${pxToRem(-1)};
  padding: 0;
  /* https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe */
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
  position: absolute;
`;

export const VisuallyHidden = styled.span`
  ${visuallyHiddenCss};
`;
