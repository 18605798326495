import type { SubmissionDoc } from "@input-output-hk/essential-cardano-models";
import type { Item } from "../data/db/types";
import { memo } from "@input-output-hk/essential-cardano-utils";
import type { IncomingMessage } from "http";
import { badRequest } from "@hapi/boom";

export const publicUrl = memo((path = "/"): string => {
  const baseUrl = new URL(
    typeof window !== "undefined"
      ? window.location.origin
      : process.env.NEXT_PUBLIC_PUBLIC_URL ??
        process.env.PUBLIC_URL ??
        `https://${process.env.VERCEL_URL}`
  );
  const absolutePath = path[0] === "/" ? path : "/" + path;

  // make sure route is absolute
  return new URL(absolutePath, baseUrl).href;
});

export const itemUrl = memo(
  ({ _category, _slug }: Pick<Item, "_category" | "_slug">) =>
    publicUrl(`/${_category}/${_slug}`)
);

export const submissionUrl = memo(({ _id }: Pick<SubmissionDoc, "_id">) =>
  publicUrl(`/profile/my-contributions/${_id}`)
);

// as per https://stackoverflow.com/a/43467144/1175555
export const isValidHttpUrl = (verifiedURL: string): boolean => {
  let url: URL;

  try {
    url = new URL(verifiedURL);
  } catch {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const parseUrl = (url: string) => {
  let output: URL;

  try {
    output = new URL(url);
  } catch {
    output = new URL(`http://${url}`);
  }

  return output.href;
};

export const getRequestOrigin = (req: IncomingMessage): string => {
  const { host } = req.headers;
  if (!host) {
    // Host header is mandatory according to the spec
    throw badRequest();
  }

  return host.startsWith("localhost") ? `http://${host}` : `https://${host}`;
};

export function getOrigin(req?: IncomingMessage) {
  if (req && req.headers) {
    // Urgh - this isnt perfect by any means (nextjs does not use express or anything)
    const protocol = req.headers?.referer?.split("://")[0] || "https";
    return `${protocol}://${req.headers.host}`;
  }

  if (typeof window !== "undefined") {
    return window.location.origin;
  }
  return null;
}

export function getHostname(uri: string) {
  try {
    return new URL(uri).hostname;
  } catch {
    return "";
  }
}

export const isOnKeyMilestonesPage = (path: string) => {
  return path.startsWith("/key-milestones");
};
