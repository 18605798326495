import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const CopyToClipBoardVariables = css`
  --clipboard-font-size: ${typography.size.small};
  --clipboard-font-family: var(--default-text-font);
  --clipboard-text-color: ${colors.secondary.ashGrey};
  --clipboard-icon-color: var(--theme-color-primary);
`;
