import algoliasearch, { SearchClient, SearchIndex } from "algoliasearch";

let client: SearchClient | undefined = undefined;
export const initSearchClient = () => {
  if (client) {
    return client;
  }

  const appId =
    process.env.ALGOLIA_APP_ID || process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
  const apiKey =
    process.env.ALGOLIA_API_KEY || process.env.NEXT_PUBLIC_ALGOLIA_API_KEY;

  if (!appId) {
    throw new Error("Cannot create search client: No appId");
  }
  if (!apiKey) {
    throw new Error("Cannot create search client: No apiKey");
  }

  client = algoliasearch(appId, apiKey);
  return client;
};

const indexMap = new Map<string, SearchIndex>();
export function initSearchIndex(indexName: string) {
  if (indexMap.has(indexName)) {
    return indexMap.get(indexName)!;
  }
  const index = initSearchClient().initIndex(indexName);
  indexMap.set(indexName, index);
  return index;
}

if (typeof window !== "undefined") {
  (window as any).indexMap = indexMap;
}
