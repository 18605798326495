import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const verifiedContentRibbonVariables = css`
  --verified-content-fold-background-color: ${colors.primary.cardanoBlue};
  --verified-content-fold-background-gradient: ${colors.gradient.linear.blue};
  --verified-content-font-size: ${typography.size.tiny};
  --verified-content-hover-color: ${colors.primary.cardanoBlue};
`;
