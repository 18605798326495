import { extend } from "@react-three/fiber";
import { AdditiveBlending, DoubleSide, ShaderMaterial } from "three";

export default class PlaneMaterial extends ShaderMaterial {
  constructor() {
    super({
      blending: AdditiveBlending,
      side: DoubleSide,
      transparent: true,
      uniforms: {
        uRadius: { value: 0.3 }
      },
      vertexShader: `
      varying vec2 vUv;
      uniform float uRadius;
      
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
      `,

      fragmentShader: `
      varying vec2 vUv;
      uniform float uRadius;

      void main() {

        float sprite;

        // outer circle
        float d = distance(vUv, vec2(0.5));
        sprite = 1.0 - smoothstep(0.0, 0.02, abs(uRadius - d));
        sprite *= 0.8;

        // inner dot
        float border = 0.015;
        float radius = 0.12;
        float dist = radius - distance(vUv, vec2(0.5));
        sprite += smoothstep(0.0, border, dist);

        float opacityFactor = 0.8;

        gl_FragColor = vec4( vec3(1.0), sprite * opacityFactor );

      }`
    });
  }
}

extend({ PlaneMaterial });
