import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";

type Props = {
  "data-size"?: "default" | "small";
  "data-theme"?: "default" | "dark";
};

export const Checkbox = styled.input.attrs<Props>({
  type: "checkbox"
})<Props>`
  --checkbox-size: ${pxToRem(26)};
  --checkbox-check-size: ${pxToRem(14)};

  &[data-size="small"] {
    --checkbox-size: ${pxToRem(14)};
    --checkbox-check-size: ${pxToRem(6)};
  }

  --border-color: var(--input-checkbox-border-color);
  --bg-color: var(--input-checked-background);

  &[data-theme="dark"] {
    --border-color: var(--input-checkbox-dark-border-color);
    --bg-color: var(--input-checked-dark-background);
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: ${pxToRem(1)} solid var(--border-color);
  border-radius: 0.25rem;
  margin: 0;
  position: relative;
  cursor: pointer;

  &:checked::before {
    content: "";
    background: var(--bg-color);
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--checkbox-check-size);
    height: var(--checkbox-check-size);
    transform: translate(-50%, -50%);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: default;
  }

  &:focus {
    outline: ${pxToRem(2)} solid var(--input-focus-color);
  }
`;

export default Checkbox;
