import { pxToRem } from "@input-output-hk/px-to-rem";
import { MenuButton } from "@reach/menu-button";
import styled from "styled-components";

export const ProfileButton = styled(MenuButton)`
  border: 0;
  background: 0;
  padding: 0;
  line-height: 1;
  cursor: pointer;
  touch-action: manipulation;
  min-width: ${pxToRem(60)};
  min-height: ${pxToRem(60)};
`;
