import { useRouter } from "next/router";
import Button from "../buttons/Button";

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const LoginButton: React.FC<Props> = ({
  children = "Log out",
  ...buttonProps
}) => {
  const router = useRouter();
  const href = `/api/auth/logout?returnTo=${encodeURIComponent(router.asPath)}`;
  return (
    <Button {...buttonProps} as="a" href={href} data-login-button>
      {children}
    </Button>
  );
};

export default LoginButton;
