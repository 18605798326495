import * as React from "react";

function Search(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-icon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 18a7 7 0 100-14 7 7 0 000 14zM20 20l-4-4"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Search;
