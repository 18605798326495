import React, { useRef, useState } from "react";
import {
  forDesktopUp,
  forNarrowPhoneOnly,
  forTabletLandscapeUp,
  forTabletLandscapeWideOnly
} from "../../tokens/media-queries";
import styled, { css } from "styled-components";

import { Awaited } from "../../utils/types";
import Button from "../buttons/Button";
import Heading from "../heading/Heading";
import { KEYS } from "../../utils/keyboard";
import Link from "next/link";
import { SearchForm } from "./SearchForm";
import { TSearchResult } from "../../data/search/types";
import { Tags } from "../item/Tags";
import Text from "../text/Text";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import colors from "../../tokens/colors";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { useRouter } from "next/router";
import { useSearchIndex } from "../../hooks/useSearchIndex";
import { useSharedState } from "../../hooks/useSharedState";

const AutoCompleteResult = styled.div`
  padding: var(--spacing-large);
  :hover,
  :focus {
    background-color: ${colors.secondary.tyneFog};
  }
  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
  }
  & ${Heading} {
    font-size: ${pxToRem(14)};
    margin: var(--spacing-small) 0;
  }
  & ${Text} {
    font-size: ${pxToRem(12)};
    margin: 0;
  }
`;

const SearchResultAndAutocompleteShared = styled.div<{
  appearance?: "default" | "small";
}>`
  ${(props) =>
    props.appearance === "default" &&
    css`
      width: calc(100vw - ${pxToRem(40)});

      @media ${forTabletLandscapeUp} {
        width: ${pxToRem(600)};
      }

      @media ${forTabletLandscapeWideOnly} {
        width: 55vw;
      }

      @media ${forDesktopUp} {
        width: ${pxToRem(600)};
      }
    `}
`;

const AutoCompleteResults = styled(SearchResultAndAutocompleteShared)<{
  appearance?: "default" | "small";
}>`
  //search results style

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: ${colors.primary.white};
  border: ${pxToRem(1)} solid ${colors.primary.white};
  border-radius: ${pxToRem(10)};
  padding: var(--spacing-large);
  box-shadow: ${pxToRem(1)} ${pxToRem(4)} ${pxToRem(18)} rgba(26, 52, 185, 0.15);
  margin-top: var(--spacing-default);
  display: block !important;

  ${(props) =>
    props.appearance === "small" &&
    css`
      @media ${forNarrowPhoneOnly} {
        min-width: ${pxToRem(290)};
        left: ${pxToRem(-55)};
        width: calc(100vw - ${pxToRem(40)});
      }
    `}

  & > * + * {
    border-top: var(--card-stroke);
  }
`;

const AutoCompleteContainer = styled(SearchResultAndAutocompleteShared)<{
  isLoading?: boolean;
}>`
  //autocomplete search box
  position: relative;

  &:not(&:focus-within) {
    ${AutoCompleteResults} {
      display: none;
    }
  }
  ${(props) =>
    props.isLoading &&
    css`
      ${AutoCompleteResults} > * {
        opacity: 0.9;
      }
    `}
`;

const ViewAllContainer = styled.div`
  padding: var(--spacing-default);
  & > [data-button] {
    display: flex;
    width: 100%;
    min-width: 100%;
  }
`;
type Props = {
  appearance?: "default" | "small";
  searchPlaceholderText: string;
};

const AutoComplete: React.FC<Props> = ({
  appearance = "default",
  searchPlaceholderText
}) => {
  const router = useRouter();
  const search = useSearchIndex<TSearchResult>("articles");
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useSharedState("__autocomplete__", "");
  const [, setResults] = useState<
    Awaited<ReturnType<typeof search>> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const resultsMapRef = useRef<{
    [key: string]: Awaited<ReturnType<typeof search>> | undefined;
  }>({});
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const term = value.trim();
    setValue(value);
    if (!value) {
      setResults(undefined);
    } else if (!resultsMapRef.current[term]) {
      // If we have no cached results, do the lookup. (queries cost $$)
      setLoading(true);
      search(term, { hitsPerPage: 3 }).then((res) => {
        resultsMapRef.current[res.query] = res;
        batchedUpdates(() => {
          setResults(res);
          setLoading(false);
        });
      });
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const params = new URLSearchParams();
    params.set("q", value);
    router.push(`/search?${params.toString()}`);
  }

  function handleClear() {
    setValue("");
    setResults(undefined);
    setLoading(false);
  }
  const results = resultsMapRef.current[value];
  const queryMatches = results?.query === value;
  const numHits = queryMatches ? results?.nbHits ?? 0 : 0;
  const hasHits = numHits > 0;

  function handleKeyDown(e: React.KeyboardEvent) {
    const key = e.keyCode || e.which;
    const activeElement = document.activeElement as HTMLElement | null;
    const container = containerRef.current!;
    if (!activeElement || !container.contains(activeElement)) {
      return;
    }
    const focusableResults = Array.from(
      container.querySelectorAll<HTMLElement>("[data-results] > [tabindex]")
    );
    const currentFocusIdx = focusableResults.indexOf(activeElement);

    switch (key) {
      /**
       * Move focus down the results
       */
      case KEYS.arrowDown:
        const nextFocus = (currentFocusIdx + 1) % focusableResults.length;
        const nextFocusElem = focusableResults[nextFocus];
        if (nextFocusElem) {
          e.preventDefault();
          nextFocusElem.focus();
        }
        break;
      /**
       * Move focus up the results
       */
      case KEYS.arrowUp:
        const prevFocus =
          (currentFocusIdx - 1 + focusableResults.length) %
          focusableResults.length;
        const prevFocusElem = focusableResults[prevFocus];
        if (prevFocusElem) {
          e.preventDefault();
          prevFocusElem.focus();
        }
        break;
      /**
       * Select a result
       */
      case KEYS.space:
      case KEYS.enter:
        if (currentFocusIdx > -1) {
          e.preventDefault();
          const hit = results?.hits[currentFocusIdx];
          if (hit) {
            activeElement.blur();
            router.push(`/${hit._category}/${hit._slug}`);
          }
        }
    }
  }

  return (
    <AutoCompleteContainer
      isLoading={results?.query !== value && loading}
      ref={containerRef}
      onKeyDown={handleKeyDown}
      appearance={appearance}
    >
      <SearchForm
        placeholderText={searchPlaceholderText}
        value={value}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onClear={handleClear}
        allowClear={!!value}
        appearance={appearance}
        loading={loading}
        autoFocus={false}
      />
      {!!value && (
        <AutoCompleteResults data-results appearance={appearance}>
          {hasHits &&
            results!.hits.map((hit) => (
              <AutoCompleteResult
                key={hit._id}
                tabIndex={0}
                onClick={(e) => {
                  if ((e.target as HTMLElement).tagName !== "A") {
                    router.push(`/${hit._category}/${hit._slug}`);
                  }
                }}
              >
                <Link href={`/${hit._category}/${hit._slug}`}>
                  <a>
                    <Heading level="4">{hit.name}</Heading>
                    <Text>
                      {hit.articleBody.slice(0, 100)}
                      {hit.articleBody.length > 100 ? "..." : ""}
                    </Text>
                  </a>
                </Link>
                <Tags tags={hit._tags} />
              </AutoCompleteResult>
            ))}
          {numHits > 3 && (
            <ViewAllContainer>
              <Button
                tabIndex={0}
                appearance="primary"
                as="a"
                href={`/search?q=${value}`}
              >
                View all results
              </Button>
            </ViewAllContainer>
          )}
          {!loading && !hasHits && (
            <ViewAllContainer>
              <Text element="p" level="2" isMuted>
                Nothing found for &quot;{value}&quot;
              </Text>
              <Button
                tabIndex={0}
                appearance="secondary"
                as="a"
                href={`/search?q=${value}`}
              >
                Advanced search
              </Button>
            </ViewAllContainer>
          )}
        </AutoCompleteResults>
      )}
    </AutoCompleteContainer>
  );
};

export default AutoComplete;
