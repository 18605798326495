export const iogSocials = {
  twitter: "https://twitter.com/InputOutputHK/",
  youtube: "https://www.youtube.com/c/IohkIo/",
  facebook: "https://www.facebook.com/iohk.io/",
  linkedin: "https://www.linkedin.com/company/input-output-global/",
  instagram: "https://www.instagram.com/IOHKOfficial/"
};

export const partners = {
  iog: "https://iohk.io",
  cardanoFoundation: "https://cardanofoundation.org/",
  emurgo: "https://emurgo.io/"
};

export const lace = {
  href: "https://www.lace.io/blog/the-personalized-ada-handle-feature-is-now-available-on-mainnet?utm_source=essentialcardano.io&utm_medium=website&utm_campaign=ada-handle&utm_content=banner",
  rel: "external",
  target: "_blank"
};

export const guide = {
  href: "https://services.iohk.io/guide-to-the-ecosystem",
  rel: "external",
  target: "_blank"
};
