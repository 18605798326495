import React from "react";
import Search, { Props } from "./Search";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import IconSearch from "../icons/Search";

/**
 * Search Input wrapped with a form
 * in order to fallback and work correctly
 * without javascript
 */

const SearchAction = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  display: none;
  background-color: var(--theme-color-primary);
  border: ${pxToRem(1)} solid var(--theme-color-primary);
  color: ${colors.primary.white};
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  touch-action: manipulation;
  box-shadow: ${pxToRem(5)} ${pxToRem(5)} ${pxToRem(10)}
      rgba(170, 170, 204, 0.5),
    ${pxToRem(-5)} ${pxToRem(-5)} ${pxToRem(10)} #ffffff;

  justify-content: center;
  display: flex;
  > * {
    flex-shrink: 0;
  }
  &[data-appearance="small"] {
    width: ${pxToRem(27)};
    height: ${pxToRem(27)};
    right: ${pxToRem(12)};
    [data-search-icon] {
      width: ${pxToRem(15)};
      height: ${pxToRem(15)};
      > path {
        stroke-width: 3;
      }
    }
  }
`;

export function SearchForm({
  placeholderText,
  onSubmit,
  appearance,
  ...props
}: Props & {
  appearance?: "default" | "small";
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}) {
  return (
    <>
      <form action="/search" method="GET" onSubmit={onSubmit} name="search">
        <Search
          name="q"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
          placeholder={placeholderText}
          ariaLabel="Search"
          appearance={appearance}
          {...props}
        >
          <SearchAction data-appearance={appearance}>
            <IconSearch data-search-icon />
          </SearchAction>
        </Search>
      </form>
    </>
  );
}
