import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { trueOrUndef } from "../../utils/dom-attribute-helpers";
import IconArrowDown from "../icons/ArrowDown";
import { MEDIA_CATEGORY_ROUTES } from "../page-partials/ContentNavigation";
// import { EXPLORE_ITEMS } from "./constants";

/* Disabling dropdown for mobile nav and only showing
 * community channels until we have more content in place
 */

const NavElement = styled.nav`
  --nav-item-font-size: ${pxToRem(16)};
  color: var(--theme-color-primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  will-change: transform, opacity;
  visibility: visible;
  cursor: pointer;

  > [aria-controls] {
    margin-top: var(--spacing-large);
  }
  > a {
    font-weight: 700;
  }
  /* Remove blue highlight color */
  & {
    -webkit-tap-highlight-color: transparent;
    outline: none !important;
  }
`;

const NavSubmenu = styled.div`
  --item-count: 1;
  --item-height: ${pxToRem(
    1 + // border
      10 + // padding
      28 // line-height
  )};
  background-color: white;
  will-change: height, opacity;
  cursor: pointer;
  transition: opacity 0.35s cubic-bezier(0.23, 1, 0.32, 1),
    height 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  height: calc(var(--item-height) * var(--item-count));
  overflow: hidden;
  margin-left: ${pxToRem(-20)};
  margin-right: ${pxToRem(-20)};
  margin-bottom: ${pxToRem(20)};

  &[aria-hidden] {
    height: 0;
    opacity: 0;
  }

  & > a {
    color: var(--secondary-nav-color);
    border-bottom: ${pxToRem(1)} solid rgba(33, 33, 33, 0.08);
    padding-left: var(--spacing-xlarge);
  }
`;

const NavContainer = styled.div`
  width: 100%;
`;

const NavItemAnchor = styled.a<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  padding: var(--spacing-small);

  text-decoration: none;
  color: inherit;
  font-size: var(--nav-item-font-size);
  touch-action: manipulation;

  cursor: pointer;
`;

const StyledPrimaryArrow = styled(IconArrowDown)`
  color: inherit;
`;

const NavToggleIcon = styled.span`
  margin-left: auto;
  display: inline-block;
  transform-origin: 50% 50%;
  margin-left: auto;
  transition: transform 0.3s ease-out;
  transform: rotate(0deg);
  [aria-expanded="true"] & {
    transform: rotate(180deg);
  }
`;

const FullNavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavItem: React.FC<
  React.ComponentProps<typeof NavItemAnchor> & {
    href: string;
    isIndex?: boolean;
  }
> = ({ href, isIndex, ...props }) => {
  const router = useRouter();
  const isActive = isIndex
    ? router.asPath.split("?")[0] === href
    : router.asPath.startsWith(href);
  return (
    <Link href={href} passHref>
      <NavItemAnchor {...props} data-active={isActive || undefined} />
    </Link>
  );
};

type Props = {};
const Navigation: React.FC<Props> = () => {
  const [mediaOpen, setMediaOpen] = useState(true);
  // const [exploreOpen, setExploreOpen] = useState(true);

  return (
    <FullNavContainer>
      <NavContainer>
        <NavElement>
          <NavItemAnchor
            tabIndex={0}
            aria-controls="media-navigation-menu"
            aria-expanded={mediaOpen}
            onClick={() => setMediaOpen(!mediaOpen)}
          >
            Content
            <NavToggleIcon>
              <StyledPrimaryArrow />
            </NavToggleIcon>
          </NavItemAnchor>
          <NavSubmenu
            id="media-navigation-menu"
            aria-hidden={trueOrUndef(!mediaOpen)}
            style={
              {
                "--item-count": String(
                  Object.keys(MEDIA_CATEGORY_ROUTES).length
                )
              } as any
            }
          >
            {Object.entries(MEDIA_CATEGORY_ROUTES).map(([path, label]) => {
              const href = `/${path}`;
              return (
                <NavItem key={href} href={href}>
                  {label}
                </NavItem>
              );
            })}
          </NavSubmenu>
          <NavItem href="/community" isIndex>
            Community Channels
          </NavItem>
          {/* <NavItemAnchor
            tabIndex={0}
            aria-controls="explore-navigation-menu"
            onClick={() => setExploreOpen(!exploreOpen)}
            aria-expanded={exploreOpen}
          >
            Explore
            <NavToggleIcon>
              <StyledPrimaryArrow />
            </NavToggleIcon>
          </NavItemAnchor>
          <NavSubmenu
            id="explore-navigation-menu"
            aria-hidden={trueOrUndef(!exploreOpen)}
            style={{ "--item-count": String(EXPLORE_ITEMS.length) } as any}
          >
            {EXPLORE_ITEMS.map(({ href, label }) => {
              return (
                <NavItem key={href} href={href}>
                  {label}
                </NavItem>
              );
            })}
          </NavSubmenu> */}
        </NavElement>
      </NavContainer>
    </FullNavContainer>
  );
};

export default Navigation;
