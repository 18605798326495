import { useUser } from "@auth0/nextjs-auth0";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { Menu } from "@reach/menu-button";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import { BlessedIcon } from "./BlessedIcon";
import { ProfileButton } from "./ProfileButton";
import { ProfileElement } from "./ProfileElement";
import { Avatar } from "../avatar/Avatar";
import { AvatarPlaceholder } from "../avatar/AvatarPlaceholder";
import LoginButton from "../login-prompt/LoginButton";
import SignupButton from "../login-prompt/SignupButton";
import {
  StyledMenuLink,
  StyledMenuPopover
} from "../pop-menu-button/PopMenuButton";
import Text from "../../components/text/Text";
import { useNotificationCount } from "../../context/NotificationCountContext";
import colors from "../../tokens/colors";
import zIndex from "../../tokens/z-index";
import { isBlessedUser } from "../../utils/user-role";
export const NotificationAlertInMenu = styled.div<{
  notificationCount: number;
}>`
  --notification-alert-color: ${colors.feedback.errorRed};
  display: ${(props) => (props.notificationCount > 0 ? "flex" : "none")};
  justify-content: center;
  width: ${pxToRem(33)};
  height: ${pxToRem(22)};
  background-color: var(--notification-alert-color);
  margin-left: ${pxToRem(5)};
  border-radius: ${pxToRem(15)};
`;

export const NotificationCount = styled(Text)`
  color: ${colors.primary.white};
`;

export const NotificationAlert = styled.span<{ notificationCount: number }>`
  --notification-alert-color: ${colors.feedback.errorRed};
  position: absolute;
  display: block;
  justify-content: center;
  width: ${pxToRem(15)};
  height: ${pxToRem(15)};
  border-radius: ${pxToRem(15)};
  background-color: var(--notification-alert-color);
  left: ${pxToRem(10)};
  bottom: ${pxToRem(10)};
  z-index: ${zIndex.navigation};

  ${(props) =>
    props.notificationCount > 0
      ? `visibility: visible;
          opacity: 1;`
      : `visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 1000ms, opacity 1000ms;`}
`;

const AvatarPlaceholderContainer = styled.div`
  min-width: ${pxToRem(60)};
  min-height: ${pxToRem(60)};
  display: flex;
  align-items: center;
`;

export const notificationAlertLimit = 11;
export const notificationCountAboveAlertLimit = "10+";

export const ProfileMenu: React.FC = () => {
  const { user, isLoading } = useUser();
  const isBlessed = isBlessedUser(user);
  const router = useRouter();
  const avatar = user?.picture;

  const { notificationCount } = useNotificationCount();

  const logoutHref = `/api/auth/logout?returnTo=${encodeURIComponent(
    router.asPath
  )}`;

  return (
    <>
      {!isLoading && !user && (
        <>
          <LoginButton
            size="small"
            appearance={"secondary"}
            disabled={isLoading}
          />
          <SignupButton
            size="small"
            appearance={"primary"}
            disabled={isLoading}
          />
        </>
      )}
      <ProfileElement data-blessed={isBlessed} data-profile-element>
        {isBlessed && <BlessedIcon>🤘</BlessedIcon>}
        {isLoading && !user && (
          <AvatarPlaceholderContainer>
            <AvatarPlaceholder level="1" />
          </AvatarPlaceholderContainer>
        )}
        {user && (
          <Menu>
            <ProfileButton>
              <NotificationAlert notificationCount={notificationCount} />
              <Avatar level="1" src={avatar} alt={"profile"} />
            </ProfileButton>
            <StyledMenuPopover>
              <StyledMenuLink href="/profile">Profile</StyledMenuLink>
              <StyledMenuLink href="/profile/account">Account</StyledMenuLink>
              <StyledMenuLink href={"/profile/notifications"}>
                Notifications
                <NotificationAlertInMenu notificationCount={notificationCount}>
                  <NotificationCount level={"3"}>
                    {notificationCount < notificationAlertLimit
                      ? notificationCount
                      : notificationCountAboveAlertLimit}
                  </NotificationCount>
                </NotificationAlertInMenu>
              </StyledMenuLink>
              <StyledMenuLink href={logoutHref}>Logout</StyledMenuLink>
            </StyledMenuPopover>
          </Menu>
        )}
      </ProfileElement>
    </>
  );
};

export default ProfileMenu;
