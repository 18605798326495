import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const voteVariables = css`
  --vote-text-size: ${typography.size.h4};
  --vote-text-font: ${typography.font.default};
  --vote-focus-color: var(--default-focus-color);
  --vote-up-active-color: ${colors.action.pass};
  --vote-down-active-color: ${colors.action.fail};
  --vote-count-inline-text: ${typography.size.small};
`;
