import { extend } from "@react-three/fiber";
import { ShaderMaterial } from "three";

export default class ParticleBGMaterial extends ShaderMaterial {
  constructor() {
    super({
      transparent: true,
      uniforms: {
        uTime: { value: 1 },
        uScrollY: { value: 0 },
        opacity: { value: 1 }
      },
      vertexShader: `
      uniform float uTime;
      uniform float uScrollY;

      attribute float size;
      attribute vec3 color;
      attribute float id;

      varying float vId;
      varying vec4 transformed;
      varying vec3 vPosition;
      varying vec3 vColor;
      varying float perspectiveSizeSq;
      varying float perspectiveSize;
      
      void main() {
        vId = id;
        vColor = color;
        vPosition = position;

        vec3 newPos = position.xyz + vec3(
          sin(uTime * 0.001) * 50.0,
          uScrollY * 0.025,
          cos(uTime*0.1) * 50.0
        );

        newPos.y = mod(newPos.y, 500.0); // wrap y position

        gl_Position = projectionMatrix * modelViewMatrix * vec4(newPos, 1.0);

        transformed = gl_Position;

        perspectiveSizeSq = dot(transformed.xyz, transformed.xyz) * 0.000009;
        perspectiveSize = length(transformed.xyz) * 0.005;
        gl_PointSize = max(3.0, size / perspectiveSizeSq);
      }`,

      fragmentShader: `
      uniform float uTime;
      uniform float opacity;
      
      varying float vId;
      varying vec4 transformed;
      varying vec3 vPosition;
      varying vec3 vColor;
      varying float perspectiveSizeSq;
      varying float perspectiveSize;

      float rand(float n){
        return fract(sin(n) * 43758.5453123);
      }

      void main() {
        float border = clamp(rand(vId), 0.0, 0.45);
        float radius = 0.5;
        float dist = radius - distance(gl_PointCoord.xy, vec2(0.5));
        float t = smoothstep(0.0, border, dist);
        gl_FragColor = vec4(vColor, t * (opacity * (max(perspectiveSizeSq * 1.2, 0.05) )));
        gl_FragColor.a *= 1.0-(perspectiveSize*0.3);
        float finalOpacity = clamp(sin( (vId * 100.0) + uTime * 0.5 ), 0.0, 0.5);
        gl_FragColor.a *= finalOpacity;
        gl_FragColor.a *= 0.5;
      }`
    });
  }
}

extend({ ParticleBGMaterial });
