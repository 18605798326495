import styled, { css } from "styled-components";

type Props = { level?: "00" | "0" | "1" | "2" | "3" };

const stackCss = css`
  &[data-level="00"] > * + * {
    margin-top: var(--spacing-level00);
  }
  &[data-level="0"] > * + * {
    margin-top: var(--spacing-level0);
  }
  &[data-level="1"] > * + * {
    margin-top: var(--spacing-level1);
  }
  &[data-level="2"] > * + * {
    margin-top: var(--spacing-level2);
  }
  &[data-level="3"] > * + * {
    margin-top: var(--spacing-level3);
  }
`;

export const Stack = styled.div.attrs<Props>(({ level = "2" }) => ({
  ["data-level"]: level
}))<Props>`
  ${stackCss}
`;

export const Column = styled.div.attrs<Props>(({ level = "2" }) => ({
  ["data-level"]: level
}))<Props>`
  display: flex;
  flex-direction: column;
  ${stackCss}
`;
