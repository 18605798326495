import styled, { css, keyframes } from "styled-components";
import zIndex from "../tokens/z-index";
import { pxToRem } from "@input-output-hk/px-to-rem";

type Props = {
  /**
   * Size of the spiner
   */
  size?: "small" | "default" | "large" | "huge";
};

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(980deg);
    }
`;
const spinAnimation = css`
  animation: ${spin} 2s cubic-bezier(0.23, 1, 0.32, 1);
  animation-iteration-count: infinite;
`;

const LoadingSpinner = styled.span.attrs<Props>((props) => ({
  "data-size": props.size,
  "data-loading-spinner": true
}))<Props>`
  position: relative;
  display: inline-block;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border-radius: 50%;
  border: ${pxToRem(2)} solid transparent;
  border-top-color: currentColor;
  z-index: ${zIndex.base + 1};
  &:after {
    content: "";
    position: absolute;
    top: ${pxToRem(-2)};
    left: ${pxToRem(-2)};
    right: ${pxToRem(-2)};
    bottom: ${pxToRem(-2)};
    border-radius: 50%;
    border: ${pxToRem(2)} solid currentColor;
    opacity: 0.25;
  }
  ${spinAnimation};
  &[data-size="small"] {
    width: ${pxToRem(10)};
    height: ${pxToRem(10)};
  }
  &[data-size="large"] {
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
  }
  &[data-size="huge"] {
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    border-width: ${pxToRem(5)};
    &:after {
      top: ${pxToRem(-5)};
      left: ${pxToRem(-5)};
      right: ${pxToRem(-5)};
      bottom: ${pxToRem(-5)};
      border-width: ${pxToRem(5)};
    }
  }
`;

export default LoadingSpinner;
