import Button from "../buttons/Button";

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const AccountButton: React.FC<Props> = ({
  children = "Account",
  ...buttonProps
}) => {
  const href = `/profile/account`;
  return (
    <Button {...buttonProps} as="a" href={href} data-login-button>
      {children}
    </Button>
  );
};

export default AccountButton;
