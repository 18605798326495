import { RoadmapContext } from "@input-output-hk/essential-cardano-roadmap";
import { pxToRem } from "@input-output-hk/px-to-rem";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext } from "react";
import styled from "styled-components";
import colors from "../../tokens/colors";
import {
  forPhoneOnly,
  forTabletPortraitDown
} from "../../tokens/media-queries";
import typography from "../../tokens/typography";
import { isOnKeyMilestonesPage } from "../../utils/url-utils";
import Heading from "../heading/Heading";
import { Logo } from "../Logo";
import OfficialSocialIcons from "../social-icons/OfficialSocialIcons";
import Text from "../text/Text";
import NewsLetter from "./newsletter/NewsLetter";

const FooterWrapper = styled.footer`
  position: relative;
  margin: 0 auto;
  padding: 0 ${pxToRem(40)} ${pxToRem(220)} var(--spacing-xlarge);
  max-width: var(--page-max-width);

  &[data-show-border="true"] {
    border-top: var(--card-stroke);
  }

  [data-social-official-links] {
    position: relative;
    top: ${pxToRem(30)};
    margin-bottom: var(--spacing-xlarge);
  }
  [data-heading] {
    margin: ${pxToRem(30)} 0 0 0;
  }
  [data-terms] {
    margin-top: ${pxToRem(25)};
  }
  [data-social-official-links] {
    padding: ${pxToRem(15)} 0 ${pxToRem(30)} 0;
    display: flex;
  }
  [data-logo-holder] {
    margin-top: ${pxToRem(50)};
  }
  [data-logo-holder],
  [data-nav-holder],
  [data-newsletter] {
    flex-flow: column wrap;
    padding: 0;
  }
  @media ${forTabletPortraitDown} {
    padding-bottom: ${pxToRem(270)};
  }
  @media ${forPhoneOnly} {
    [data-social-link] {
      flex-grow: 4;
    }
    padding-bottom: ${pxToRem(320)};
  }
  @media (min-width: 1100px) {
    display: flex;
    [data-logo-holder],
    [data-nav-holder],
    [data-newsletter] {
      width: 25%;
      padding: var(--spacing-large);
    }
    padding-left: 0;
    padding-right: 0;
  }

  &[data-theme="dark"] {
    --text-color: ${colors.primary.white};
    --link-color: var(--text-color);

    --logo-top-text-color: var(--text-color);
    --logo-cardano-text-color: var(--text-color);

    color: var(--text-color);
  }
`;
const LogoHolder = styled.div`
  padding: ${pxToRem(60)} 0;
  [data-social-official-links] {
    margin: 0 auto;
  }
`;
const NavHolder = styled.div`
  & a,
  & a:link,
  & a:visited {
    display: block;
    color: var(--link-color);
    font-family: ${typography.font.default};
    font-size: ${typography.size.default};
    line-height: ${typography.lineHeight.h3};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const SocialLinks = styled(OfficialSocialIcons)`
  color: var(--link-color);
`;
const NavWrapper = styled.div``;

const Copyright = styled.div`
  position: absolute;
  bottom: var(--spacing-xlarge);
  left: ${pxToRem(95)};
  transform: translateX(-50%);
  font-size: var(--text-level3-size);
  color: var(--text-color-muted);
`;

const Disclaimer = styled(Text)`
  position: absolute;
  bottom: ${pxToRem(120)};
  width: 100%;
  max-width: var(--page-max-width);
  left: 50%;
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
  padding-top: var(--spacing-xlarge);
  transform: translateX(-50%);
  font-size: var(--text-level3-size);
  line-height: var(--text-level2-lineHeight);

  &[data-show-border="true"] {
    border-top: var(--card-stroke);
  }
`;

const PageFooter: React.FC<{
  theme?: "default" | "dark";
  showBorder?: boolean;
}> = ({ theme = "default", showBorder = true }) => {
  const { roadmapPresentationMode } = useContext(RoadmapContext);
  const router = useRouter();

  if (roadmapPresentationMode && isOnKeyMilestonesPage(router.asPath)) {
    return null;
  }

  return (
    <FooterWrapper data-theme={theme} data-show-border={showBorder}>
      <LogoHolder data-logo-holder>
        <Logo />
        <SocialLinks />
      </LogoHolder>
      <NavHolder data-nav-holder>
        <Heading level="5" weight="bold" data-heading>
          Resources
        </Heading>
        <a target="_blank" href="https://cardano.org/" rel="noreferrer">
          Cardano.org
        </a>
        <a
          target="_blank"
          href="https://cardanofoundation.org/"
          rel="noreferrer"
        >
          Cardano Foundation
        </a>
        <a target="_blank" href="https://docs.cardano.org/" rel="noreferrer">
          Cardano Docs
        </a>
        <a target="_blank" href="https://emurgo.io/" rel="noreferrer">
          EMURGO
        </a>
        <a target="_blank" href="https://iohk.io/" rel="noreferrer">
          IOHK
        </a>
        <a target="_blank" href="https://lace.io/" rel="noreferrer">
          Lace
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCX9j__vYOJu00iqBrCzecVw"
          rel="noreferrer"
        >
          IOG Academy
        </a>
      </NavHolder>
      <NavWrapper data-nav-holder>
        <NavHolder data-terms>
          <Heading level="5" weight="bold" data-heading>
            Legal
          </Heading>
          <Link href="/privacy">
            <a>Privacy policy</a>
          </Link>
          <Link href="/terms">
            <a>Terms and conditions</a>
          </Link>
          <Link href="/additional-disclosures">
            <a>Additional Disclosures</a>
          </Link>
        </NavHolder>
      </NavWrapper>
      <NewsLetter theme={theme} />
      <Disclaimer isMuted={true} data-show-border={showBorder}>
        The content on this website is provided “as is” with no guarantees of
        any kind. Nothing on this website is intended to be professional advice,
        including without limitation, financial, investment, legal or tax
        advice. © {new Date().getFullYear()} Input Output Global is not
        responsible for your use of or reliance on any information in this
        website.
      </Disclaimer>
      <Copyright>© {new Date().getFullYear()} Input Output Global</Copyright>
    </FooterWrapper>
  );
};
export default PageFooter;
