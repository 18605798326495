import { ChangeEventHandler, FormEventHandler, useState } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import Button from "../../buttons/Button";
import Heading from "../../heading/Heading";
import IconEmail from "../../icons/Email";
import Checkbox from "../../checkbox/Checkbox";
import typography from "../../../tokens/typography";

const Container = styled.div``;
const SignUpButton = styled(Button)`
  display: block;
  margin-top: ${pxToRem(30)};
`;
const EmailInput = styled.input`
  background: transparent;
  border: ${pxToRem(2)} solid var(--input-border-color);
  border-width: 0 0 ${pxToRem(2)} 0;
  color: var(--default-text-color);
  outline: 0;
  font-size: var(--default-text-size);
  padding: ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(30)};
  width: 100%;
  &::placeholder {
    color: var(--input-placeholder-color-2);
  }
`;
const EmailInputWrapper = styled.div`
  position: relative;
  margin-top: ${pxToRem(10)};
`;
const Icon = styled(IconEmail)`
  position: absolute;
  top: ${pxToRem(4)};
  left: 0;
`;

const TermsWrapper = styled.div`
  margin-top: ${pxToRem(10)};
  font-size: ${typography.size.small};
  line-height: ${typography.lineHeight.small};

  display: flex;

  input {
    flex: 1 0 auto;
    margin-top: ${pxToRem(4)};
    margin-right: ${pxToRem(10)};
  }

  a,
  a:link,
  a:visited {
    color: var(--link-color);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const NewsLetter: React.FC<{ theme?: "dark" | "default" }> = ({
  theme = "default"
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [showEmptyFieldErrorMessage, setShowEmptyFieldErrorMessage] =
    useState(false);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setShowEmptyFieldErrorMessage(false);
    setEmail(e.target.value);
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    setSubmitting(true);
    if (email) {
      fetch("/api/newsletter", {
        method: "POST",
        body: JSON.stringify({ email, accept_privacy_policy: acceptedTerms }),
        headers: {
          "content-type": "application/json"
        }
      })
        .then(() => setSubmitted(true))
        .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
      setShowEmptyFieldErrorMessage(true);
    }
  };

  return (
    <Container data-newsletter>
      <Heading level="5" weight="bold" data-heading>
        Subscribe to our newsletter
      </Heading>
      <form onSubmit={handleSubmit}>
        <EmailInputWrapper>
          <Icon />
          <EmailInput
            type="email"
            placeholder="Enter your email address"
            value={
              showEmptyFieldErrorMessage
                ? "Please enter email address!"
                : submitted
                ? "Subscribed!"
                : email
            }
            onChange={handleChange}
            disabled={submitting || submitted}
          />
        </EmailInputWrapper>

        <TermsWrapper>
          <Checkbox
            data-size="small"
            data-theme={theme}
            id="accept_privacy_policy-input-1"
            name="accept_privacy_policy"
            checked={acceptedTerms}
            onChange={(e) => setAcceptedTerms(e.target.checked)}
          />
          <label htmlFor="accept_privacy_policy-input-1">
            By checking this box, I agree to allow IOG to store and process the
            personal data submitted on this form in accordance with the IOG{" "}
            <a
              href="https://static.iohk.io/terms/iog-privacy-policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </label>
        </TermsWrapper>

        <SignUpButton
          type="submit"
          appearance={theme === "dark" ? "inverted" : "secondary"}
          disabled={!acceptedTerms || !email}
        >
          Sign up
        </SignUpButton>
      </form>
    </Container>
  );
};
export default NewsLetter;
