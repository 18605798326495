import { Svg, SvgProps } from "./Svg";

function Contribute(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={25}
      height={27}
      viewBox="0 0 24 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.5,24.5h-17c-0.4,0-0.8-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-1V2.9c0-0.4,0.2-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4
	h11.2c0.4,0,0.8,0.2,1,0.4l2.8,2.8l2.9,2.8c0.3,0.2,0.4,0.6,0.4,0.9V23c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.1,0.1-0.3,0.2-0.5,0.3C20.9,24.4,20.7,24.5,20.5,24.5L20.5,24.5z"
        stroke="currentColor"
        fill="transparent"
        strokeWidth={2}
      />
      <path
        d="M11.9,9.2v7.6"
        stroke="currentColor"
        fill="transparent"
        strokeWidth={2}
      />
      <path
        d="M15.8,12.8H8.2"
        stroke="currentColor"
        fill="transparent"
        strokeWidth={2}
      />
    </Svg>
  );
}

export function ContributeSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={14}
      height={15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.648 5.412v4.411M8.943 7.53H4.531"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.63 14.235H1.84A.86.86 0 011.246 14 .785.785 0 011 13.432V1.802c0-.212.089-.416.246-.567A.86.86 0 011.84 1h6.465a.86.86 0 01.591.238l1.644 1.588 1.671 1.598c.157.143.25.34.26.547v8.435a.77.77 0 01-.237.585.874.874 0 01-.604.245v0z"
        stroke="currentColor"
        strokeWidth={1.25}
        strokeMiterlimit={10}
      />
    </Svg>
  );
}

export default Contribute;
