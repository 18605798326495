import { pxToRem } from "@input-output-hk/px-to-rem";
import {
  SkipNavContent,
  SkipNavLink as BaseSkipNavLink
} from "@reach/skip-nav";
import styled from "styled-components";
import zIndex from "../../tokens/z-index";
import { visuallyHiddenCss } from "./VisuallyHidden";

const SkipNavLink = styled(BaseSkipNavLink)`
  ${visuallyHiddenCss}

  &:focus {
    padding: 1rem;
    position: fixed;
    top: ${pxToRem(10)};
    left: ${pxToRem(10)};
    background: white;
    z-index: ${zIndex.navigation};
    width: auto;
    height: auto;
    clip: auto;
  }
`;

export { SkipNavContent, SkipNavLink };
