import React, { useRef, useState } from "react";

import BaseButton from "../../buttons/Button";
import Contribute from "../../icons/Contribute";
import ContributePopUp from "./ContributePopUp";
import { forTabletPortraitDown } from "../../../tokens/media-queries";
import { pxToRem } from "@input-output-hk/px-to-rem";
import styled from "styled-components";
import zIndex from "../../../tokens/z-index";

const ContributeWrapper = styled.div`
  --button-gap-right: ${pxToRem(50)};
  --button-gap-bottom: ${pxToRem(50)};
  position: fixed;
  width: fit-content;
  margin-left: auto;
  padding-right: var(--button-gap-right);
  height: ${pxToRem(88)};
  padding-bottom: var(--spacing-large);
  right: 0;
  bottom: var(--spacing-large);
  text-align: right;
  z-index: ${zIndex.contribute};

  @media ${forTabletPortraitDown} {
    bottom: 0;
    height: initial;
    --button-gap-right: var(--spacing-large);
    z-index: ${zIndex.contribute};
  }
`;

const Button = styled(BaseButton)`
  --button-primary-border-color: #fff;
  --button-primary-border-color-hover: #fff;
  box-shadow: 0 0 ${pxToRem(25)} rgba(0, 67, 101, 0.35);
`;

const ContributeButton: React.FC = ({}) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const rectRef = useRef<DOMRect | null>(null);
  const [shouldShowPopUp, setShouldShowPopUp] = useState(false);

  const getBottom = () => {
    if (typeof window !== "undefined") {
      return window.innerHeight - (rectRef.current?.bottom || 0);
    }
  };

  return (
    <ContributeWrapper>
      <Button
        ref={buttonRef}
        as="a"
        appearance={shouldShowPopUp ? "secondary" : "primary"}
        size="huge-responsive"
        onClick={() => {
          rectRef.current = buttonRef.current?.getBoundingClientRect() ?? null;
          setShouldShowPopUp(!shouldShowPopUp);
        }}
        role="button"
        style={
          shouldShowPopUp
            ? {
                position: "fixed",
                bottom: getBottom(),
                right: "var(--button-gap-right)"
              }
            : undefined
        }
      >
        <Contribute />
        <span>Contribute</span>
      </Button>
      <ContributePopUp
        sourceEl={buttonRef.current}
        isOpen={shouldShowPopUp}
        onRequestClose={() => setShouldShowPopUp(false)}
      />
    </ContributeWrapper>
  );
};

export default ContributeButton;
