import { useRouter } from "next/router";
import { useMemo } from "react";
import { toArray } from "../api-utils/item-filters";
import {
  RoadmapFilterOption,
  ROADMAP_FILTER_OPTIONS
} from "../data/roadmap/filter-options";

type FiltersHook = {
  filterBy: typeof ROADMAP_FILTER_OPTIONS[number];
  themeFilters: string[];
};

export function useRoadmapFilters(): FiltersHook {
  const router = useRouter();
  const filterBy = useMemo<RoadmapFilterOption>(() => {
    const value = Array.isArray(router.query.filterBy)
      ? router.query.filterBy[0]
      : router.query.filterBy;

    if (value) {
      const found = ROADMAP_FILTER_OPTIONS.indexOf(value as any) > -1;

      if (found) return value as RoadmapFilterOption;
    }

    return ROADMAP_FILTER_OPTIONS[0];
  }, [router.query.filterBy]);

  const themeFilters = toArray(router.query.theme || []);

  return { filterBy, themeFilters };
}
