import * as React from "react";

// yes, I know there's another Filters icon. This one's different 😆
const Filters2 = (props) => (
  <svg
    width={18}
    height={14}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-icon
    {...props}
  >
    <rect
      x={10.75}
      y={8.75}
      width={4.5}
      height={4.5}
      rx={2.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.095 10.25a3.005 3.005 0 0 0 0 1.5H.75a.75.75 0 0 1 0-1.5h9.345Zm5.81 0a3.005 3.005 0 0 1 0 1.5h1.345a.75.75 0 0 0 0-1.5h-1.345Z"
      fill="currentColor"
    />
    <rect
      x={2.75}
      y={0.75}
      width={4.5}
      height={4.5}
      rx={2.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.095 2.25a3.005 3.005 0 0 0 0 1.5H.75a.75.75 0 0 1 0-1.5h1.345Zm5.81 0a3.005 3.005 0 0 1 0 1.5h9.345a.75.75 0 0 0 0-1.5H7.905Z"
      fill="currentColor"
    />
  </svg>
);

export default Filters2;
