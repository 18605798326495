import React, { useRef, useMemo, useEffect, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { isMobile } from "react-device-detect";
import { Euler, Quaternion, Vector2 } from "three";

import { clamp } from "./math";
import { useScrollStore } from "./state/scroll";

export default function ParentGroup({ children }) {
  const { size } = useThree();

  const NDCMousePos = useRef(new Vector2());
  const mouseDown = useRef(false);
  const [rEuler, rQuaternion] = useMemo(
    () => [new Euler(), new Quaternion()],
    []
  );
  const parentGroup = useRef();

  const [aspect, setAspect] = useState(1);
  const [groupScale, setGroupScale] = useState(1);

  const scrollYDelta = useRef(useScrollStore.getState().scrollYDelta);
  useEffect(
    () =>
      useScrollStore.subscribe(
        (state) => (scrollYDelta.current = state.scrollYDelta)
      ),
    []
  );

  function setMousePos(e) {
    const x = e.clientX;
    const y = e.clientY;

    NDCMousePos.current.x = (x / window.innerWidth) * 2 - 1;
    NDCMousePos.current.y = (1 - y / window.innerHeight) * 2 - 1;
  }

  // constructor
  useEffect(() => {
    document.addEventListener(
      "mousemove",
      (e) => {
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchmove",
      (e) => {
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchstart",
      (e) => {
        mouseDown.current = true;
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchend",
      (e) => {
        mouseDown.current = false;
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );

    document.addEventListener(
      "touchcancel",
      (e) => {
        if (
          typeof e.touches[0] === "undefined" &&
          typeof e.changedTouches[0] === "undefined"
        ) {
          return;
        } else {
          e = e.touches[0] || e.changedTouches[0];
        }
        setMousePos(e);
      },
      false
    );
  }, []);

  useEffect(() => {
    setAspect(size.width / size.height);
  }, [size]);

  useEffect(() => {
    if (!isMobile) {
      setGroupScale(Math.min(aspect * 0.7, 1.0));
    }
  }, [aspect]);

  const sceneRotationSpeed = isMobile ? 0.01 : 0.004;
  const sceneRotationAmountX = isMobile ? 0.1 : 0.03;
  const sceneRotationAmountY = isMobile ? 0.1 : 0.03;

  useFrame(() => {
    // rotate mesh on mouse move
    if (parentGroup.current) {
      rEuler.set(
        clamp(
          scrollYDelta.current *
            Math.PI *
            0.005 *
            (-NDCMousePos.current.y * Math.PI) *
            sceneRotationAmountY,
          -0.5,
          0.5
        ),
        NDCMousePos.current.x * Math.PI * sceneRotationAmountX,
        scrollYDelta.current * Math.PI * 0.000003
      );
      parentGroup.current.quaternion.slerp(
        rQuaternion.setFromEuler(rEuler),
        sceneRotationSpeed
      );
    }
  });

  return (
    <group ref={parentGroup} scale={[groupScale, groupScale, 1]}>
      {children}
    </group>
  );
}
