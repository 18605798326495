import { Svg, SvgProps } from "./Svg";

export function CloseSmall(props: SvgProps) {
  return (
    <Svg
      width={8}
      height={8}
      data-icon
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.625 7.375 L7.375 0.625 M7.375 7.375 L0.625 0.625"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
    </Svg>
  );
}

export function CloseMedium(props: SvgProps) {
  return (
    <Svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      data-icon
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8925 0.3025C12.5025 -0.0874998 11.8725 -0.0874998 11.4825 0.3025L6.5925 5.1825L1.7025 0.2925C1.3125 -0.0975 0.6825 -0.0975 0.2925 0.2925C-0.0975 0.6825 -0.0975 1.3125 0.2925 1.7025L5.1825 6.5925L0.2925 11.4825C-0.0975 11.8725 -0.0975 12.5025 0.2925 12.8925C0.6825 13.2825 1.3125 13.2825 1.7025 12.8925L6.5925 8.0025L11.4825 12.8925C11.8725 13.2825 12.5025 13.2825 12.8925 12.8925C13.2825 12.5025 13.2825 11.8725 12.8925 11.4825L8.0025 6.5925L12.8925 1.7025C13.2725 1.3225 13.2725 0.6825 12.8925 0.3025Z"
        fill="currentColor"
      />
    </Svg>
  );
}

function Close(props: SvgProps) {
  return (
    <Svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.619 6.381a.875.875 0 010 1.238l-14 14A.875.875 0 016.38 20.38l14-14a.875.875 0 011.238 0z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.5}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.381 6.381a.875.875 0 011.238 0l14 14a.875.875 0 11-1.238 1.238l-14-14a.875.875 0 010-1.238z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </Svg>
  );
}

export default Close;
