import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import Heading from "../../components/heading/Heading";
import Text from "../../components/text/Text";
import { useLoginPrompt } from "../../context/LoginPromptContext";
import { useNotificationCount } from "../../context/NotificationCountContext";
import { useViewProfilePrompt } from "../../context/ViewProfilePromptContext";
import useResponsiveMenu from "../../hooks/useResponsiveMenu";
import { useSharedState } from "../../hooks/useSharedState";
import { pxToRem } from "@input-output-hk/px-to-rem";
import {
  forTabletLandscapeUp,
  forTabletPortraitUp
} from "../../tokens/media-queries";
import zIndex from "../../tokens/z-index";
import { VisuallyHidden } from "../a11y/VisuallyHidden";
import { Avatar } from "../avatar/Avatar";
import CloseIcon from "../icons/Close";
import LogoIcon from "../icons/Logo";
import MenuIcon from "../icons/Menu";
import ProfileIcon from "../icons/Profile";
import { Logo } from "../Logo";
import Navigation from "../navigation/Navigation";
import NavigationMobile from "../navigation/NavigationMobile";
import { NotificationAlert } from "../page-partials/ProfileMenu";
import RoadmapFiltersButton from "../roadmap/RoadmapFiltersButton";
import AutoComplete from "../search/AutoComplete";

const ResponsiveParent = styled.div`
  --responsive-nav-bg-color: rgba(255, 255, 255);
  --responsive-nav-transparent-bg-color: rgba(255, 255, 255, 0);
  --responsive-nav-gap: ${pxToRem(20)};
  --responsive-nav-padding-top: var(--spacing-default);
  --responsive-nav-margin-bottom: ${pxToRem(0)};
  --responsive-nav-max-width: var(--page-max-width);

  --responsive-content-gutter: ${pxToRem(100)};
  --responsive-content-padding-top: ${pxToRem(60)};
  --responsive-content-inner-width: 100%;

  @media ${forTabletPortraitUp} {
    --responsive-content-inner-width: ${pxToRem(780)};
    --responsive-content-gutter: ${pxToRem(130)};
  }

  @media ${forTabletLandscapeUp} {
    --responsive-content-padding-top: ${pxToRem(90)};
  }

  --responsive-nav-button-border-radius: ${pxToRem(5)};
  --responsive-nav-button-color: var(--theme-color-primary);
  --responsive-nav-button-bg-color: var(--responsive-nav-transparent-bg-color);
`;

const ResponsiveCloseButton = styled.button`
  color: var(--theme-color-primary);
  background-color: var(--responsive-nav-button-bg-color);
  border-radius: var(--responsive-nav-button-border-radius);
  position: absolute;
  right: var(--spacing-large);
  top: ${pxToRem(15)};
  border: 0;
  cursor: pointer;
  touch-action: manipulation;
  padding: 0;
`;

const MobileNavigationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: var(--responsive-nav-gap);
  margin-left: auto; /* Align right */

  & > * {
    margin-left: ${pxToRem(10)};
  }

  @media ${forTabletLandscapeUp} {
    display: none;
  }
`;
const mobileNavButtonCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  padding: 0;

  color: var(--responsive-nav-button-color);
  cursor: pointer;
  touch-action: manipulation;
`;

const ResponsiveNavSideMenuButton = styled.button`
  ${mobileNavButtonCss};
  [data-icon] {
    transform: scaleX(-1);
  }
`;

const ResponsiveNavMobileProfileButton = styled.button`
  ${mobileNavButtonCss};
  position: relative;
`;

const ResponsiveSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--responsive-nav-bg-color);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.navigation};
  opacity: 1;
  width: 100%;
  padding: var(--spacing-large);
  transition: transform 0.35s cubic-bezier(0.23, 1, 0.32, 1);
  overflow-y: auto;

  &[aria-hidden] {
    transform: translateX(100%);
  }

  @media ${forTabletLandscapeUp} {
    display: none;

    &[data-show-on-desktop="true"] {
      display: initial;
      max-width: ${pxToRem(400)};
    }
  }
`;

const ResponsiveNavWrapper = styled.div`
  background: var(--responsive-nav-bg-color);
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  width: 100%;
  z-index: ${zIndex.navigation};
`;

const ResponsiveNavBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: none;
  visibility: visible;

  [aria-hidden] ~ & {
    opacity: 0;
    visibility: hidden;
  }

  @media ${forTabletPortraitUp} {
    display: none;
  }
`;

const ResponsiveNavInner = styled.div`
  height: ${pxToRem(60)};
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: auto;
  max-width: var(--responsive-nav-max-width);

  @media ${forTabletLandscapeUp} {
    height: ${pxToRem(90)};
  }
`;

export const ResponsiveContent = styled.main.attrs({ "data-main": true })`
  padding-top: var(--responsive-content-padding-top);
`;

const LoginHeading = styled(Heading)`
  margin: 0;
  line-height: 1.25;
`;

const RoadmapNavContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: var(--responsive-nav-gap);
  & > ${ResponsiveNavMobileProfileButton} {
    margin-right: var(--spacing-default);
  }

  @media ${forTabletPortraitUp} {
    & > ${ResponsiveNavMobileProfileButton} {
      margin: 0 var(--spacing-large);
    }
  }
`;

type Props = {};
export const Responsive: React.FC<Props> = (props) => {
  return <ResponsiveParent>{props.children}</ResponsiveParent>;
};

const PrimaryLogoSideMenuContainer = styled.a`
  display: block;
  padding: ${pxToRem(30)} 0;
  & > svg {
    max-width: 100%;
  }
`;

const PrimaryLogoContainer = styled.a`
  [data-mobile-logo] {
    display: block;
  }
  [data-desktop-logo] {
    display: none;
  }
  display: block;
  text-align: center;
  padding: 0 ${pxToRem(20)};

  @media ${forTabletLandscapeUp} {
    padding: var(--spacing-default);

    [data-mobile-logo] {
      display: none;
    }
    [data-desktop-logo] {
      display: block;
    }
  }
`;
const SearchInputContainer = styled.div`
  &:empty {
    display: none;
  }
  flex-basis: 100%;
  @media ${forTabletLandscapeUp} {
    max-width: ${pxToRem(380)};
    margin-left: var(--spacing-large);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.navigation};
  background-color: rgba(0, 0, 0, 0.25);
`;

const MobileNotificationAlert = styled(NotificationAlert)`
  position: absolute;
  width: ${pxToRem(10)};
  height: ${pxToRem(10)};
  top: 0;
  left: 0;
`;

export const ResponsiveNav: React.FC<{
  shouldShowSearch?: boolean;
  showRoadmapMenu?: boolean;
}> = ({ shouldShowSearch = true, showRoadmapMenu = false }) => {
  const { asPath } = useRouter();
  const { showMenu, setShowMenu, ariaHidden, menuElementRef } =
    useResponsiveMenu("nav");
  const [pageSearchInView] = useSharedState("page-search-input-in-view", true);
  const { showPrompt } = useLoginPrompt();
  const { showViewProfilePrompt } = useViewProfilePrompt();
  const { user, isLoading } = useUser();
  const avatar = user?.picture;

  useEffect(() => {
    setShowMenu(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  const { notificationCount, refreshNotificationCount } =
    useNotificationCount();

  useEffect(() => {
    const controller = new AbortController();
    refreshNotificationCount(controller.signal);

    const intervalId = setInterval(() => {
      refreshNotificationCount(controller.signal);
    }, 60_000); // refresh once per minute

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, [refreshNotificationCount]);

  const collapsedNav = (
    <>
      <ResponsiveNavMobileProfileButton
        onClick={() => {
          if (!isLoading && !user) {
            showPrompt(
              <>
                <LoginHeading level="3">Join the conversation</LoginHeading>
                <Text level="2">
                  Login to comment
                  <br />
                  and much more
                </Text>
              </>
            );
          } else {
            showViewProfilePrompt(
              <>
                {avatar && <Avatar level="000" src={avatar} alt={"profile"} />}
              </>
            );
          }
        }}
        data-primary-profile-nav-button
        aria-controls="navigation"
        aria-label="Sign in or Sign up"
      >
        <MobileNotificationAlert notificationCount={notificationCount} />
        <ProfileIcon />
      </ResponsiveNavMobileProfileButton>
      <ResponsiveNavSideMenuButton
        onClick={() => setShowMenu(!showMenu)}
        data-primary-menu-nav-button
        aria-expanded={showMenu}
        aria-controls="navigation"
        aria-label="Move navigation options"
      >
        <MenuIcon />
      </ResponsiveNavSideMenuButton>
    </>
  );

  return (
    <>
      <ResponsiveNavWrapper id="navigation">
        <ResponsiveNavInner>
          <Link href="/" passHref>
            <PrimaryLogoContainer>
              <VisuallyHidden>Home</VisuallyHidden>
              <LogoIcon data-mobile-logo />
              <Logo data-desktop-logo height="48" />
            </PrimaryLogoContainer>
          </Link>
          <SearchInputContainer>
            {!showRoadmapMenu && (shouldShowSearch || !pageSearchInView) && (
              <AutoComplete
                appearance="small"
                searchPlaceholderText="Search here"
              />
            )}
          </SearchInputContainer>
          {showRoadmapMenu ? (
            <>
              <RoadmapFiltersButton />
              <RoadmapNavContainer>{collapsedNav}</RoadmapNavContainer>
            </>
          ) : (
            <>
              <Navigation />
              <MobileNavigationButtonsContainer>
                {collapsedNav}
              </MobileNavigationButtonsContainer>
            </>
          )}
        </ResponsiveNavInner>
      </ResponsiveNavWrapper>
      {showMenu && showRoadmapMenu && <Overlay />}
      <ResponsiveSideMenu
        aria-hidden={ariaHidden}
        ref={menuElementRef}
        data-show-on-desktop={showRoadmapMenu}
      >
        <Link href="/" passHref>
          <PrimaryLogoSideMenuContainer>
            <Logo />
          </PrimaryLogoSideMenuContainer>
        </Link>
        <ResponsiveCloseButton
          onClick={() => setShowMenu(!showMenu)}
          aria-expanded={showMenu}
          aria-controls="navigation"
        >
          <CloseIcon />
        </ResponsiveCloseButton>
        <NavigationMobile />
      </ResponsiveSideMenu>
      <ResponsiveNavBackdrop />
    </>
  );
};
