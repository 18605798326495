import { css } from "styled-components";

export const fauxUnderlineCss = css`
  /* Faux underline */
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: var(--nav-underline-width);
    max-width: 100%;
    height: var(--nav-underline-thickness);
    border-radius: var(--nav-underline-border-radius);
    background-color: currentColor;
  }
`;
