import * as React from "react";

export function Infographics(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 3.2A9 9 0 1020.8 14a1 1 0 00-1-1H13a2 2 0 01-2-2V4a.902.902 0 00-1-.8z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3.5A9 9 0 0120.5 9H16a1 1 0 01-1-1V3.5z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const InfographicsSmall = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={6} stroke="currentColor" strokeWidth={2} />
    <path
      d="M8 2v6m0 0H13m-5 0L6 10 4 13"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);
