import { pxToRem } from "@input-output-hk/px-to-rem";

// 3rd party
import { A11yAnnouncer } from "@react-three/a11y";
import { Canvas } from "@react-three/fiber";
import { gsap } from "gsap";
import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { isMobile, isTablet } from "react-device-detect";
import styled from "styled-components";

// elements
import YearList from "./components/YearList";
import FlowMap from "./FlowMap";
import FlowMapVizualiser from "./FlowMapVisualiser";
import ParentGroup from "./ParentGroup";
import ParticleBG from "./ParticleBG";
import ParticleSpiral from "./ParticleSpiral";
import Post from "./Post";

// store
import { useDetectGPU } from "@react-three/drei";
import { useMeasureStore } from "./state/measure";
import { RoadmapContext } from "./RoadmapContext";

// const bgColor = '#1f4ae4'
const bgColor = "#1533a1";

export default function App({
  roadMapItems = [],
  visibleItemIds = [],
  onClickItem,
  isCondensedModeEnabled,
  filterBy
}) {
  const gpuTier = useDetectGPU();
  const { setRoadmapPresentationMode } = useContext(RoadmapContext);

  const [cameraPos, setCameraPos] = useState([0, 0, 800]);
  const containerHeight = useMeasureStore((state) => state.containerHeight);

  const scrollCount = useRef(0);

  function hidePresentation() {
    setHideText(true);
    setRoadmapPresentationMode(false);
  }

  // constructor
  useEffect(() => {
    window.history.scrollRestoration = "manual";

    window.addEventListener("scroll", () => {
      scrollCount.current++;

      if (scrollCount.current > 20) {
        hidePresentation();
      }
    });
  }, []);

  // animation
  const introWrapperRef = useRef();
  const introH2Ref = useRef();
  const introPRef = useRef();
  const introButtonRef = useRef();

  const [hideText, setHideText] = useState(false);
  const animParams = useRef({
    h2Left: 0,
    h2Opacity: 1,
    h2Blur: 0,

    pLeft: 0,
    pOpacity: 1,
    pBlur: 0,

    buttonLeft: 0,
    buttonOpacity: 1,
    buttonBlur: 0,

    wrapperDisplay: "flex",
    wrapperBlur: pxToRem(8)
  });

  const tl = useRef(
    gsap.timeline({
      onUpdate: () => {
        if (introH2Ref.current) {
          introH2Ref.current.style.left = animParams.current.h2Left;
          introH2Ref.current.style.opacity = animParams.current.h2Opacity;
          introH2Ref.current.style.filter = `blur(${animParams.current.h2Blur}px)`;
        }
        if (introPRef.current) {
          introPRef.current.style.left = animParams.current.pLeft;
          introPRef.current.style.opacity = animParams.current.pOpacity;
          introPRef.current.style.filter = `blur(${animParams.current.pBlur}px)`;
        }
        if (introButtonRef.current) {
          introButtonRef.current.style.left = animParams.current.buttonLeft;
          introButtonRef.current.style.opacity =
            animParams.current.buttonOpacity;
          introButtonRef.current.style.filter = `blur(${animParams.current.buttonBlur}px)`;
        }
        if (introWrapperRef.current) {
          introWrapperRef.current.style.display =
            animParams.current.wrapperDisplay;
          introWrapperRef.current.style.backdropFilter = `blur(${animParams.current.wrapperBlur}px)`;
        }
      }
    })
  );

  useEffect(() => {
    if (hideText === true) {
      tl.current.to(
        animParams.current,
        {
          duration: 1,
          h2Left: "-200px",
          h2Opacity: 0,
          h2Blur: 5
        },
        0
      );

      tl.current.to(
        animParams.current,
        {
          duration: 1,
          pLeft: "-200px",
          pOpacity: 0,
          pBlur: 5
        },
        0.1
      );

      tl.current.to(
        animParams.current,
        {
          duration: 1,
          buttonLeft: "-200px",
          buttonOpacity: 0,
          buttonBlur: 5
        },
        0.15
      );

      tl.current.to(
        animParams.current,
        {
          duration: 1,
          wrapperBlur: 0
        },
        0.16
      );

      tl.current.to(animParams.current, {
        duration: 0,
        wrapperDisplay: "none"
      });
    }
  }, [hideText]);

  useEffect(() => {
    if (isMobile) {
      setCameraPos([0, 0, 3200]);
    }
  }, [isMobile]);

  return (
    <div>
      <IntroWrapper ref={introWrapperRef}>
        <Intro>
          <IntroInner>
            <IntroH2 ref={introH2Ref}>Explore the Cardano Universe</IntroH2>
            <IntroP ref={introPRef}>
              The Cardano blockchain platform is made up of a wealth of
              technology, products, features, and a thriving community of
              developers, users, and enterprises. Discover the Cardano Universe,
              from its building blocks, to its overarching development themes,
              and everything in between.
            </IntroP>
            <ExploreButton
              ref={introButtonRef}
              onClick={() => {
                hidePresentation();
              }}
            >
              Explore now
            </ExploreButton>
          </IntroInner>
        </Intro>
      </IntroWrapper>

      <Canvas
        linear
        gl={{ antialias: false }}
        style={{ position: "fixed", left: 0, top: 0 }}
        dpr={isMobile || isTablet || gpuTier.tier > 2 ? [1, 2] : [1, 1]}
        camera={{ fov: 20, position: cameraPos, near: 100, far: 4000 }}
      >
        <Suspense fallback={null}>
          <color attach="background" args={[bgColor]} />
          <ParentGroup>
            <group position={isMobile ? [0, -600, 0] : [0, -300, 0]}>
              <ParticleSpiral
                filterBy={filterBy}
                roadMapItems={roadMapItems}
                visibleItemIds={visibleItemIds}
                onClickItem={onClickItem}
                isCondensedModeEnabled={isCondensedModeEnabled}
                disableInteraction={!hideText}
              />
              <ParticleBG />
            </group>
          </ParentGroup>
          {!isMobile && !isTablet && gpuTier.tier > 2 && (
            <>
              <FlowMap />
              <FlowMapVizualiser />
              <Post />
            </>
          )}
        </Suspense>
      </Canvas>
      {!isCondensedModeEnabled && <YearList hideText={hideText} />}
      <MainContent height={containerHeight} />
      <A11yAnnouncer />
    </div>
  );
}

const IntroWrapper = styled.div`
  will-change: display, backdrop-filter;
  backdrop-filter: blur(${pxToRem(8)});
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
`;

const Intro = styled.div`
  color: #fff;
  width: 100%;
  margin: auto;
  max-width: var(--responsive-nav-max-width);
`;

const IntroInner = styled.div`
  width: 50%;
  padding: 0 0 0 ${pxToRem(15)};

  @media (max-width: 768px) {
    padding: 0 ${pxToRem(25)};
    width: 100%;
  }
`;

const IntroH2 = styled.h2`
  will-change: transform, opacity, filter, left;
  position: relative;
  font-size: ${pxToRem(60)};
  line-height: ${pxToRem(60)};
  margin: 0 0 0.3em;

  @media (max-width: 768px) {
    font-size: ${pxToRem(30)};
    line-height: ${pxToRem(40)};
  }
`;

const IntroP = styled.p`
  will-change: transform, opacity, filter, left;
  position: relative;
  width: 78%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExploreButton = styled.button`
  //will-change: transform, opacity, filter, left;
  position: relative;
  border: ${pxToRem(2)} solid #fff;
  border-radius: ${pxToRem(50)};
  background: none;
  color: #fff;
  padding: 0 ${pxToRem(30)};
  min-height: ${pxToRem(50)};
  font-family: "Chivo";
  font-size: 1rem;
  margin-top: ${pxToRem(15)};
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;

  &:hover {
    cursor: pointer;
    box-shadow: inset 10.5em 0 0 0 #fff;
    color: #002891;
  }
`;

const MainContent = styled.div`
  padding-top: ${pxToRem(500)};
  height: ${(props) => props.height}px;
`;
