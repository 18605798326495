import { disabledRoutes } from "../../utils/feature-flags";

//Not using this until we have some more content in place

export const EXPLORE_ITEMS = [
  {
    label: "Network",
    href: "/network"
  },
  {
    label: "Ecosystem",
    href: "/ecosystem"
  },
  {
    label: "Key Milestones",
    href: "/key-milestones"
  },
  {
    label: "Twitter Wall",
    href: "/twitter-wall"
  },
  {
    label: "Community Channels",
    href: "/community"
  }
].filter(({ href }) => !disabledRoutes.has(href));
