import React from "react";
import styled from "styled-components";
import { usePortal } from "../../hooks/usePortal";
import { forTabletPortraitUp } from "../../tokens/media-queries";
import AlertDialogBox from "../alert-dialog/AlertDialog";
import { Column } from "../Stack";
import LoginButton from "./LoginButton";

type Props = {
  isOpen?: boolean;
  onRequestClose(): void;
};

const StyledAlertBox = styled(AlertDialogBox)`
  text-align: center;
  width: auto;
  padding: var(--spacing-xlarge);
  @media ${forTabletPortraitUp} {
    padding: var(--spacing-xlarge) var(--spacing-xxlarge);
  }
`;

const LoginPrompt: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  children = "You must be logged in"
}) => {
  const portal = usePortal();
  return portal(
    <StyledAlertBox isOpen={isOpen} onRequestClose={onRequestClose}>
      <Column level="2">
        {children}
        <LoginButton />
      </Column>
    </StyledAlertBox>
  );
};

export default LoginPrompt;
