import { extend } from "@react-three/fiber";
import { MeshBasicMaterial, ShaderLib } from "three";

export default class TextMaterial extends MeshBasicMaterial {
  constructor(cfg) {
    super(cfg);
    this.type = "ShaderMaterial";
    this.uniforms = ShaderLib.basic.uniforms;
    this.vertexShader = ShaderLib.basic.vertexShader;
    this.fragmentShader = ShaderLib.basic.fragmentShader;
  }
}

extend({ TextMaterial });
