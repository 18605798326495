import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: var(--alert-overlay-opacity);
  background: var(--alert-overlay-background-color);
  animation: ${fadeIn} 0.25s cubic-bezier(0.23, 1, 0.32, 1);
`;

export default Overlay;
