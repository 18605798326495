import * as React from "react";

function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      data-icon
      width={18}
      height={10}
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.837.587a.937.937 0 011.326 0L9 7.424 15.837.587a.937.937 0 111.326 1.326l-7.5 7.5a.937.937 0 01-1.326 0l-7.5-7.5a.937.937 0 010-1.326z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ArrowDown;
