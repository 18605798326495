import { Svg } from "./Svg";

export function AvatarPlaceholderIcon() {
  return (
    <Svg
      version="1.0"
      id="Layer_3"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 595.28 841.89"
    >
      <g>
        <path
          fill="#6E6E6E"
          d="M0,0v145.9v4.1v461v85.47v25.57l10.25-15.6l4.98-7.58l0.17,0c1.6-2.71,3.27-5.37,5.02-7.97
		c10.03-14.91,22.52-28.01,36.65-39.05c12.25-9.58,25.74-17.62,39.93-23.96c0.46-0.22,0.93-0.43,1.4-0.65
		c10.4-4.81,23.76-10.12,37.89-16.24h-0.04c7.03-3.04,14.25-6.29,21.39-9.77c23.19-11.34,45.5-25.25,57.61-43.09
		c7.2-10.66,10.78-22.73,8.77-36.55c-3.34-14.43-12.09-25.77-18.17-38.58c-4.52-8.09-7.08-17.06-10.19-25.69
		c-3.67-10.19-8.09-19.89-17.43-27.07c-11.78-9.94-17.42-23.19-15-38.57c0.07-3.59,0.64-6.21,1.43-8.23
		c1.24-3.19,3.04-4.87,4.34-6.49c0.83-1.03,1.45-2.04,1.61-3.38c0.15-1.34-0.17-3.01-1.22-5.4c-1.62-5.13-3.05-10.3-4.4-15.5
		c-3.38-13-6.29-26.12-10.61-38.96c-0.15-0.57-0.26-1.14-0.35-1.72c-0.64-4.1,0.02-8.32-1.13-12.37c-0.19-0.66-0.42-1.32-0.72-1.97
		c-0.17-0.37-0.29-0.79-0.37-1.23c-0.06-0.33-0.09-0.68-0.1-1.03c-0.03-1.06,0.14-2.16,0.41-3.1c2.91-25.96,11.37-51.22,24.46-73.97
		c0.42-0.77,0.86-1.53,1.31-2.27c7.04-11.87,16.84-20.59,28.11-27.19c10.15-6.11,21.45-10.54,32.8-13.64
		c5.15-1.39,10.35-2.6,15.58-3.65c4.64-1.03,9.26-1.97,13.8-2.86c4.95-0.49,10.08-0.88,15.33-1.15c15.79-1.19,31.68-0.78,47.34,1.36
		c17,2.05,33.75,6.01,48.75,12.73c11.16,4.27,20.6,10.67,28.56,18.56c7.3,6.81,13.52,14.9,18.3,24.46c0.4,0.87,0.79,1.75,1.19,2.63
		c4.36,8.44,7.86,17.45,10.63,26.67c0.45,1.36,0.89,2.72,1.32,4.09c4.25,10.98,6.04,21.5,6.22,31.77c0.36,5.47,0.3,10.98-0.25,16.52
		c-1.47,8.6-3.67,17.06-6.06,25.48c-4.01,15.07-8.98,30.28-12.04,46.38c3.46-1.05,5.47,1.31,6.66,4.86c0,0.01,0.01,0.02,0.01,0.02
		c0.04,0.13,0.08,0.26,0.12,0.39c0.05,0.16,0.1,0.32,0.15,0.49c0,0.01,0,0.01,0,0.02c0.33,1.14,0.58,2.38,0.78,3.65
		c0.02,0.13,0.04,0.26,0.06,0.39c0.03,0.17,0.05,0.34,0.08,0.52c0.58,4.23,0.63,8.62,0.8,10.88c-0.01,0.41-0.03,0.8-0.05,1.2
		c-0.17,3.97-0.8,7.45-1.81,10.54c-2.65,8.19-7.79,13.77-13.43,19.5c-4.46,4.54-9.22,9.17-13.28,15.2
		c-0.06,0.09-0.12,0.17-0.18,0.26c-2.5,6.52-4.77,13.09-7.18,19.58c-3.75,10.09-7.83,20-13.62,29.28
		c-5.81,8.53-9.37,16.59-11.06,24.2c-0.02,0.09-0.04,0.19-0.06,0.28c-9.28,43,41.48,71.67,86.28,91.49h0
		c14.72,6.51,28.8,12.08,39.9,16.88c19.33,8.41,38.62,19.82,54.66,34.4c6.52,5.93,12.5,12.37,17.72,19.36
		c0.53,0.71,1.05,1.43,1.56,2.16c0.18,0.26,0.38,0.51,0.56,0.77c4.1,5.85,7.67,12.06,10.59,18.63l0.13,0l0.08,0.19l8.37,19.03v-27.6
		V611V150v-3.57V0H0z"
        />
        <path
          fill="#EDEDEE"
          d="M586.91,703.4l-0.08-0.19l-0.13,0c-2.92-6.58-6.49-12.79-10.59-18.63
		c-0.18-0.26-0.38-0.51-0.56-0.77c-0.52-0.72-1.03-1.45-1.56-2.16c-5.22-6.99-11.2-13.43-17.72-19.36
		c-16.04-14.59-35.33-26-54.66-34.4c-11.1-4.81-25.18-10.37-39.9-16.88c-44.81-19.83-95.56-48.49-86.28-91.49
		c0.02-0.09,0.04-0.18,0.06-0.28c1.69-7.61,5.25-15.66,11.06-24.2c5.79-9.28,9.87-19.19,13.62-29.28
		c2.41-6.49,4.69-13.06,7.18-19.58c0.06-0.09,0.12-0.18,0.18-0.26c4.08-6.07,8.83-10.68,13.28-15.2c5.64-5.73,10.78-11.3,13.43-19.5
		c1-3.09,1.63-6.56,1.81-10.54c0.02-0.4,0.04-0.79,0.05-1.2c-0.17-2.26-0.22-6.66-0.8-10.88c-0.02-0.17-0.05-0.35-0.08-0.52
		c-0.02-0.13-0.04-0.26-0.06-0.39c-0.2-1.27-0.45-2.51-0.78-3.65c0-0.01,0-0.01,0-0.02c-0.05-0.17-0.1-0.33-0.15-0.49
		c-0.04-0.13-0.08-0.26-0.12-0.39c0-0.01-0.01-0.02-0.01-0.02c-1.19-3.55-3.2-5.92-6.66-4.86c3.06-16.1,8.04-31.31,12.04-46.38
		c3.7-13.92,6.55-27.72,6.31-42c-0.18-10.27-1.97-20.79-6.22-31.77c-0.18-0.46-0.33-0.91-0.52-1.37c-0.26-0.91-0.53-1.82-0.8-2.72
		c-2.77-9.22-6.27-18.23-10.63-26.67c-5.2-10.06-11.62-19.3-19.49-27.09c-7.96-7.89-17.4-14.29-28.56-18.56
		c-0.93-0.36-1.87-0.7-2.83-1.03c-14.87-5.71-30.28-9.56-45.92-11.7c-15.67-2.14-31.56-2.55-47.34-1.36
		c-9.77,0.73-19.51,2.08-29.12,4.01c-5.23,1.05-10.43,2.26-15.58,3.65c-11.35,3.11-22.65,7.54-32.8,13.64
		c-11.31,6.81-21.2,15.7-28.11,27.19c-0.45,0.75-0.89,1.5-1.31,2.27c-13.08,22.75-21.55,48.01-24.46,73.97
		c-0.27,0.94-0.44,2.05-0.41,3.1c0.01,0.35,0.04,0.7,0.1,1.03c0.08,0.44,0.2,0.86,0.37,1.23c0.3,0.65,0.54,1.31,0.72,1.97
		c1.16,4.05,0.49,8.27,1.13,12.37c0.09,0.58,0.2,1.15,0.35,1.72c4.33,12.84,7.23,25.96,10.61,38.96c1.35,5.19,2.77,10.37,4.4,15.5
		c1.06,2.38,1.38,4.05,1.22,5.4c-0.16,1.34-0.78,2.34-1.61,3.38c-1.3,1.62-3.1,3.32-4.34,6.49c-0.79,2.02-1.36,4.64-1.43,8.23
		c-2.42,15.37,3.22,28.63,15,38.57c9.33,7.18,13.76,16.88,17.43,27.07c3.11,8.63,5.66,17.6,10.19,25.69
		c6.08,12.82,14.83,24.15,18.17,38.59c2.06,13.79-1.53,25.87-8.77,36.54c-12.11,17.84-34.42,31.75-57.61,43.09
		c-7.12,3.48-14.33,6.73-21.35,9.77c-14.13,6.12-27.49,11.43-37.89,16.24c-0.47,0.22-0.94,0.44-1.4,0.65
		c-14.19,6.33-27.67,14.37-39.93,23.96c-14.13,11.05-26.62,24.14-36.65,39.05c-1.75,2.6-3.42,5.26-5.02,7.97l-0.17,0l-4.98,7.58
		L0,722.04V761v80.89h595.28V761v-38.57L586.91,703.4z"
        />
      </g>
    </Svg>
  );
}
