import { css } from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const selectVariables = css`
  --select-text-color: var(--default-text-color);
  --select-text-font: var(--default-text-font-family);

  --select-list-background-color: ${colors.secondary.tyneFog};
  --select-list-color-hover: var(--default-text-color);
  --select-list-border-color: ${colors.secondary.lilacCloud};

  --select-popover-outline-color: ${colors.secondary.lilacCloud};

  --select-arrow-color: ${colors.secondary.ashGrey};

  --select-primary-background-color: ${colors.primary.white};
  --select-primary-text-size: ${typography.size.default};
  --select-box-primary-padding: ${pxToRem(13)};
  --select-secondary-background-color: ${colors.secondary.londonFog};
  --select-secondary-text-size: ${typography.size.small};
  --select-box-secondary-padding: ${pxToRem(1, 13)};

  --select-size-default: auto;
  --select-size-fit: 100%;
`;
