import type { SearchResponse } from "@algolia/client-search";
import { SearchIndex } from "algoliasearch/lite";
import { useMemo } from "react";
import { initSearchIndex } from "../data/search/init";

type SearchFn<T> = (
  ...args: Parameters<SearchIndex["search"]>
) => Promise<SearchResponse<T>>;

export function useSearchIndex<T = any>(indexName: string) {
  const searchFn = useMemo<SearchFn<T>>(() => {
    const index = initSearchIndex(indexName);
    return (...args) => index.search<T>(...args);
  }, [indexName]);

  return searchFn;
}
