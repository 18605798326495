import * as React from "react";
import { SVGProps } from "react";

const Email = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-icon
    width={24}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 6.868c0-1.26-.9-2.29-2-2.29H4c-1.1 0-2 1.03-2 2.29v13.736c0 1.26.9 2.29 2 2.29h16c1.1 0 2-1.03 2-2.29V6.868Zm-2 0-8 5.712-8-5.712h16Zm0 13.736H4V9.157l8 5.724 8-5.724v11.447Z"
      fill="currentColor"
    />
  </svg>
);

export default Email;
