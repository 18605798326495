import { Svg, SvgProps } from "./Svg";

export function CodeSmall(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={15}
      height={15}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="currentColor"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.565.835l-3.776 17.92M16.995 4.288l5.513 5.568-5.513 5.513M6.348 15.369L.835 9.801l5.513-5.513" />
      </g>
    </Svg>
  );
}

function Code(props: SvgProps) {
  return (
    <Svg
      data-icon
      width={24}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.565.835l-3.776 17.92M16.995 4.288l5.513 5.568-5.513 5.513M6.348 15.369L.835 9.801l5.513-5.513" />
      </g>
    </Svg>
  );
}

export default Code;
