import { css } from "styled-components";
import colors from "../../tokens/colors";

export const searchIconVariables = css`
  --search-input-background: ${colors.primary.white};
  --search-input-border: ${colors.secondary.tyneFog};
  --search-input-color: ${colors.primary.cardanoBlue};
  --search-input-placeholder: ${colors.secondary.ashGrey};
  --search-input-shadow: ${colors.shadow.gray};
`;
