import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { getSize, Props } from "./Avatar";
import { AvatarPlaceholderIcon } from "../icons/AvatarPlaceholder";
import colors from "../../tokens/colors";

const StyledAvatarPlaceholder = styled.span.attrs<Props>((props) => ({
  width: getSize(props.level),
  height: getSize(props.level)
}))<Props>`
  border-radius: 50%;
  width: ${(props) => pxToRem(getSize(props.level))};
  height: ${(props) => pxToRem(getSize(props.level))};
  background: ${colors.secondary.lilacCloud};
  overflow: hidden;

  svg {
    position: relative;
    top: ${pxToRem(-3)};
  }
`;
export const AvatarPlaceholder = (props) => (
  <StyledAvatarPlaceholder {...props} data-testid="avatar-placeholder">
    <AvatarPlaceholderIcon />
  </StyledAvatarPlaceholder>
);
