import { pxToRem } from "@input-output-hk/px-to-rem";

export const spacing = {
  // T-Shirt sizes
  xs: pxToRem(4),
  s: pxToRem(5),
  m: pxToRem(10),
  l: pxToRem(20),
  xl: pxToRem(40),
  xxl: pxToRem(80),
  xxxl: pxToRem(160),
  // Level sizes (like Heading, h1, h2, h3)
  level00: pxToRem(100),
  level0: pxToRem(60),
  level1: pxToRem(40),
  level2: pxToRem(20),
  level3: pxToRem(10)
} as const;
