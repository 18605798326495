import styled from "styled-components";

const CloseButton = styled.button`
  position: absolute;
  top: var(--spacing-default);
  right: var(--spacing-default);
  border: 0;
  background: 0;
  color: var(--theme-color-primary);
  cursor: pointer;
`;

export default CloseButton;
