import { css } from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletLandscapeUp } from "../../tokens/media-queries";

export const buttonVariables = css`
  --button-primary-text: var(--default-bg-color);
  --button-primary-text-hover: var(--default-bg-color);
  --button-primary-bg-color: var(--theme-color-primary);
  --button-primary-bg-color-hover: var(--theme-color-secondary);
  --button-primary-border-color: var(--theme-color-primary);
  --button-primary-border-color-hover: var(--theme-color-secondary);

  --button-secondary-text: var(--theme-color-primary);
  --button-secondary-text-hover: var(--theme-color-secondary);
  --button-secondary-bg-color: var(--default-bg-color);
  --button-secondary-bg-color-hover: var(--default-bg-color);
  --button-secondary-border-color: var(--theme-color-primary);
  --button-secondary-border-color-hover: var(--theme-color-secondary);

  --button-link-bg-color: transparent;
  --button-link-bg-color-hover: transparent;
  --button-link-color: var(--default-text-color);
  --button-link-color-hover: var(--theme-color-primary);

  --button-banner-bg-color: transparent;
  --button-banner-bg-color-hover: transparent;
  --button-banner-color-hover: var(--theme-color-primary);
  --button-banner-border-color: var(--banner-border-color);

  --button-focus-color: var(--default-focus-color);

  --button-default-width: ${pxToRem(211)};
  --button-medium-width: ${pxToRem(120)};
  --button-large-width: 100%;
  --button-small-width: unset;
  --button-default-font-size: ${pxToRem(16)};
  --button-huge-font-size: ${pxToRem(18)};
  --button-large-font-size: ${pxToRem(14)};
  --button-small-font-size: ${pxToRem(12)};

  --button-huge-width: ${pxToRem(250)};
  --button-huge-height: ${pxToRem(68)};

  --button-huge-responsive-width: ${pxToRem(175)};
  --button-huge-responsive-height: ${pxToRem(50)};
  --button-huge-responsive-font-size: ${pxToRem(16)};
  @media ${forTabletLandscapeUp} {
    --button-huge-responsive-font-size: ${pxToRem(18)};
    --button-huge-responsive-width: ${pxToRem(250)};
    --button-huge-responsive-height: ${pxToRem(68)};
  }
`;
