import styled from "styled-components";

export const ProfileElement = styled.div`
  position: relative;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  display: flex;
`;
