import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import ViewProfilePrompt from "../components/profile-view-prompt/ViewProfilePrompt";

type ViewProfilePromptContextValue = {
  shouldShowPrompt: boolean;
  showViewProfilePrompt(content?: ReactNode): void;
};

const initialState = () => ({ show: false, content: null });

const ViewProfilePromptContext = createContext<ViewProfilePromptContextValue>({
  shouldShowPrompt: false,
  showViewProfilePrompt() {}
});

const ViewProfilePromptProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<{
    show: boolean;
    content: ReactNode | null;
  }>(initialState);

  const onRequestClose = useCallback(() => {
    setState({
      show: false,
      content: null
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      shouldShowPrompt: state.show,
      showViewProfilePrompt(content: ReactNode | false) {
        if (content === false) {
          setState({
            show: false,
            content: null
          });
        } else {
          setState({
            show: true,
            content
          });
        }
      }
    };
  }, [state.show]);

  return (
    <ViewProfilePromptContext.Provider value={contextValue}>
      <>
        {children}
        {
          <ViewProfilePrompt
            isOpen={state.show}
            onRequestClose={onRequestClose}
          >
            {state.content}
          </ViewProfilePrompt>
        }
      </>
    </ViewProfilePromptContext.Provider>
  );
};

export default ViewProfilePromptProvider;

export const useViewProfilePrompt = () => useContext(ViewProfilePromptContext);
