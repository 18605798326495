import Button from "../../buttons/Button";

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const HowToContributeButton: React.FC<Props> = ({
  children = "Learn how to contribute",
  ...buttonProps
}) => {
  //TODO redirect to signup link once the flow for login/signup is decided
  const href = "/contribute-help";
  return (
    <Button
      {...buttonProps}
      as="a"
      href={href}
      data-learn-how-to-contribute-button
    >
      {children}
    </Button>
  );
};

export default HowToContributeButton;
