import { extend } from "@react-three/fiber";
import { AdditiveBlending, DoubleSide, ShaderMaterial } from "three";

export default class MilestonePlaneMaterial extends ShaderMaterial {
  constructor() {
    super({
      blending: AdditiveBlending,
      side: DoubleSide,
      transparent: true,
      uniforms: {
        uRadius: { value: 0.4 },
        uTime: { value: 0 },
        uTexture: { type: "t", value: null },
        uSpriteIndex: { value: 0 }
      },
      vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
      `,

      fragmentShader: `
      varying vec2 vUv;
      uniform float uRadius;
      uniform float uTime;
      uniform sampler2D uTexture;
      uniform float uSpriteIndex;

      const vec3 bgColor = vec3(35.0/255.0, 83.0/255.0, 255.0/255.0) * 0.2;

      void main() {

        vec2 spriteSheetSize = vec2(273.0);
        vec2 spriteSize = vec2(91.0);
        
        // credit to https://gist.github.com/passiomatic/4e108740db98eea5d2e6873267271f37 for sprite sheet implementation
        float w = spriteSheetSize.x;
        float h = spriteSheetSize.y;

        // Normalize sprite size (0.0-1.0)
        float dx = spriteSize.x / w;
        float dy = spriteSize.y / h;

        // Figure out number of tile cols of sprite sheet
        float cols = w / spriteSize.x;

        // From linear index to row/col pair
        float col = mod(uSpriteIndex, cols);
        float row = floor(uSpriteIndex / cols);

        vec2 scaledUV = vUv * 2.0 - 0.5;

        // Finally to UV texture coordinates
        vec2 newUV = vec2(dx * scaledUV.x + col * dx, 1.0 - dy - row * dy + dy * scaledUV.y);

        vec4 spriteTex = vec4(0.0);

        if (
          scaledUV.y < 1.0 && 
          scaledUV.y > 0.0 && 
          scaledUV.x < 1.0 &&
          scaledUV.x > 0.0
          ) {
          spriteTex = texture2D(uTexture, newUV);
        }

        // circle 1
        vec3 sprite = vec3(0.0);
        float d = distance(vUv, vec2(0.5));

        float disc = (1.0 - smoothstep(0.4, 0.41, abs(d)));
        sprite += disc;
        sprite *= bgColor;

        sprite += 1.0 - smoothstep(0.0, 0.014, abs(uRadius - d));
        // // sprite *= 0.8;
        
        // circle2
        vec2 offset = vec2(sin(uTime), sin(uTime + 1.0)) * 0.054;

        d = distance(vUv + offset, vec2(0.5));
        sprite += 1.0 - smoothstep(0.0, 0.014, abs(uRadius - d));

        gl_FragColor = vec4( sprite, 1.0 );

        gl_FragColor.rgb += spriteTex.rgb * spriteTex.a;

      }`
    });
  }
}

extend({ MilestonePlaneMaterial });
