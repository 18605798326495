/**
 * Doc Ref: https://reach.tech/menu-button/
 */
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuPopover
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import styled, { css } from "styled-components";
import { filterUndefined } from "../../data/db/filter-undefined";
import zIndex from "../../tokens/z-index";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { Maybe } from "../../utils/types";

const StyledMenuButton = styled(MenuButton)`
  text-align: left;
  white-space: nowrap;
  background: transparent;
  border: 0;
  padding: 0;
  color: inherit;
  font-family: var(--menu-button-font-family);
  font-size: var(--menu-button-font-size);
  cursor: pointer;
  &:hover {
    color: var(--menu-button-text-color-hover);
  }
  &[data-reach-menu-button][aria-expanded="true"] {
    color: var(--menu-button-text-active-color);
  }
`;

export const StyledMenuPopover = styled(MenuPopover)`
  background: var(--menu-list-background-color);
  border-radius: ${pxToRem(10)};
  border: 0;
  padding-top: ${pxToRem(8)};
  padding-bottom: ${pxToRem(8)};
  box-shadow: 0.0764rem 0.25rem 1.1458rem rgba(26, 52, 185, 0.15);
  margin-top: var(--spacing-default);
  position: absolute;
  z-index: ${zIndex.navigation};
  font-family: var(--menu-button-font-family);
  font-size: var(--menu-button-font-size);
`;

const menuItemStyle = css`
  color: var(--menu-link-text-color);
  &:hover {
    color: var(--menu-link-text-color-hover);
    background: var(--menu-link-background-color-hover);
  }
  display: flex;
  align-items: center;
  padding: var(--spacing-default) var(--spacing-large);
  text-decoration: none;
  & > * {
    flex-shrink: 0;
  }
  & > * + * {
    margin-left: ${pxToRem(8)};
  }
`;

export const StyledMenuLink = styled(MenuLink)`
  ${menuItemStyle}
`;
export const StyledMenuItem = styled(MenuItem)`
  ${menuItemStyle}
`;

export type PopMenuProps = {
  labelBy?: string;
  name?: string;
  options: (
    | {
        href: string;
        children: React.ReactNode;
      }
    | {
        onSelect(): void;
        children: React.ReactNode;
      }
    | null
  )[];
};

export const isValidOption = (
  opt: Maybe<PopMenuProps["options"][number]>
): opt is PopMenuProps["options"][number] => {
  return Boolean(opt);
};

/**
 * TODO When `options.href` use `@next/link`
 */
const PopMenuButton: React.FC<PopMenuProps> = ({
  name,
  children,
  labelBy,
  options
}) => {
  return (
    <>
      <span id="menu-button-label" className="hide-on-screen">
        {labelBy ? labelBy : "Display list of links on click"}
      </span>
      <Menu>
        <StyledMenuButton aria-describedby="menu-button-label">
          {children || name}
        </StyledMenuButton>
        <StyledMenuPopover>
          {options.filter(filterUndefined).map((option, i) =>
            "href" in option ? (
              <StyledMenuLink key={i} as="a" href={option.href}>
                {option.children}
              </StyledMenuLink>
            ) : (
              <StyledMenuItem key={i} onSelect={option.onSelect}>
                {option.children}
              </StyledMenuItem>
            )
          )}
        </StyledMenuPopover>
      </Menu>
    </>
  );
};

export default PopMenuButton;
