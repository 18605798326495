import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { pxToRem } from "@input-output-hk/px-to-rem";
import { forTabletPortraitDown } from "../../tokens/media-queries";
// import { trueOrUndef } from "../../utils/dom-attribute-helpers";
// import CaretIcon from "../icons/Caret";
import { ProfileMenu } from "../page-partials/ProfileMenu";
import { EXPLORE_ITEMS } from "./constants";
import { fauxUnderlineCss } from "./fauxUnderlineCss";
// import SecondaryNavigation, {
//   NavItem as SecondaryNavItem
// } from "./SecondaryNavigation";

/* Disabling dropdown nav and only showing community
 * channels until we have more content in place
 */

const NavElement = styled.nav`
  --nav-item-font-size: ${pxToRem(16)};
  color: var(--nav-color);
  display: flex;
  align-items: center;
  & > * {
    margin-left: var(--spacing-large);
  }

  [data-login-button] {
    font-size: var(--nav-item-font-size, inherit);
    min-width: auto;
    padding: 0 ${pxToRem(15)};
  }
`;

// const NavSecondary = styled(SecondaryNavigation)`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   right: 0;
//   border-bottom: var(--card-stroke);
//   background-color: var(--default-bg-color);
//   justify-content: center;

//   will-change: transform, opacity;
//   cursor: pointer;
//   transition: visibility 0.25s ease-out, transform 0.25s ease-out,
//     opacity 0.25s ease-out;
//   opacity: 1;
//   visibility: visible;

//   &[aria-hidden="true"] {
//     opacity: 0;
//     visibility: hidden;
//   }
// `;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const NavItemAnchor = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: var(--spacing-xsmall);
  font-size: var(--nav-item-font-size);
  font-weight: 600;
  cursor: pointer;
  &[data-active] {
    ${fauxUnderlineCss}
  }
  &:focus,
  &:hover,
  &[data-active]:focus {
    color: var(--theme-color-secondary);
  }

  &[data-community-channels-nav-item] {
    min-width: ${pxToRem(170)};
  }
`;

// const NavToggleIcon = styled.span`
//   margin-left: auto;
//   display: inline-block;
//   margin-left: ${pxToRem(5)};
//   & [data-icon] {
//     transform-origin: 50% 50%;
//     transition: transform 0.3s ease-out;
//     [aria-expanded="true"] & {
//       transform: rotate(180deg);
//     }
//   }
// `;

const FullNavContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  @media ${forTabletPortraitDown} {
    display: none;
  }
`;

export const NavItem: React.FC<
  React.ComponentProps<typeof NavItemAnchor> & {
    href: string;
    isIndex?: boolean;
  }
> = ({ href, isIndex, ...props }) => {
  const router = useRouter();
  const isActive = isIndex
    ? router.asPath.split("?")[0] === href
    : router.asPath.startsWith(href);
  return (
    <Link href={href} passHref>
      <NavItemAnchor {...props} data-active={isActive || undefined} />
    </Link>
  );
};

const Navigation: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const timeoutRef = useRef<number>(null);
  // const handleMouseEnter = () => {
  //   clearTimeout(timeoutRef.current);
  //   setIsOpen(true);
  // };
  // const handleMouseLeave = () => {
  //   if (isOpen) {
  //     timeoutRef.current = window.setTimeout(() => {
  //       setIsOpen(false);
  //     }, 1000);
  //   }
  // };
  const { asPath: path } = useRouter();
  const exploreActive = EXPLORE_ITEMS.some(({ href }) => {
    return path.startsWith(href);
  });
  useEffect(() => {
    /**
     * Check and close the secondary nav
     * if we have navigated away from an
     * explore page
     */
    if (isOpen && !exploreActive) {
      setIsOpen(false);
    } else if (!isOpen && exploreActive) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return (
    <FullNavContainer>
      <NavContainer>
        <NavElement>
          <NavItem href="/content" isIndex>
            Content
          </NavItem>
          <NavItem href="/community" data-community-channels-nav-item isIndex>
            Community Channels
          </NavItem>
          {/* <NavItemAnchor
            tabIndex={0}
            onClick={() => setIsOpen(!isOpen)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsOpen(!isOpen);
              }
            }}
            data-active={trueOrUndef(isOpen)}
            aria-haspopup="menu"
            aria-controls="secondary-nav"
            aria-expanded={isOpen}
          >
            Explore
            <NavToggleIcon>
              <CaretIcon />
            </NavToggleIcon>
          </NavItemAnchor> */}
          <ProfileMenu />
        </NavElement>
        {/* <NavSecondary
          id="secondary-nav"
          aria-hidden={trueOrUndef(!isOpen)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {EXPLORE_ITEMS.map(({ href, label }) => {
            const active = path.startsWith(href);
            return (
              <SecondaryNavItem
                data-nav-item
                key={href}
                href={href}
                active={active}
              >
                {label}
              </SecondaryNavItem>
            );
          })}
        </NavSecondary> */}
      </NavContainer>
    </FullNavContainer>
  );
};

export default Navigation;
