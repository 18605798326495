import { useRouter } from "next/router";
import React from "react";
import SecondaryNavigation, {
  NavItem
} from "../navigation/SecondaryNavigation";
import { CATEGORY_LABELS } from "../navigation/shared";
import { getPathWithoutQueryString } from "../../utils/path-utils";

export const MEDIA_CATEGORY_ROUTES = {
  content: "All",
  ...CATEGORY_LABELS
} as const;

type Props = {};
const ContentNavigation: React.FC<Props> = () => {
  const router = useRouter();
  const currPath = getPathWithoutQueryString(router.asPath);
  return (
    <SecondaryNavigation>
      {Object.entries(MEDIA_CATEGORY_ROUTES)
        .filter(([path]) => {
          // Temp disable podcast for now
          return path !== "podcast";
        })
        .map(([path, label]) => {
          const href =
            label === CATEGORY_LABELS.glossary
              ? `/${path}?sort=alphabetical`
              : `/${path}`;
          const active =
            label === CATEGORY_LABELS.glossary
              ? currPath === href.split("?")[0]
              : currPath === href;
          return (
            <NavItem key={href} href={href} active={active} shallow>
              {label}
            </NavItem>
          );
        })}
    </SecondaryNavigation>
  );
};

export default ContentNavigation;
