import * as React from "react";
import { Svg, SvgProps } from "./Svg";

function Twitter({
  strokeWidth = 2,
  ...props
}: SvgProps & { strokeWidth?: number }) {
  return (
    <Svg
      data-icon
      width={24}
      height={24}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 2.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S10.977 4.323 11 6v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 00.497-3.753C19.18 5.773 20.692 3.25 21 2.009v.001z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default Twitter;
