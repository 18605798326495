import { createContext, FC, PropsWithChildren } from "react";

export type ConfigContext = {
  POSTHOG_API_KEY: string | undefined;
  POSTHOG_PROJECT_ID: string | undefined;
};

export const ConfigContext = createContext<ConfigContext>({
  POSTHOG_API_KEY: undefined,
  POSTHOG_PROJECT_ID: undefined
});

export const ConfigProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => (
  <ConfigContext.Provider
    value={{
      POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY,
      POSTHOG_PROJECT_ID: process.env.NEXT_PUBLIC_POSTHOG_PROJECT_ID
    }}
  >
    {children}
  </ConfigContext.Provider>
);
