import { useEffect, useState } from "react";
import { usePortal } from "../../hooks/usePortal";
import AlertDialogBox from "../alert-dialog/AlertDialog";
import Heading from "../heading/Heading";
import Text from "../text/Text";

type Props = {
  errorCode?: string;
};

const LoginErrorHandler: React.FC<Props> = ({ errorCode }) => {
  const portal = usePortal();
  const [error, setError] = useState<Record<
    "title" | "message",
    string
  > | null>(null);

  const isOpen = !!error;

  useEffect(() => {
    if (!errorCode) {
      return;
    }

    // error codes taken from
    // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors#log-in
    // + some of our own error codes
    switch (errorCode) {
      case "access_denied":
        setError({
          message: "It looks like you cancelled the log in process",
          title: "Login cancelled"
        });
        break;
      case "unverified_primary_email":
        setError({
          message:
            "Check your inbox to confirm your email address and then login again",
          title: "Verify your email address"
        });
        break;
      default:
        setError({
          message: "We encountered an error trying to log you in.",
          title: "Unexpected login error"
        });
        break;
    }
  }, [errorCode]);

  return errorCode && error
    ? portal(
        <AlertDialogBox isOpen={isOpen} onRequestClose={() => setError(null)}>
          <Heading level="2">{error.title}</Heading>
          <Text>{error.message}</Text>
        </AlertDialogBox>
      )
    : null;
};

export default LoginErrorHandler;
