import { css } from "styled-components";
import colors from "../../tokens/colors";

export const paginationVariables = css`
  --pagination-btn-font-size: var(--default-font-size);

  --pagination-btn-font-color: var(--theme-color-primary);
  --pagination-btn-background-color: transparent;

  --pagination-selected-btn-font-color: var(--default-text-color);
  --pagination-selected-btn-background-color: ${colors.secondary.londonFog};
`;
