import { useUser } from "@auth0/nextjs-auth0";
import { createContext, useCallback, useContext, useState } from "react";

export type NotificationCountContextValue = {
  notificationCount: number;
  refreshNotificationCount: (signal?: AbortSignal) => Promise<void>;
};

const NotificationCountContext = createContext<NotificationCountContextValue>({
  notificationCount: 0,
  refreshNotificationCount: async () => {}
});

const NotificationCountProvider: React.FC = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const { user } = useUser();

  const refreshNotificationCount = useCallback(
    async (signal?: AbortSignal) => {
      if (!user) {
        setNotificationCount(0);
        return;
      }

      try {
        const response = await fetch(`/api/notification?count=true`, {
          method: "GET",
          signal
        });
        const body = await response.json();
        setNotificationCount(body.data);
      } catch (err) {
        if (err instanceof DOMException && err.name === "AbortError") {
          // no-op
        } else {
          console.warn(err);
        }
      }
    },
    [user]
  );

  return (
    <NotificationCountContext.Provider
      value={{ notificationCount, refreshNotificationCount }}
    >
      {children}
    </NotificationCountContext.Provider>
  );
};

export default NotificationCountProvider;

export const useNotificationCount = () => useContext(NotificationCountContext);
