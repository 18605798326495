import { ArticleCategory } from "@input-output-hk/essential-cardano-models";
import Articles, { ArticlesSmall } from "../icons/Articles";
import Code, { CodeSmall } from "../icons/Code";
import FAQ, { FAQSmall } from "../icons/FAQ";
import Glossary, { GlossarySmall } from "../icons/Glossary";
import Other, { OtherSmall } from "../icons/Other";
import Mic, { MicSmall } from "../icons/Mic";
import Video, { VideoSmall } from "../icons/Video";
import { Infographics, InfographicsSmall } from "../icons/Infographics";

type KnownCategoryMetadata<T> = Record<ArticleCategory["type"], T> &
  Partial<Record<string, T>>;

export const CATEGORY_LABELS: KnownCategoryMetadata<string> = {
  content: "All",
  article: "Articles",
  video: "Videos",
  faq: "FAQs",
  infographic: "Infographics",
  podcast: "Podcasts",
  developer: "Developer Resources",
  glossary: "Glossary",
  "development-update": "Development Updates",
  other: "Other"
};

const knownCategories = new Set(Object.keys(CATEGORY_LABELS));

export const CATEGORY_SINGULAR_LABEL: KnownCategoryMetadata<string> = {
  article: "article",
  video: "video",
  faq: "FAQ",
  podcast: "podcast",
  developer: "developer resource",
  glossary: "glossary",
  other: "other resource",
  "development-update": "development update",
  infographic: "infographic"
};

export const CATEGORY_ICONS: KnownCategoryMetadata<React.FC> = {
  article: Articles,
  "development-update": Articles,
  video: Video,
  faq: FAQ,
  podcast: Mic,
  infographic: Infographics,
  developer: Code,
  other: Other,
  glossary: Glossary
};

export const CATEGORY_ICONS_SMALL: KnownCategoryMetadata<React.FC> = {
  article: ArticlesSmall,
  video: VideoSmall,
  faq: FAQSmall,
  podcast: MicSmall,
  developer: CodeSmall,
  other: OtherSmall,
  glossary: GlossarySmall,
  "development-update": ArticlesSmall,
  infographic: InfographicsSmall
};

export const isKnownCategory = (
  maybeCategory?: string
): maybeCategory is ArticleCategory["type"] => {
  return !!maybeCategory && knownCategories.has(maybeCategory);
};

export function getPathLabel(path: string): string | undefined {
  const [maybeCategory] = path
    .split("?")[0]
    .split("/")
    .map((part) => part.trim())
    .filter((part) => !!part);
  return CATEGORY_LABELS[maybeCategory];
}
