import { Svg, SvgProps } from "./Svg";

function YouTube({
  strokeWidth = 2,
  ...props
}: SvgProps & { strokeWidth?: number }) {
  return (
    <Svg
      width={46}
      height={36}
      viewBox="0 0 46 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="M43.712 8.533A10.866 10.866 0 0042 3.833c-1.142-1.271-2.694-1.996-4.318-2.016-6.06-.484-15.076-.484-15.076-.484s-9.09 0-15.151.484c-1.625.018-3.178.743-4.319 2.016-.918 1.385-1.507 3.001-1.712 4.7A79.881 79.881 0 001 16.2v3.6c.018 2.562.16 5.121.424 7.667.205 1.699.794 3.315 1.712 4.7 1.31 1.289 3 2.011 4.758 2.033 3.44.367 14.636.467 14.636.467s9.091 0 15.152-.484c1.624-.02 3.176-.745 4.318-2.016.919-1.385 1.507-3.001 1.712-4.7.264-2.546.406-5.105.424-7.667v-3.6a79.891 79.891 0 00-.424-7.667v0zM18.152 24.15V10.817l11.636 6.666L18.15 24.15z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default YouTube;
