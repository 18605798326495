import { pxToRem } from "@input-output-hk/px-to-rem";

const typography = {
  font: {
    default: `"chivo",'Roboto', sans-serif`
  },
  size: {
    large: pxToRem(20),
    default: "${pxToRem(16)}",
    h1: "2rem",
    h2: "1.625rem",
    h3: "1.375rem",
    h4: "1.125rem",
    small: "0.875rem",
    tiny: "0.75rem"
  },
  lineHeight: {
    large: pxToRem(28),
    default: "1.75rem",
    h1: "3rem",
    h2: "2.25rem",
    h3: "2.125rem",
    h4: "1.75rem",
    medium: "1.5rem",
    small: "1.375rem",
    tiny: "1.375rem"
  },
  weight: {
    light: "300",
    default: "400",
    bold: "700"
  }
};
export default typography;
