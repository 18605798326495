import styled from "styled-components";

type iProps = {
  level?: "1" | "2" | "3" | "4" | "5" | "6";
  weight?: string;
  isTight?: boolean;
};

const Heading = styled.div.attrs<iProps>(({ level }) => ({
  as: "h" + level
}))<iProps>`
  font-size: var(${(props) => `--heading-level${props.level}-size`});
  line-height: var(${(props) => `--heading-level${props.level}-lineHeight`});
  margin: ${(props) => props.isTight && 0};
  font-weight: ${(props) => `${props.level === "6" ? "bold" : props.weight}`};
`;

Heading.defaultProps = {
  level: "1"
};

export default Heading;
