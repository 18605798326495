import { css } from "styled-components";
import typography from "../../tokens/typography";

export const headingVariables = css`
  --heading-level1-size: ${typography.size.h1};
  --heading-level2-size: ${typography.size.h2};
  --heading-level3-size: ${typography.size.h3};
  --heading-level4-size: ${typography.size.h4};
  --heading-level5-size: ${typography.size.h4};
  --heading-level6-size: ${typography.size.h4};

  --heading-level1-lineHeight: ${typography.lineHeight.h1};
  --heading-level2-lineHeight: ${typography.lineHeight.h2};
  --heading-level3-lineHeight: ${typography.lineHeight.h3};
  --heading-level4-lineHeight: ${typography.lineHeight.h4};
  --heading-level5-lineHeight: ${typography.lineHeight.h4};
  --heading-level6-lineHeight: ${typography.lineHeight.h4};
`;
