import { useFrame } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import {
  EffectComposer,
  Bloom,
  Noise,
  Vignette
  // ChromaticAberration
} from "@react-three/postprocessing";

// store
// import { useScrollStore } from "./state/scroll";

const Post = () => {
  // const CARef = useRef();

  // const scrollYDelta = useRef(useScrollStore.getState().scrollYDelta);
  // useEffect(
  //   () =>
  //     useScrollStore.subscribe(
  //       (state) => (scrollYDelta.current = state.scrollYDelta)
  //     ),
  //   []
  // );

  // useFrame(() => {
  //   if (CARef.current) {
  //     CARef.current.uniforms.get("offset").value = [
  //       0.0004,
  //       (1.0 - scrollYDelta.current) * 0.00001
  //     ];
  //   }
  // });

  return (
    <EffectComposer>
      {/* <Bloom intensity={0.5} luminanceThreshold={0.0} luminanceSmoothing={0.9} height={800} kernelSize={2} /> */}
      <Noise opacity={0.1} />
      <Vignette eskil={false} offset={0.15} darkness={0.6} />
      {/* <ChromaticAberration
        ref={CARef}
        // blendFunction={BlendFunction.NORMAL} // blend mode
        offset={[0.0004, 0.0004]}
      /> */}
    </EffectComposer>
  );
};

export default Post;
