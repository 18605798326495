import { Filters } from "../data/db/types";

export function toArray(thing: string | string[] | undefined): string[] {
  if (!thing) {
    return [];
  }
  return Array.isArray(thing) ? thing : [thing];
}

export function toStrippedArray(thing: string | string[] | undefined) {
  return toArray(thing).filter((v) => !!v);
}

/**
 * Parses the page parameter (1 based) from a query string
 */
export function getPageFromQuery(qs: string | string[] | undefined): number {
  const pageAsInt = typeof qs === "string" ? parseInt(qs, 10) : NaN;
  if (Number.isSafeInteger(pageAsInt) && pageAsInt > 0) {
    return pageAsInt;
  }

  return 1;
}

export function getFiltersFromQuery(query: {
  [key: string]: string | string[] | undefined;
}) {
  const { tags, category, page } = query;
  const filters: Filters = {};
  if (tags) {
    filters.tags = toArray(tags);
  }
  if (category) {
    filters.category = toArray(category);
  }

  filters.page = getPageFromQuery(page);

  return filters;
}

export function hasTagFilter(filters?: Filters) {
  return filters && filters.tags && filters.tags.length > 0;
}

const notNull = (thing: any) => thing != null;

export function stringifyFilters(filters: Filters) {
  const params = new URLSearchParams();
  Object.entries(filters).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      v.forEach((_v) => {
        params.append(k, _v);
      });
    } else if (notNull(v)) {
      params.set(k, String(v));
    }
  });
  return params.toString();
}
