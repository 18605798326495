import { Svg, SvgProps } from "./Svg";

function LogoIcon({
  color = "var(--logo-color, #022275)",
  ...props
}: SvgProps & { color?: string }) {
  return (
    <Svg
      data-icon
      width={35}
      height={35}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2764_66918)">
        <path
          d="M10.3398 17.3682C10.3026 18.0433 10.5335 18.7057 10.9819 19.2103C11.4303 19.7148 12.0598 20.0203 12.7322 20.0598C12.7815 20.0626 12.8304 20.0642 12.8786 20.0642C13.5398 20.0636 14.1747 19.8042 14.6485 19.3413C15.1223 18.8783 15.3977 18.2482 15.4162 17.5848C15.4348 16.9213 15.195 16.2767 14.7478 15.7878C14.3007 15.2989 13.6813 15.0042 13.0212 14.9663C11.6137 14.8838 10.4193 15.97 10.3398 17.3682Z"
          fill={color}
        />
        <path
          d="M0.867812 16.7162C0.705651 16.7069 0.544401 16.7462 0.404452 16.8289C0.264502 16.9117 0.152139 17.0342 0.0815686 17.1811C0.0109986 17.3279 -0.0146086 17.4925 0.00798513 17.6539C0.0305788 17.8154 0.100359 17.9665 0.208501 18.0882C0.316644 18.2098 0.458293 18.2965 0.615536 18.3374C0.77278 18.3782 0.938557 18.3714 1.09191 18.3176C1.24525 18.2639 1.37929 18.1657 1.47706 18.0355C1.57483 17.9053 1.63194 17.7489 1.64118 17.5862C1.64739 17.478 1.6323 17.3698 1.59679 17.2675C1.56127 17.1652 1.50601 17.071 1.43418 16.9902C1.36235 16.9094 1.27536 16.8436 1.17817 16.7966C1.08099 16.7496 0.975525 16.7222 0.867812 16.7162V16.7162Z"
          fill={color}
        />
        <path
          d="M10.1795 2.56802C10.3246 2.49463 10.4447 2.37969 10.5248 2.23774C10.6048 2.09579 10.6411 1.9332 10.6291 1.77053C10.6171 1.60787 10.5573 1.45244 10.4573 1.32389C10.3572 1.19534 10.2215 1.09946 10.0672 1.04835C9.91289 0.997253 9.74696 0.993232 9.59038 1.0368C9.4338 1.08037 9.2936 1.16957 9.18752 1.29312C9.08144 1.41667 9.01424 1.56903 8.99442 1.73092C8.9746 1.89281 9.00304 2.05697 9.07616 2.20263C9.1742 2.39796 9.34552 2.54618 9.55243 2.6147C9.75934 2.68323 9.98489 2.66644 10.1795 2.56802V2.56802Z"
          fill={color}
        />
        <path
          d="M12.7583 7.08035C12.9826 6.96698 13.1683 6.78941 13.292 6.57008C13.4157 6.35075 13.4718 6.09952 13.4533 5.84816C13.4347 5.59681 13.3424 5.35662 13.1878 5.15796C13.0333 4.95931 12.8236 4.81113 12.5851 4.73215C12.3467 4.65317 12.0903 4.64694 11.8483 4.71426C11.6064 4.78157 11.3897 4.91941 11.2258 5.11032C11.0619 5.30124 10.9581 5.53667 10.9274 5.78683C10.8968 6.037 10.9408 6.29066 11.0538 6.51573C11.1288 6.66516 11.2323 6.7983 11.3586 6.90755C11.4848 7.0168 11.6313 7.10002 11.7896 7.15245C11.9478 7.20488 12.1149 7.2255 12.2811 7.21312C12.4473 7.20075 12.6095 7.15564 12.7583 7.08035V7.08035Z"
          fill={color}
        />
        <path
          d="M4.08138 10.1834C4.25425 10.2972 4.45685 10.357 4.66356 10.3552C4.87028 10.3534 5.07182 10.2901 5.24271 10.1734C5.4136 10.0566 5.54615 9.89157 5.62361 9.69917C5.70106 9.50677 5.71994 9.29563 5.67786 9.09246C5.63578 8.88929 5.53463 8.70321 5.38719 8.55774C5.23976 8.41228 5.05266 8.31398 4.84957 8.27526C4.64648 8.23654 4.43651 8.25915 4.24622 8.34023C4.05592 8.4213 3.89386 8.5572 3.78051 8.73074C3.62852 8.96344 3.57484 9.2472 3.63126 9.51963C3.68769 9.79205 3.8496 10.0308 4.08138 10.1834V10.1834Z"
          fill={color}
        />
        <path
          d="M5.6267 16.2569C5.37598 16.2427 5.12668 16.3033 4.91032 16.4313C4.69396 16.5593 4.52026 16.7488 4.41118 16.9758C4.30211 17.2029 4.26256 17.4573 4.29753 17.707C4.33251 17.9566 4.44044 18.1902 4.60768 18.3782C4.77492 18.5663 4.99395 18.7003 5.23708 18.7634C5.48021 18.8265 5.73651 18.8158 5.97358 18.7327C6.21065 18.6495 6.41783 18.4977 6.56893 18.2963C6.72003 18.095 6.80826 17.8532 6.82246 17.6015C6.84149 17.264 6.72621 16.9328 6.50196 16.6806C6.27772 16.4285 5.96288 16.2761 5.6267 16.2569V16.2569Z"
          fill={color}
        />
        <path
          d="M4.22008 24.8191C4.03538 24.9124 3.88239 25.0586 3.78044 25.2392C3.6785 25.4197 3.63218 25.6266 3.64735 25.8336C3.66252 26.0406 3.73849 26.2384 3.86566 26.4021C3.99283 26.5658 4.16549 26.6879 4.3618 26.753C4.55811 26.8182 4.76926 26.8235 4.96855 26.7682C5.16784 26.7129 5.34632 26.5995 5.48142 26.4424C5.61652 26.2852 5.70217 26.0914 5.72755 25.8854C5.75293 25.6794 5.71689 25.4705 5.624 25.2851C5.56233 25.1619 5.47709 25.0522 5.37315 24.9621C5.26921 24.872 5.1486 24.8033 5.01823 24.7601C4.88785 24.7168 4.75025 24.6997 4.6133 24.7099C4.47635 24.72 4.34273 24.7571 4.22008 24.8191V24.8191Z"
          fill={color}
        />
        <path
          d="M9.15146 13.6249C9.39844 13.7874 9.68789 13.8727 9.98319 13.8701C10.2785 13.8675 10.5664 13.7771 10.8105 13.6103C11.0546 13.4434 11.244 13.2077 11.3546 12.9328C11.4653 12.658 11.4923 12.3563 11.4322 12.0661C11.3721 11.7758 11.2276 11.51 11.017 11.3022C10.8064 11.0943 10.5392 10.9538 10.249 10.8985C9.95893 10.8431 9.65897 10.8753 9.38708 10.991C9.1152 11.1068 8.88361 11.3008 8.72159 11.5487C8.61389 11.7133 8.53958 11.8976 8.50289 12.0911C8.46621 12.2846 8.46787 12.4834 8.5078 12.6762C8.54772 12.8691 8.62512 13.0521 8.73556 13.2149C8.84601 13.3776 8.98733 13.517 9.15146 13.6249Z"
          fill={color}
        />
        <path
          d="M24.7997 6.97877C25.0096 7.1171 25.2557 7.18982 25.5069 7.18774C25.758 7.18565 26.0029 7.10884 26.2105 6.96703C26.4182 6.82523 26.5792 6.62479 26.6734 6.39107C26.7675 6.15735 26.7905 5.90085 26.7395 5.65401C26.6884 5.40717 26.5655 5.18109 26.3865 5.00434C26.2074 4.8276 25.9801 4.70814 25.7334 4.66108C25.4866 4.61401 25.2315 4.64146 25.0004 4.73994C24.7692 4.83842 24.5723 5.00352 24.4346 5.21435C24.2499 5.49692 24.1846 5.84157 24.2531 6.17246C24.3216 6.50335 24.5182 6.79339 24.7997 6.97877V6.97877Z"
          fill={color}
        />
        <path
          d="M27.4027 2.48138C27.5386 2.57074 27.6978 2.61764 27.8603 2.61615C28.0228 2.61466 28.1811 2.56485 28.3154 2.47301C28.4497 2.38117 28.5538 2.25143 28.6146 2.10019C28.6754 1.94895 28.6902 1.78301 28.6571 1.62334C28.6239 1.46367 28.5444 1.31745 28.4285 1.20315C28.3126 1.08886 28.1655 1.01164 28.0059 0.981245C27.8463 0.95085 27.6812 0.96865 27.5317 1.03239C27.3822 1.09614 27.2548 1.20296 27.1657 1.33936C27.1066 1.42996 27.0658 1.53137 27.0456 1.6378C27.0255 1.74423 27.0265 1.85359 27.0485 1.95964C27.0705 2.06569 27.1131 2.16636 27.1739 2.25588C27.2346 2.3454 27.3124 2.42202 27.4027 2.48138V2.48138Z"
          fill={color}
        />
        <path
          d="M24.9982 14.9397C24.3253 14.9012 23.6648 15.1326 23.1619 15.5831C22.659 16.0335 22.3549 16.666 22.3166 17.3415C22.2783 18.017 22.5088 18.6801 22.9576 19.1849C23.4063 19.6897 24.0364 19.995 24.7092 20.0334C24.758 20.0362 24.807 20.0376 24.8554 20.0376C25.5165 20.037 26.1514 19.7777 26.6252 19.3149C27.099 18.852 27.3744 18.222 27.393 17.5586C27.4116 16.8951 27.1719 16.2506 26.7247 15.7617C26.2776 15.2728 25.6583 14.9782 24.9982 14.9402V14.9397Z"
          fill={color}
        />
        <path
          d="M13.5927 13.4485C13.8035 13.8715 14.1278 14.227 14.5291 14.4749C14.9304 14.7228 15.3927 14.8533 15.8638 14.8517C16.2976 14.8515 16.7242 14.7399 17.1029 14.5274C17.4816 14.315 17.7997 14.0087 18.0271 13.6378C18.2545 13.267 18.3836 12.8439 18.402 12.4088C18.4203 11.9737 18.3275 11.5411 18.1322 11.1522C17.9215 10.7294 17.5975 10.374 17.1964 10.126C16.7954 9.87813 16.3334 9.74755 15.8625 9.74904C15.4285 9.74879 15.0016 9.86015 14.6227 10.0725C14.2437 10.2848 13.9253 10.5911 13.6977 10.962C13.4701 11.333 13.341 11.7563 13.3226 12.1915C13.3042 12.6268 13.3972 13.0595 13.5927 13.4485V13.4485Z"
          fill={color}
        />
        <path
          d="M33.512 10.181C33.6967 10.0876 33.8496 9.94136 33.9514 9.76072C34.0532 9.58009 34.0994 9.37319 34.0842 9.1662C34.0689 8.95921 33.9928 8.76142 33.8655 8.59784C33.7382 8.43426 33.5655 8.31224 33.3692 8.24719C33.1728 8.18215 32.9617 8.17702 32.7624 8.23244C32.5632 8.28786 32.3847 8.40135 32.2497 8.55855C32.1147 8.71575 32.0292 8.90961 32.0039 9.11562C31.9787 9.32162 32.0148 9.53052 32.1078 9.71589C32.2327 9.96436 32.4508 10.1529 32.7141 10.2401C32.9773 10.3273 33.2643 10.3061 33.512 10.181V10.181Z"
          fill={color}
        />
        <path
          d="M27.067 10.9928C26.8033 11.1263 26.5849 11.3353 26.4396 11.5933C26.2942 11.8514 26.2283 12.1469 26.2502 12.4426C26.2721 12.7383 26.3809 13.0207 26.5628 13.2543C26.7446 13.4879 26.9914 13.6622 27.2719 13.755C27.5524 13.8478 27.854 13.8551 28.1386 13.7758C28.4231 13.6966 28.6779 13.5344 28.8707 13.3098C29.0635 13.0852 29.1856 12.8082 29.2216 12.514C29.2576 12.2197 29.2058 11.9214 29.0729 11.6566C28.9847 11.4807 28.8628 11.324 28.7142 11.1955C28.5657 11.0669 28.3933 10.969 28.207 10.9074C28.0207 10.8457 27.8241 10.8215 27.6285 10.8362C27.4329 10.8508 27.2421 10.9041 27.067 10.9928V10.9928Z"
          fill={color}
        />
        <path
          d="M18.7714 2.09728C18.9778 2.10904 19.1831 2.05908 19.3613 1.95372C19.5394 1.84837 19.6825 1.69234 19.7723 1.50538C19.8621 1.31843 19.8947 1.10893 19.8659 0.903382C19.8371 0.697837 19.7483 0.505476 19.6106 0.350624C19.4729 0.195772 19.2925 0.0853849 19.0923 0.0334206C18.8922 -0.0185438 18.6811 -0.00975109 18.4859 0.0586868C18.2907 0.127125 18.1201 0.252134 17.9956 0.417907C17.8712 0.58368 17.7985 0.78277 17.7868 0.990003C17.7712 1.26788 17.8661 1.54063 18.0507 1.74827C18.2354 1.95591 18.4946 2.08144 18.7714 2.09728V2.09728Z"
          fill={color}
        />
        <path
          d="M18.7603 8.70057C19.0555 8.71764 19.3492 8.64642 19.6041 8.49593C19.8589 8.34543 20.0636 8.12243 20.1922 7.85513C20.3208 7.58784 20.3675 7.28825 20.3265 6.99429C20.2854 6.70033 20.1584 6.42519 19.9616 6.20369C19.7647 5.9822 19.5068 5.82429 19.2206 5.74996C18.9343 5.67562 18.6325 5.6882 18.3533 5.78609C18.0742 5.88399 17.8302 6.0628 17.6523 6.29992C17.4744 6.53703 17.3706 6.82178 17.3539 7.11815C17.3316 7.51515 17.4672 7.9048 17.7309 8.20151C17.9946 8.49821 18.3649 8.67771 18.7603 8.70057V8.70057Z"
          fill={color}
        />
        <path
          d="M10.6651 24.0068C10.9289 23.8735 11.1473 23.6646 11.2928 23.4066C11.4383 23.1485 11.5044 22.853 11.4826 22.5573C11.4607 22.2616 11.3521 21.9791 11.1703 21.7454C10.9885 21.5117 10.7418 21.3374 10.4613 21.2445C10.1808 21.1515 9.87921 21.1442 9.59458 21.2234C9.30995 21.3025 9.05509 21.4647 8.86225 21.6892C8.6694 21.9138 8.54723 22.1907 8.51117 22.485C8.47511 22.7793 8.5268 23.0777 8.65968 23.3425C8.83814 23.6973 9.14953 23.9665 9.5255 24.091C9.90147 24.2156 10.3113 24.1853 10.6651 24.0068Z"
          fill={color}
        />
        <path
          d="M19.7241 10.8876C19.4143 11.3622 19.2711 11.927 19.317 12.4925C19.3629 13.058 19.5954 13.592 19.9777 14.0099C20.36 14.4279 20.8702 14.7059 21.4276 14.7999C21.985 14.894 22.5578 14.7988 23.0552 14.5293C23.5526 14.2598 23.9463 13.8315 24.1739 13.3121C24.4015 12.7928 24.45 12.212 24.3118 11.6618C24.1736 11.1117 23.8566 10.6235 23.4109 10.2745C22.9652 9.9256 22.4163 9.73586 21.8511 9.73535C21.4291 9.73527 21.0138 9.84054 20.6426 10.0417C20.2713 10.2429 19.9557 10.5336 19.7241 10.8876V10.8876Z"
          fill={color}
        />
        <path
          d="M24.143 21.552C23.9169 21.1013 23.5627 20.7281 23.1253 20.4795C22.6879 20.2309 22.1868 20.1181 21.6855 20.1554C21.1843 20.1927 20.7053 20.3784 20.3091 20.689C19.9129 20.9995 19.6174 21.4211 19.46 21.9003C19.3025 22.3795 19.2901 22.8948 19.4244 23.381C19.5587 23.8673 19.8336 24.3026 20.2144 24.632C20.5952 24.9614 21.0647 25.17 21.5636 25.2315C22.0625 25.293 22.5684 25.2046 23.0172 24.9775C23.3162 24.8279 23.5827 24.6203 23.8014 24.3667C24.02 24.1132 24.1865 23.8187 24.2911 23.5002C24.3958 23.1817 24.4366 22.8456 24.4112 22.5112C24.3857 22.1768 24.2946 21.8508 24.143 21.552Z"
          fill={color}
        />
        <path
          d="M28.5812 21.3745C28.3341 21.2114 28.0444 21.1257 27.7487 21.128C27.453 21.1303 27.1647 21.2206 26.9201 21.3874C26.6756 21.5543 26.4858 21.7902 26.3749 22.0653C26.2639 22.3404 26.2367 22.6424 26.2968 22.9331C26.3568 23.2237 26.5014 23.4899 26.7122 23.6981C26.923 23.9062 27.1905 24.0469 27.481 24.1024C27.7715 24.1579 28.0718 24.1256 28.344 24.0097C28.6163 23.8938 28.8481 23.6994 29.0102 23.4512C29.2275 23.1186 29.3043 22.7131 29.2239 22.3237C29.1434 21.9343 28.9123 21.5929 28.5812 21.3745Z"
          fill={color}
        />
        <path
          d="M33.4435 17.5432C33.4578 17.2916 33.3974 17.0413 33.27 16.8241C33.1425 16.6069 32.9538 16.4325 32.7276 16.3229C32.5015 16.2134 32.248 16.1736 31.9993 16.2087C31.7507 16.2437 31.5179 16.352 31.3306 16.5199C31.1432 16.6877 31.0096 16.9076 30.9467 17.1516C30.8838 17.3957 30.8944 17.6529 30.9772 17.8909C31.06 18.1289 31.2112 18.3369 31.4117 18.4887C31.6123 18.6404 31.8531 18.729 32.1038 18.7433C32.44 18.7624 32.77 18.6468 33.0212 18.4217C33.2724 18.1967 33.4243 17.8807 33.4435 17.5432V17.5432Z"
          fill={color}
        />
        <path
          d="M36.9585 16.6361C36.7963 16.6268 36.6349 16.6659 36.4948 16.7486C36.3547 16.8314 36.2423 16.9539 36.1716 17.1008C36.1009 17.2477 36.0752 17.4124 36.0978 17.5739C36.1204 17.7355 36.1901 17.8867 36.2983 18.0084C36.4065 18.1302 36.5482 18.217 36.7055 18.2578C36.8629 18.2987 37.0287 18.2918 37.1822 18.2381C37.3356 18.1843 37.4697 18.086 37.5675 17.9557C37.6653 17.8255 37.7224 17.669 37.7316 17.5061C37.7438 17.2879 37.6692 17.0737 37.5243 16.9106C37.3793 16.7475 37.1759 16.6488 36.9585 16.6361Z"
          fill={color}
        />
        <path
          d="M33.65 24.8164C33.477 24.7025 33.2744 24.6428 33.0676 24.6446C32.8608 24.6464 32.6592 24.7097 32.4883 24.8265C32.3174 24.9434 32.1848 25.1084 32.1074 25.3009C32.0299 25.4934 32.011 25.7046 32.0532 25.9078C32.0953 26.111 32.1965 26.2971 32.344 26.4426C32.4915 26.5881 32.6787 26.6864 32.8818 26.7251C33.085 26.7638 33.295 26.7411 33.4853 26.66C33.6757 26.5789 33.8378 26.4429 33.9511 26.2693C34.0264 26.1541 34.0783 26.0251 34.1039 25.8897C34.1295 25.7543 34.1283 25.6151 34.1004 25.4802C34.0724 25.3453 34.0182 25.2172 33.9409 25.1033C33.8637 24.9894 33.7648 24.8919 33.65 24.8164V24.8164Z"
          fill={color}
        />
        <path
          d="M10.3271 32.5181C10.1912 32.4286 10.032 32.3815 9.86948 32.3828C9.70699 32.3842 9.54855 32.4339 9.41419 32.5256C9.27982 32.6173 9.17558 32.747 9.11464 32.8982C9.05371 33.0495 9.03881 33.2154 9.07184 33.3751C9.10487 33.5348 9.18434 33.6811 9.3002 33.7955C9.41606 33.9099 9.56311 33.9872 9.72274 34.0177C9.88238 34.0482 10.0474 34.0304 10.197 33.9667C10.3466 33.903 10.474 33.7962 10.5632 33.6598C10.6224 33.5693 10.6633 33.468 10.6835 33.3616C10.7036 33.2552 10.7028 33.1459 10.6808 33.0398C10.6589 32.9338 10.6164 32.8331 10.5557 32.7436C10.495 32.6541 10.4173 32.5775 10.3271 32.5181V32.5181Z"
          fill={color}
        />
        <path
          d="M27.5527 32.4312C27.4077 32.5047 27.2877 32.6197 27.2078 32.7616C27.1279 32.9035 27.0917 33.066 27.1038 33.2286C27.1159 33.3912 27.1758 33.5465 27.2758 33.6749C27.3758 33.8034 27.5115 33.8992 27.6658 33.9502C27.82 34.0012 27.9858 34.0052 28.1423 33.9616C28.2988 33.918 28.4389 33.8289 28.5449 33.7054C28.651 33.5819 28.7181 33.4296 28.7379 33.2678C28.7578 33.106 28.7294 32.9419 28.6563 32.7963C28.6078 32.6996 28.5407 32.6134 28.4589 32.5427C28.3772 32.472 28.2824 32.4181 28.1799 32.3842C28.0774 32.3503 27.9692 32.337 27.8616 32.3451C27.754 32.3531 27.649 32.3824 27.5527 32.4312V32.4312Z"
          fill={color}
        />
        <path
          d="M18.0089 24.1112C18.3188 23.6366 18.4621 23.0718 18.4162 22.5062C18.3703 21.9405 18.1378 21.4065 17.7554 20.9885C17.3731 20.5705 16.8628 20.2924 16.3053 20.1984C15.7478 20.1043 15.175 20.1996 14.6775 20.4691C14.18 20.7387 13.7863 21.1671 13.5587 21.6865C13.3311 22.206 13.2826 22.7868 13.4209 23.3371C13.5592 23.8873 13.8763 24.3755 14.3222 24.7245C14.768 25.0734 15.317 25.2631 15.8823 25.2635C16.3045 25.2652 16.7203 25.1606 17.0919 24.9593C17.4634 24.758 17.7787 24.4664 18.0089 24.1112V24.1112Z"
          fill={color}
        />
        <path
          d="M12.934 28.0211C12.7241 27.8829 12.478 27.8103 12.227 27.8125C11.9759 27.8148 11.7312 27.8917 11.5237 28.0335C11.3161 28.1754 11.1552 28.3758 11.0612 28.6095C10.9671 28.8431 10.9442 29.0996 10.9954 29.3463C11.0465 29.593 11.1694 29.819 11.3484 29.9957C11.5275 30.1723 11.7547 30.2917 12.0014 30.3387C12.248 30.3857 12.503 30.3582 12.7341 30.2597C12.9652 30.1613 13.162 29.9962 13.2997 29.7855C13.3911 29.6455 13.4542 29.4889 13.4853 29.3244C13.5163 29.16 13.5149 28.9911 13.4809 28.8272C13.447 28.6634 13.3812 28.5079 13.2874 28.3695C13.1935 28.2312 13.0735 28.1128 12.934 28.0211V28.0211Z"
          fill={color}
        />
        <path
          d="M18.8555 32.9021C18.649 32.8902 18.4436 32.9401 18.2654 33.0454C18.0871 33.1507 17.944 33.3067 17.8541 33.4937C17.7641 33.6806 17.7314 33.8901 17.7601 34.0958C17.7888 34.3014 17.8776 34.4938 18.0152 34.6488C18.1529 34.8037 18.3332 34.9142 18.5334 34.9663C18.7336 35.0184 18.9447 35.0097 19.14 34.9414C19.3353 34.873 19.506 34.7481 19.6306 34.5823C19.7551 34.4166 19.8279 34.2175 19.8398 34.0102C19.8476 33.8726 19.8284 33.7347 19.7832 33.6045C19.738 33.4743 19.6677 33.3543 19.5763 33.2514C19.4849 33.1485 19.3742 33.0647 19.2505 33.0048C19.1268 32.9448 18.9926 32.9099 18.8555 32.9021Z"
          fill={color}
        />
        <path
          d="M18.8657 26.2994C18.5706 26.2823 18.277 26.3535 18.0222 26.5039C17.7673 26.6544 17.5627 26.8773 17.434 27.1445C17.3054 27.4118 17.2586 27.7113 17.2996 28.0052C17.3405 28.2991 17.4674 28.5742 17.6641 28.7957C17.8608 29.0173 18.1186 29.1753 18.4047 29.2498C18.6909 29.3242 18.9927 29.3119 19.2718 29.2142C19.551 29.1165 19.795 28.9379 19.9731 28.701C20.1511 28.4641 20.2552 28.1795 20.2721 27.8832C20.2948 27.4859 20.1594 27.0959 19.8956 26.7989C19.6319 26.5019 19.2614 26.3222 18.8657 26.2994V26.2994Z"
          fill={color}
        />
        <path
          d="M24.9756 27.9185C24.7514 28.0319 24.5657 28.2096 24.442 28.4289C24.3183 28.6483 24.2622 28.8996 24.2808 29.151C24.2994 29.4023 24.3918 29.6425 24.5464 29.8412C24.7009 30.0399 24.9107 30.1881 25.1491 30.267C25.3876 30.346 25.644 30.3523 25.886 30.285C26.128 30.2177 26.3447 30.0798 26.5086 29.8889C26.6726 29.698 26.7765 29.4626 26.8072 29.2124C26.8378 28.9622 26.7939 28.7085 26.681 28.4834C26.5294 28.1815 26.2646 27.9524 25.9448 27.8464C25.625 27.7405 25.2764 27.7664 24.9756 27.9185V27.9185Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2764_66918">
          <rect width="37.7451" height="35" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
}

export default LogoIcon;
