import useIsomorphicLayoutEffect from "./useIsomorphicLayoutEffect";

interface FocusableHTMLElement extends HTMLElement {
  focus(): void;
}

const FOCUSABLE_SELECTOR =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default function useTrapFocus<E extends HTMLElement = HTMLDivElement>(
  ref: React.RefObject<E>,
  enable: boolean
) {
  useIsomorphicLayoutEffect(() => {
    if (ref.current) {
      if (enable) {
        /**
         * Focus the element
         */
        ref.current.focus();

        let unsubs: Array<() => void> = [];
        // Live node list
        const focussableNodes =
          ref.current.querySelectorAll(FOCUSABLE_SELECTOR);

        /**
         * Capture tab indexing with keyboard,
         * and redirect around the tabbable elements
         * inside the ref container
         */
        const onKeyDown = (e: KeyboardEvent) => {
          const { target, key } = e;
          if (key === "Tab") {
            const focusable = Array.from(
              focussableNodes
            ) as FocusableHTMLElement[];
            const first = focusable[0];
            const last = focusable[focussableNodes.length - 1];
            const notFocussed = focusable.indexOf(target as HTMLElement) === -1;
            if (e.shiftKey && (notFocussed || target === first)) {
              e.preventDefault();
              last.focus();
            } else if (notFocussed || target === last) {
              e.preventDefault();
              first.focus();
            }
          }
        };

        document.addEventListener("keydown", onKeyDown);
        unsubs.push(function removeTabKeyHook() {
          document.removeEventListener("keydown", onKeyDown);
        });

        /**
         * Capture and disable any tabbable elements
         * outside the ref container that the "back/forward"
         * buttons on the iOS keyboard will navigate to
         */
        if (/iP(hone|ad|od)/.test(navigator.userAgent)) {
          const focusable = Array.from(
            focussableNodes
          ) as FocusableHTMLElement[];
          const outerFocusable = Array.from<FocusableHTMLElement>(
            document.querySelectorAll(FOCUSABLE_SELECTOR)
          ).filter((node) => {
            return !focusable.includes(node) && !node.getAttribute("disabled");
          });
          outerFocusable.forEach((node) =>
            node.setAttribute("disabled", "disabled")
          );
          unsubs.push(function revertIosHack() {
            outerFocusable.forEach((node) => node.removeAttribute("disabled"));
          });
        }

        return () => {
          unsubs.forEach((fn) => fn());
        };
      }
    }
  }, [enable]);
}
