import React from "react";
import { Svg, SvgProps } from "./Svg";

function ChevronRight({
  color = "currentColor",
  ...props
}: SvgProps & { color?: string }) {
  return (
    <Svg
      width={7}
      height={13}
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-icon
      {...props}
    >
      <path
        d="m6.429 7.054-4.75 4.75a.719.719 0 0 1-.554.238.719.719 0 0 1-.554-.238.765.765 0 0 1 0-1.108L4.766 6.5.571 2.304a.765.765 0 0 1 0-1.108.765.765 0 0 1 1.108 0l4.75 4.75a.765.765 0 0 1 0 1.108Z"
        fill={color}
      />
    </Svg>
  );
}

export default ChevronRight;
