import { css } from "styled-components";
import colors from "../../tokens/colors";

export const checkboxVariables = css`
  --input-checkbox-border-color: ${colors.primary.cardanoBlue};
  --input-checked-background: ${colors.primary.cardanoBlue};
`;

export const checkboxDarkVariables = css`
  --input-checkbox-dark-border-color: ${colors.primary.white};
  --input-checked-dark-background: ${colors.primary.white};
`;
