import { pxToRem } from "@input-output-hk/px-to-rem";
import { forwardRef, useRef } from "react";
import styled from "styled-components";
import useAutofocus from "../../hooks/useAutofocus";
import colors from "../../tokens/colors";
import { forTabletPortraitDown } from "../../tokens/media-queries";
import { KEYS } from "../../utils/keyboard";
import IconClose, { CloseSmall } from "../icons/Close";
import LoadingSpinner from "../LoadingSpinner";

const Input = styled.input`
  appearance: none;
  &:focus-visible {
    outline: none;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: calc(1rem + var(--spacing-xlarge));
  cursor: pointer;
  touch-action: manipulation;
  [data-loading-spinner] {
    margin-right: var(--spacing-small);
  }
`;

export const Container = styled.div`
  position: relative;

  &[data-appearance="small"] ${IconContainer} {
    right: calc(1rem + ${pxToRem(30)});
  }

  ${Input} {
    height: ${pxToRem(60)};
    width: 100%;
    border-radius: ${pxToRem(625)};
    background: var(--search-input-background);
    border: ${pxToRem(1)} solid var(--search-input-border);
    color: var(--search-input-color);
    padding: ${pxToRem(8)};
    padding-left: ${pxToRem(24)};

    box-shadow: 0.0764rem 0.25rem 0.4rem var(--search-input-shadow) inset;
    font-family: inherit;
    font-size: ${pxToRem(22)};
    line-height: ${pxToRem(28)};
    &::placeholder {
      color: var(--search-input-placeholder);
      font-family: inherit;
    }
    @media ${forTabletPortraitDown} {
      font-size: ${pxToRem(18)};
    }
  }

  &[data-appearance="small"] ${Input} {
    padding-left: ${pxToRem(16)};
    height: ${pxToRem(44)};
    font-size: ${pxToRem(16)};
  }
`;

const StyledIconClose = styled(IconClose)`
  width: ${pxToRem(15)};
  margin-right: ${pxToRem(17)};
  color: ${colors.primary.cardanoBlue};
`;

const StyledCloseSmall = styled(CloseSmall)`
  margin-right: ${pxToRem(5)};
  color: ${colors.primary.cardanoBlue};
`;

export type Props = React.ComponentPropsWithoutRef<typeof Input> & {
  loading?: boolean;
  appearance?: "default" | "small";
  allowClear?: boolean;
  onClear?: () => void;
  autoFocus?: boolean;
};

const Search = forwardRef<HTMLInputElement, Props>(
  (
    { allowClear, onClear, autoFocus, children, appearance, loading, ...props },
    givenRef
  ) => {
    const ourRef = useRef<HTMLInputElement>(null);
    const ref = givenRef || ourRef;
    useAutofocus(ref, autoFocus);

    const CloseIcon =
      appearance === "small" ? StyledCloseSmall : StyledIconClose;

    return (
      <Container data-appearance={appearance}>
        <Input data-search-input ref={ref} {...props} />
        <IconContainer>
          {loading && <LoadingSpinner size={appearance} />}
          {!loading && allowClear && (
            <CloseIcon
              data-close-icon
              role="button"
              tabIndex={0}
              onClick={() => {
                onClear?.();
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (
                  (e.which === KEYS.enter || e.which === KEYS.space) &&
                  onClear
                ) {
                  onClear();
                  if ("current" in ref && ref.current) {
                    ref.current.focus();
                  }
                }
              }}
            />
          )}
        </IconContainer>
        {children}
      </Container>
    );
  }
);

Search.displayName = "Search";

export default Search;
