/**
 * Useful keys for `event.which | event.keyCode`
 */
export const KEYS = {
  esc: 27,
  tab: 9,
  arrowUp: 38,
  arrowDown: 40,
  enter: 13,
  space: 32
} as const;
