export function memoOne(fn) {
    let res;
    return (...args) => {
        if (res) {
            return res;
        }
        res = fn(...args);
        return res;
    };
}
export function memo(fn, cacheKey) {
    const cache = new Map();
    return function (...args) {
        const key = cacheKey ? cacheKey(...args) : args[0];
        if (cache.has(key)) {
            return cache.get(key);
        }
        const res = fn(...args);
        cache.set(key, res);
        return res;
    };
}
