import styled from "styled-components";
import { useNotificationCount } from "../../context/NotificationCountContext";
import { pxToRem } from "@input-output-hk/px-to-rem";
import Button from "../buttons/Button";
import {
  NotificationAlertInMenu,
  NotificationCount,
  notificationAlertLimit,
  notificationCountAboveAlertLimit
} from "../page-partials/ProfileMenu";

const MobileNotificationAlertInMenu = styled(NotificationAlertInMenu)`
  right: ${pxToRem(12)};
  position: absolute;
`;

const NotificationButton = styled(Button)`
  position: relative;
`;

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const ProfileButton: React.FC<Props> = ({
  children = "Notifications",
  ...buttonProps
}) => {
  const href = "/profile/notifications";
  const { notificationCount } = useNotificationCount();

  return (
    <NotificationButton {...buttonProps} as="a" href={href} data-login-button>
      {children}

      <MobileNotificationAlertInMenu notificationCount={notificationCount}>
        <NotificationCount level={"3"}>
          {notificationCount < notificationAlertLimit
            ? notificationCount
            : notificationCountAboveAlertLimit}
        </NotificationCount>
      </MobileNotificationAlertInMenu>
    </NotificationButton>
  );
};

export default ProfileButton;
