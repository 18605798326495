import create from "zustand";

const useYearStore = create((set) => ({
  yearsAvailable: null,
  setYearsAvailable: (value) => set((state) => ({ yearsAvailable: value })),
  year: undefined,
  setYear: (value) => set((state) => ({ year: value })),
  displayYear: null,
  setDisplayYear: (value) => set((state) => ({ displayYear: value }))
}));

export { useYearStore };
