import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";
import LoginPrompt from "../components/login-prompt/LoginPrompt";

type LoginPromptContextValue = {
  shouldShowPrompt: boolean;
  showPrompt(content?: ReactNode): void;
};

const initialState = () => ({ show: false, content: null });

const LoginPromptContext = createContext<LoginPromptContextValue>({
  shouldShowPrompt: false,
  showPrompt() {}
});

const LoginPromptProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<{
    show: boolean;
    content: ReactNode | null;
  }>(initialState);

  const onRequestClose = useCallback(() => {
    setState({
      show: false,
      content: null
    });
  }, []);

  const contextValue = useMemo(() => {
    return {
      shouldShowPrompt: state.show,
      showPrompt(content: ReactNode | false) {
        if (content === false) {
          setState({
            show: false,
            content: null
          });
        } else {
          setState({
            show: true,
            content
          });
        }
      }
    };
  }, [state.show]);

  return (
    <LoginPromptContext.Provider value={contextValue}>
      <>
        {children}
        {
          <LoginPrompt isOpen={state.show} onRequestClose={onRequestClose}>
            {state.content}
          </LoginPrompt>
        }
      </>
    </LoginPromptContext.Provider>
  );
};

export default LoginPromptProvider;

export const useLoginPrompt = () => useContext(LoginPromptContext);
