import Button from "../buttons/Button";

type Props = React.ComponentPropsWithoutRef<typeof Button>;
const ProfileButton: React.FC<Props> = ({
  children = "Profile",
  ...buttonProps
}) => {
  const href = `/profile`;
  return (
    <Button {...buttonProps} as="a" href={href} data-login-button>
      {children}
    </Button>
  );
};

export default ProfileButton;
