import { FC, useContext, PropsWithChildren } from "react";
import { ConsentType, useConsent } from "@input-output-hk/osano";
import { PostHogNextProvider } from "@input-output-hk/posthog/next";
import { ConfigContext } from "./ConfigContext";

export const AnalyticsProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { POSTHOG_API_KEY, POSTHOG_PROJECT_ID } = useContext(ConfigContext);
  const analyticsAccepted = useConsent(ConsentType.ANALYTICS);

  return (
    <PostHogNextProvider
      apiKey={POSTHOG_API_KEY}
      projectId={POSTHOG_PROJECT_ID}
      enable={analyticsAccepted}
    >
      {children}
    </PostHogNextProvider>
  );
};

export default AnalyticsProvider;
