import React, { CSSProperties, useCallback, useRef } from "react";
import styled, { keyframes } from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";
import { useEscKey } from "../../hooks/useEscKey";
import useLockDocumentScroll from "../../hooks/useLockDocumentScroll";
import useTrapFocus from "../../hooks/useTrapFocus";
import { pxToRem } from "@input-output-hk/px-to-rem";
import colors from "../../tokens/colors";
import { forTabletPortraitUp } from "../../tokens/media-queries";
import IconClose from "../icons/Close";
import CloseButton from "../../components/CloseButton";
import Overlay from "../Overlay";
import zIndex from "../../tokens/z-index";

const appear = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(10%);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-large);
  background-color: ${colors.primary.white};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${pxToRem(10)};
  width: 90%;
  animation: ${appear} 0.25s cubic-bezier(0.23, 1, 0.32, 1);
  z-index: ${zIndex.dialog};
  outline: 0;
  @media ${forTabletPortraitUp} {
    max-width: ${pxToRem(480)};
  }
`;

const DialogOverlay = styled(Overlay)`
  z-index: ${zIndex.dialog - 1};
`;

type AlertDialogProps = {
  isOpen?: boolean;
  onRequestClose(): void;
  className?: string;
  closeButton?: boolean;
  style?: CSSProperties;
  id?: string;
};

const AlertDialogBox: React.FC<AlertDialogProps> = ({
  isOpen = false,
  onRequestClose,
  children,
  className,
  style,
  closeButton = true,
  id = "close-dialog"
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const closeIfOpen = useCallback(() => {
    if (isOpen) {
      onRequestClose();
    }
  }, [isOpen, onRequestClose]);
  useTrapFocus(ref, isOpen);
  useLockDocumentScroll(isOpen);
  useClickOutside(ref, closeIfOpen);
  useEscKey(closeIfOpen);

  return isOpen ? (
    <>
      <DialogOverlay />
      <Content
        className={className}
        ref={ref}
        tabIndex={0}
        role="dialog"
        aria-modal="true"
        style={style}
      >
        {closeButton && (
          <CloseButton
            onClick={onRequestClose}
            aria-label="Close dialog"
            id={id}
          >
            <IconClose width="20" height="20" />
          </CloseButton>
        )}
        {children}
      </Content>
    </>
  ) : null;
};

export default AlertDialogBox;
