export const ROADMAP_FILTER_OPTIONS: ("all" | "completed" | "coming_soon")[] = [
  "all",
  "completed",
  "coming_soon"
];

export type RoadmapFilterOption = typeof ROADMAP_FILTER_OPTIONS[number];

// this will be replaced by an endpoint via https://input-output.atlassian.net/browse/WEB-1197
export const disabledThemesForCompletionStatus: {
  [key: string]: string[];
} = {
  coming_soon: ["scaling", "smart-contracts", "decentralization"],
  completed: ["scaling", "governance"],
  all: []
};
