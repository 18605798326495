let cachedScrollPosition: [number, number];
const FIXED_BODY_STYLE =
  "position:fixed;overflow:hidden;width:100%;min-height:100%;";
let layers = 0;

export const lockDocumentScrollPosition = (
  shouldLockScrollPosition: boolean
) => {
  if (typeof document !== "undefined") {
    const { body } = document;
    let style = FIXED_BODY_STYLE;
    const x = window.pageXOffset;
    const y = window.pageYOffset;
    if (shouldLockScrollPosition) {
      if (layers === 0) {
        cachedScrollPosition = [x, y];
        style += `top:${y * -1}px;`;
        style += `left:${x * -1}px;`;
        body.setAttribute("style", style);
      }
      layers++;
    } else if (layers > 0) {
      layers--;
    }
    if (!shouldLockScrollPosition && !layers) {
      body.setAttribute("style", "");
    }
    if (!shouldLockScrollPosition && Array.isArray(cachedScrollPosition)) {
      window.scrollTo(...cachedScrollPosition);
    }
  }
};
