import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const alertBoxContentVariables = css`
  --alert-box-content-background-color: ${colors.primary.white};
  --alert-box-content-title-font-family: ${typography.font.default};
  --alert-box-content-title-font-size: ${typography.size.h3};
  --alert-box-content-message-color: ${colors.primary.slateGrey};
  --alert-box-content-icon-background-color: ${colors.secondary.londonFog};
  --alert-box-content-description-color: ${colors.secondary.ashGrey};
`;
