import { css } from "styled-components";
import colors from "../../tokens/colors";
import typography from "../../tokens/typography";

export const verifiedLabelVariables = css`
  --verified-label-background-color: ${colors.primary.cardanoBlue};
  --verified-label-text-color: ${colors.primary.white};
  --verified-label-background-color-hover: var(--theme-color-primary);
  --verified-label-text-size: ${typography.size.tiny};
`;
