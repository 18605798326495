import * as React from "react";
import { Svg, SvgProps } from "./Svg";

function Facebook({
  strokeWidth = 2,
  ...props
}: SvgProps & { strokeWidth?: number }) {
  return (
    <Svg
      data-icon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 10v4h3v7h4v-7h3l1-4h-4V8a1 1 0 011-1h3V3h-3a5 5 0 00-5 5v2H7z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default Facebook;
